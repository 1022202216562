<template>
  <div
    class="bg-sv-background h-screen flex flex-row justify-center items-start sm:items-center"
  >
    <LoginModule />
  </div>
</template>
<script>
import LoginModule from "@/modules/LoginModule.vue";
export default {
  name: "LoginPage",
  components: {
    LoginModule,
  },
};
</script>
