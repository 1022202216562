<template>
    <v-row class="d-inline-flex">
        <!-- Activator Button for Dropdown -->
        <v-btn
            :disabled="loading"
            text="Refresh"
            color="#797979"
            variant="outlined"
            size="small"
            icon="fa fa-calendar-week"
            class="secondary"
            @click="isDropdownOpen = true"
        >
        </v-btn>

        <!-- Dropdown Menu in Dialog -->
        <v-dialog
            v-model="isDropdownOpen"
            max-width="400px"
            @click:outside="isDropdownOpen = false"
        >
            <OptionDropdown
                :contentType="'graph-date-type'"
                :options="graphDataDateRangeOptions"
                @set-selected-value="setDateValue"
            />
        </v-dialog>

        <!-- Date Picker in Dialog -->
        <v-dialog
            v-model="isDatePickerOpen"
            max-width="400px"
            @click:outside="isDatePickerOpen = false"
        >
            <DatePicker :value="date" @update:modelValue="handleDate" range inline />
        </v-dialog>
    </v-row>
</template>


<style scoped>
.spinner-border {
    border-top-color: transparent;
    border-right-color: transparent;
}
</style>


<script>
/* eslint-disable */
import OptionDropdown from "../components/OptionDropdown.vue";
import ExportModule from "../modules/ExportModule.vue";
import { DateTime } from 'luxon';

export default {
    name: "RequestDataModule",
    components: {
        OptionDropdown,
        ExportModule,
    },
    props: {
        isIcon: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const handleDate = async (modelData) => {
            this.isDropdownOpen = false;
            this.isDatePickerOpen = false;

            const [startTimestamp, endTimestamp] = this.calculateCustomTimerange(modelData);
            this.graphDataDateRangeTitle = startTimestamp.toFormat('dd/MM/yyyy') + ' - ' + endTimestamp.toFormat('dd/MM/yyyy');
            this.graphDataDateRangeType = "custom-date";

            // Presist the settings to store

            this.$store.state.graph.graphDataDateRangeTitle = this.graphDataDateRangeTitle;
            this.$store.state.graph.graphDataDateRangeType = this.graphDataDateRangeType;
            this.$store.state.graph.start = this.startTimestamp;
            this.$store.state.graph.end = this.endTimestamp;

            this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);
        };
        return {
            date: new Date(),
            handleDate,
            isDropdownOpen: false,
            isDatePickerOpen: false,
            startTimestamp: null,
            endTimestamp: null,
            graphDataTypeTitle: "Detections",
            graphDataDateRangeTitle: "Last 7 Days",
            graphDataDateRangeType: "last-week",
            graphDataDateRangeCustom: [],
            processedData: [],
            rawDetectionData: [],
            graphDataDateRangeOptions: [
                {
                    name: "Last 24 Hours",
                    value: "yesterday",
                },
                {
                    name: "Last 7 Days",
                    value: "last-week",
                },
                {
                    name: "Last 30 Days",
                    value: "last-30-days",
                },
                {
                    name: "Last 3 Months",
                    value: "last-3-months",
                },
                {
                    name: "Last 6 Months",
                    value: "last-6-months",
                },
                {
                    name: "Last 12 Months",
                    value: "last-year",
                },
                {
                    name: "Custom",
                    value: "custom-date",
                },
            ],
        };
    },
    mounted() {
        this.graphDataDateRangeTitle = this.$store.state.graph.graphDataDateRangeTitle;
        this.graphDataDateRangeType = this.$store.state.graph.graphDataDateRangeType;
        this.startTimestamp = this.$store.state.graph.start;
        this.endTimestamp = this.$store.state.graph.end;

    },
    methods: {
        isMobile() {
            return this.$store.state.isMobile;
        },
        triggerSelectOptionDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        async setDateValue(value) {
            this.graphDataDateRangeTitle = value.name;
            this.graphDataDateRangeType = value.value;
            this.$store.state.graph.graphDataDateRangeTitle = this.graphDataDateRangeTitle;
            this.$store.state.graph.graphDataDateRangeType = this.graphDataDateRangeType;

            if (value.value !== "custom-date") {
                this.isDropdownOpen = !this.isDropdownOpen;
            } else {
                this.isDatePickerOpen = !this.isDatePickerOpen;
                // do not request data if the date range is custom
                return;
            }

            const [startTimestamp, endTimestamp] = this.calculateTimerange(this.graphDataDateRangeType);

            this.$store.state.graph.start = startTimestamp;
            this.$store.state.graph.end = endTimestamp;

            this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);
        },
        async refresh() {
            if (this.$store.state.graph.start === null || this.$store.state.graph.end === null) {
                [this.startTimestamp, this.endTimestamp] = this.calculateTimerange(this.graphDataDateRangeType);
                this.$store.state.graph.start = this.startTimestamp;
                this.$store.state.graph.end = this.endTimestamp;
            }
            this.startTimestamp = this.$store.state.graph.start;
            this.endTimestamp = this.$store.state.graph.end;

            this.fetchDetectionsBetweenTimePeriod(this.startTimestamp, this.endTimestamp);
        },
        async fetchDetectionsBetweenTimePeriod(startTime, endTime) {
            try {
                await this.$store.dispatch("setGraphDetectionsBetweenTimePeriod", {
                    start: startTime,
                    end: endTime
                });
                console.log(this.$store.state.detections);

            } catch (error) {
                console.error(error);
            }
        },
        calculateCustomTimerange(dateRangeValue) {
            let startTimestamp = DateTime.now();
            let endTimestamp = DateTime.now();

            if (dateRangeValue[1] !== null) {
                startTimestamp = DateTime.fromJSDate(dateRangeValue[0]);
                endTimestamp = DateTime.fromJSDate(dateRangeValue[1]);
            }

            return [startTimestamp, endTimestamp]
        },
        calculateTimerange(graphDataDateRangeType) {
            const timeNow = DateTime.now();
            let startDate = timeNow;

            switch (graphDataDateRangeType) {
                case "yesterday":
                    startDate = timeNow.minus({ days: 1 });
                    break;

                case "last-week":
                    startDate = timeNow.minus({ weeks: 1 });
                    break;

                case "last-30-days":
                    startDate = timeNow.minus({ days: 30 });
                    break;

                case "last-3-months":
                    startDate = timeNow.minus({ months: 3 }).startOf('month');
                    break;

                case "last-6-months":
                    startDate = timeNow.minus({ months: 6 }).startOf('month');
                    break;

                case "last-year":
                    startDate = timeNow.minus({ years: 1 });
                    break;

                default:
                    console.error("Invalid date range type");
                    break;
            }

            return [startDate, timeNow];
        }
    }
};
</script>