<template>
    <GenericGraphModule :title="title" :updateChartData="updateChartData" :processedChartData="processedChartData" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { DateTime } from 'luxon';
import GenericGraphModule from "@/modules/charts/GenericGraphModule.vue";

export default {
  components: {
    Bar,
    GenericGraphModule
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    displayOperational: {
      type: Boolean,
      default: false,
    },
    dataToPassToGraph: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      processedData: {},
    };
  },
  computed: {
    processedChartData() {
      const labels = [];
      const datasets = [];

      // Process each idVisionAI for separate bars
      Object.keys(this.processedData).forEach((idVisionAI) => {
        const machineData = this.processedData[idVisionAI];

        // Prepare the data for "System Fully Operational"
        const operationalData = machineData
          .map(item => item.value)
          .filter(value => value > 0); // Filter out zero values

        const faultData = machineData
          .map(item => item.redValue)
          .filter(value => value > 0); // Filter out zero values

        // Only add datasets if there is operational data or fault data
        if (operationalData.length > 0) {
          datasets.push({
            label: `${this.mapVisionaiToMachine(idVisionAI)} Fully Operational`,
            data: operationalData,
            backgroundColor: "#42f557",
            hoverBackgroundColor: "#8af296",
          });
        }

        if (faultData.length > 0) {
          datasets.push({
            label: `${this.mapVisionaiToMachine(idVisionAI)} System Fault`,
            data: faultData,
            backgroundColor: "#f54242", // Fault color remains red
            hoverBackgroundColor: "#f28a8a",
          });
        }

        // Collect labels only if machineData has entries
        if (machineData.length > 0 && labels.length === 0) {
          machineData.forEach((item) => {
            labels.push(item.label);
          });
        }
      });

      return {
        labels,
        datasets,
      };
    },

  },
  watch: {
    dataToPassToGraph: {
      handler: "updateChartData",
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    mapVisionaiToMachine(idVisionAI) {
      // Use filter to find machines that match the given idVisionAI
      let machines = this.$store.state.machines.filter((machine) => {
        return machine.machineStVisionAIIdItem === idVisionAI;
      });

      // Log the machines found for debugging
      console.log(machines);

      // Return the fleet number if machines are found, otherwise return null or a default value
      return machines.length > 0 ? machines[0].sFleetNumber : idVisionAI;
    },
    prefillDaysInTimePeriod(startDate, endDate) {
      const daysInPeriod = [];
      let date = startDate;

      while (date <= endDate) {
        const label = date.toFormat("dd/MM");
        daysInPeriod.push({
          value: 0,
          redValue: 0,
          label: label,
        });

        date = date.plus({ days: 1 });
      }

      return daysInPeriod;
    },
    getOverallStatus(item) {
      return item.stDeviceStatus.every(sourceDevice => {
        const statusCode = parseInt(sourceDevice.iStatusCode, 10);
        return statusCode === 0 || statusCode === 1;
      });
    },
    updateChartData() {
      const startDate = DateTime.fromISO(this.$store.state.graph.start);
      const endDate = DateTime.fromISO(this.$store.state.graph.end);

      this.processedData = {};

      this.dataToPassToGraph.forEach((item) => {
        const detectionDate = DateTime.fromISO(item.iTimestamp).toFormat("dd/MM");
        const idVisionAI = item.idVisionAI;

        // Ensure the machine's dataset is initialized
        if (!this.processedData[idVisionAI]) {
          this.processedData[idVisionAI] = this.prefillDaysInTimePeriod(startDate, endDate);
        }

        const existingLabelIndex = this.processedData[idVisionAI].findIndex(dataItem => dataItem.label === detectionDate);

        if (existingLabelIndex !== -1) {
          if (this.getOverallStatus(item)) {
            if (this.displayOperational) {
              this.processedData[idVisionAI][existingLabelIndex].value++;
            }
          } else {
            this.processedData[idVisionAI][existingLabelIndex].redValue++;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add any required styles here */
</style>
