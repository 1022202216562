<template>
    <GenericGraphModule :title="title" :tooltipOverride="tooltipOverride" :updateChartData="updateChartData"
      :processedChartData="processedChartData" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { DateTime } from 'luxon';
import GenericGraphModule from "@/modules/charts/GenericGraphModule.vue";

export default {
  components: {
    Bar,
    GenericGraphModule
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    dataToPassToGraph: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      processedData: [],
    };
  },
  computed: {
    processedChartData() {
      const datasets = [];
      const idVisionAIMap = {};

      // Group data by idVisionAI
      this.processedData.forEach((item) => {
        const idVisionAI = item.idVisionAI;

        // If idVisionAI not in map, initialize an array
        if (!idVisionAIMap[idVisionAI]) {
          idVisionAIMap[idVisionAI] = {
            data: [],
            labels: []
          };
        }

        // Push data and label into idVisionAI-specific group
        idVisionAIMap[idVisionAI].data.push(item.value);
        idVisionAIMap[idVisionAI].labels.push(item.label);
      });

      // Generate a dataset for each idVisionAI with unique color
      Object.keys(idVisionAIMap).forEach((idVisionAI) => {
        const { randomColor } = this.getRandomColor(); // Get a random color
        datasets.push({
          label: `${this.mapVisionaiToMachine(idVisionAI)}`,
          data: idVisionAIMap[idVisionAI].data,
          backgroundColor: randomColor,
          hoverBackgroundColor: randomColor,
          // minBarLength: 10,
        });
      });

      // Assuming labels are the same for each idVisionAI, you can use the first group's labels
      const labels = idVisionAIMap[Object.keys(idVisionAIMap)[0]]?.labels || [];

      return {
        labels,
        datasets
      };
    },
  },
  watch: {
    dataToPassToGraph: {
      handler: "updateChartData",
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    mapVisionaiToMachine(idVisionAI) {
      // Use filter to find machines that match the given idVisionAI
      let machines = this.$store.state.machines.filter((machine) => {
        return machine.machineStVisionAIIdItem === idVisionAI;
      });

      // Log the machines found for debugging
      console.log(machines);

      // Return the fleet number if machines are found, otherwise return null or a default value
      return machines.length > 0 ? machines[0].sFleetNumber : idVisionAI;
    },
    filterUniqueByTimestamp(dataArray) {
      const uniqueTimestamps = new Set(); // To track unique timestamps
      return dataArray.filter(item => {
        const timestamp = item.iTimestamp;

        // If the timestamp is not in the set, add it and keep the item
        if (!uniqueTimestamps.has(timestamp) && item.iTotalRuntime !== 0) {
          uniqueTimestamps.add(timestamp);
          return true;
        }
        // If the timestamp is already in the set, filter it out
        return false;
      });
    },
    prefillDaysInTimePeriod(startDate, endDate) {
      const processedData = [];
      let date = DateTime.fromISO(startDate);

      while (date <= endDate) {
        const label = date.toFormat("dd/MM");

        processedData.push({
          value: 0,
          label: label,
        });

        date = date.plus({ days: 1 });
      }

      return processedData;
    },
    tooltipOverride(tooltipItem, data) {
      // Get the dataset label and the datapoint value
      const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
      const value = tooltipItem.yLabel || tooltipItem.value;

      // Append " hours" to the value when displaying the tooltip
      return `${datasetLabel}: ${value} hours`;
    },
    convertSecondsToDhms(seconds) {
      // Calculate the number of days, hours, minutes, and seconds
      const days = Math.floor(seconds / (3600 * 24));
      seconds %= 3600 * 24;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      // Format the result as `dd:hh:mm:ss`
      const formattedTime = [
        String(days).padStart(2, '0'),    // Ensure two digits for days
        String(hours).padStart(2, '0'),   // Ensure two digits for hours
        String(minutes).padStart(2, '0'), // Ensure two digits for minutes
        String(remainingSeconds).padStart(2, '0') // Ensure two digits for seconds
      ].join(':');

      return formattedTime;
    },
    updateChartData() {
      this.processedData = this.prefillDaysInTimePeriod(
        this.$store.state.graph.start,
        this.$store.state.graph.end
      );

      const uniqueData = this.filterUniqueByTimestamp(this.dataToPassToGraph);

      console.log("uniqueData:", uniqueData);

      uniqueData.forEach((item) => {
        const date = DateTime.fromISO(item.iTimestamp);
        const label = date.toFormat("dd/MM");
        const runtimeInHours = (item.iTotalRuntime / 60 / 60).toFixed(2); // Runtime in hours

        // Find if there is already an entry with the same idVisionAI and label
        const existingEntry = this.processedData.find(
          (data) => data.idVisionAI === item.idVisionAI && data.label === label
        );

        if (existingEntry) {
          // Increment the value if the entry exists
          existingEntry.value = (parseFloat(existingEntry.value) + parseFloat(runtimeInHours)).toFixed(2);
        } else {
          // Otherwise, push a new entry
          this.processedData.push({
            value: runtimeInHours,
            label: label,
            idVisionAI: item.idVisionAI, // Store idVisionAI for dataset creation
            ddhhmmss: this.convertSecondsToDhms(item.iTotalRuntime),
          });
        }
      });
    },

    getRandomColor() {
      let intColor = Math.random() * 16777215 + 2796202; // Ensure color is bright enough
      const randomColor = '#' + Math.floor(intColor).toString(16);
      return { randomColor };
    },
  },
};
</script>

<style scoped>
/* Add any required styles here */
</style>
