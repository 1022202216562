<template>
  <GenericDataTable :headers="headers" :query="query" :index="'listVisionAIS'" :exportDataType="'listVisionAIS'"
    :expandedRowData="expandedRowData" :triggerRefresh="triggerRefresh" :title="'Select A VisionAI'">

    <template v-slot:extraButtons>
      <v-btn text="Create VisionAI" color="#797979" variant="outlined" size="small" @click="showCreateModal"
        icon="fa fa-plus" class="secondary mx-2">
      </v-btn>
    </template>

    <!-- Radio Button for Selecting VisionAI -->
    <template v-slot:item.action="{ item }">
      <v-radio v-model="selectedVisionAI" :value="item"></v-radio>
    </template>

    <!-- Modify VisionAI Button -->
    <template v-slot:item.modify="{ item }">
      <v-btn color="green" size="small" icon @click="showUpdateModal(item)">
        <font-awesome-icon icon="edit" />
      </v-btn>
    </template>

    <!-- Delete Button -->
    <template v-slot:item.delete="{ item }">
      <v-btn color="red" size="small" icon @click="showDeleteConfirmation(item)">
        <font-awesome-icon icon="trash" />
      </v-btn>
    </template>
  </GenericDataTable>


  <!-- Create VisionAI Modal -->
  <v-dialog v-model="createModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Create New VisionAI</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <v-select label="Select" :items="companyNameMap" v-model="selectedCompany"></v-select>
        <v-text-field v-model="stMachine.sFleetNumber" label="Fleet Number"></v-text-field>
        <v-text-field v-model="stMachine.sModel" label="Model"></v-text-field>
        <v-text-field v-model="stMachine.sMake" label="Make"></v-text-field>
        <v-text-field v-model="stMachine.sSerialNumber" label="Machine Serial Number"></v-text-field>
        <v-text-field v-model="stVisionAI.stVPU.sSerialNumber" label="VPU Serial Number"></v-text-field>
        <v-text-field v-model="stVisionAI.stVPU.sCrc" label="CRC / Serial Number"></v-text-field>
        <v-select v-model="stVisionAI.stVPU.sType" :items="['2 Camera', '4 Camera']" label="VPU Type"></v-select>
      </v-card-text>
      
      <v-card-actions>
        <v-btn color="primary" @click="confirmCreateVisionAI">Create</v-btn>
        <v-btn @click="closeCreateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Update VisionAI Modal -->
  <v-dialog v-model="updateModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Modify VisionAI</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <v-text-field v-model="selectedVisionAI.idItem" label="VisionAI ID" disabled />

        <div v-for = "item in selectedVisionAI.stVPU">
          <v-text-field v-model="item.sCrc" label="CRC" />
          <v-text-field v-model="item.sSerialNumber" label="Serial Number" />
          <v-text-field v-model="item.sType" label="Type" />
        </div>
        <v-select label="Select" :items="companyNameMap" v-model="selectedUpdatedCompany"></v-select>
        <v-text-field v-model="selectedVisionAI.visionAIStMachineIdItem" disabled label="Machine ID" />

      </v-card-text>

      <v-card-actions>
        <v-btn color="green" @click="confirmUpdateVisionAI">Update</v-btn>
        <v-btn @click="closeUpdateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- Delete Confirmation Modal -->
  <v-dialog v-model="deleteModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Delete VisionAI</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        Are you sure you want to delete the Sentinel Vision AI "{{ selectedVisionAI.idItem }}"? This action cannot be reverted.
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="confirmDeleteVisionAI">Delete</v-btn>
        <v-btn @click="closeDeleteModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import * as queries from "@/graphql/queries";
import GenericDataTable from "@/components/dataTables/GenericDataTable.vue";
import {
  v4 as uuidv4
} from 'uuid';


export default {
  name: 'AdminVisionAIDataTable',
  components: {
    GenericDataTable,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      stMachine: this.initializeMachine(),
      stVisionAI: this.initializeVisionAI(),
      selectedVisionAI: null,
      createModal: false,
      updateModal: false,
      deleteModal: false,
      triggerRefresh: false,
      selectedCompany: null,
      selectedUpdatedCompany: null,
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies,
      notification: (state) => state.notification,
    }),
    companyNameMap() {
      return this.companies.map((company) => { return { title: company.sName, value: company.idItem } });
    },
    companyIDs() {
      return this.companies.map((company) => company.idItem);
    },
    headers() {
      return [
        { align: 'start', key: 'idItem', title: 'VPU ID', sortable: true },
        { key: 'stVPU', title: 'VPU Details', sortable: true },
        { title: "Modify", key: "modify", sortable: false },
        { title: "Delete", key: "delete", sortable: false },
      ];
    }, query() {
      return queries.listVisionAIS;
    },
    expandedRowData() {
      return {
        'idItem': 'Fleet Number',
        'stVPU': 'VPU Details',
        'visionAIStMachineIdCompanyPartition': 'Machine Company ID',
        'visionAIStMachineIdItem': 'Machine ID',
      };
    }

  },
  methods: {
    ...mapActions(['setAllMachines', 'createMachine', 'deleteMachine', 'updateMachine', 'createVisionAI', 'deleteVisionAI', 'updateVisionAI',]),
    ...mapMutations(['setVisionAIs', 'removeVisionAI', 'showNotification', 'hideNotification']),
    refresh() {
      this.triggerRefresh = !this.triggerRefresh;
    },
    showCreateModal() {
      this.createModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
      this.refresh();
    },
    initializeMachine() {
      return {
        idCompanyPartition: '',
        idItem: '',
        sFleetNumber: '',
        sModel: '',
        sMake: '',
        stPosition: { iTimestamp: '', fLatitude: '', fLongitude: '' },
        sSerialNumber: '',
        tCreatedAt: '',
        tUpdatedAt: '',
        machineStVisionAIIdCompanyPartition: '',
        machineStVisionAIIdItem: ''
      };
    },
    initializeVisionAI() {
      return {
        idCompanyPartition: '',
        idItem: '',
        stVPU: { sSerialNumber: '', sCrc: '', sType: '' },
        tCreatedAt: '',
        tUpdatedAt: '',
        visionAIStVisionAIIdCompanyPartition: '',
        visionAIStVisionAIIdItem: ''
      };
    },
    async confirmCreateVisionAI() {
      console.log("confirmCreateVisionAI called");
      console.log(this.selectedCompany);

      if (!this.selectedCompany || this.selectedCompany === "Company ID") {
        console.log({ type: 'error', message: 'Please select a Vision AI device.' });
        return;
      }

      const companyPartition = this.selectedCompany;
      this.assignMachineAndVisionAIDetails(companyPartition);

      try {
        console.log("Creating Machine and VisionAI", this.stMachine, this.stVisionAI);
        await this.createMachine(this.stMachine);
        console.log("VisionAI created successfully!");
        await this.createVisionAI(this.stVisionAI);
        console.log("VisionAI created successfully!");

        this.closeCreateModal();

        console.log({ type: 'success', message: 'VisionAI and VisionAI created successfully!' });
        console.log("VisionAI and VisionAI created successfully!");
      } catch (error) {
        console.log({ type: 'error', message: `Error: ${error.message}` });
      }

    },

    showUpdateModal(visionAI) {
      this.selectedVisionAI = { ...visionAI };
      this.updateModal = true;
    },
    closeUpdateModal() {
      this.updateModal = false;
    },
    async confirmUpdateVisionAI() {
      try {
        if (!this.selectedUpdatedCompany) {
          console.log({ type: 'error', message: 'Please select a company' });
          return;
        }

        console.log("confirmUpdateVisionAI called", this.selectedUpdatedCompany);
        this.selectedVisionAI.visionAIStMachineIdCompanyPartition = this.selectedUpdatedCompany;
        this.selectedVisionAI.idCompanyPartition = this.selectedUpdatedCompany;
        console.log(this.selectedVisionAI);
        
        await this.updateVisionAI(this.selectedVisionAI);
        console.log("VisionAI updated successfully!");

        this.closeUpdateModal();
        console.log({
          type: "success",
          message: "VisionAI updated successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to update Vision AI device.",
        });
        console.log(error);
      }
    },

    showDeleteConfirmation(company) {
      this.selectedVisionAI = company;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.refresh()
    },
    assignMachineAndVisionAIDetails(companyPartition) {
      this.stVisionAI.idCompanyPartition = companyPartition;
      this.stVisionAI.idItem = this.stVisionAI.stVPU.sSerialNumber;
      this.stMachine.idCompanyPartition = companyPartition;
      this.stMachine.idItem = uuidv4();
      this.stMachine.stPosition.iTimestamp = Math.floor(Date.now() / 1000);
      this.stMachine.stPosition.fLatitude = 0.0;
      this.stMachine.stPosition.fLongitude = 0.0;
      this.stMachine.machineStVisionAIIdCompanyPartition = companyPartition;
      this.stMachine.machineStVisionAIIdItem = this.stVisionAI.stVPU.sSerialNumber;
      this.stVisionAI.visionAIStMachineIdCompanyPartition = companyPartition;
      this.stVisionAI.visionAIStMachineIdItem = this.stMachine.idItem;
    },
    async confirmDeleteVisionAI() {
      try {
        console.log("confirmDeleteVisionAI called");
        console.log(this.selectedVisionAI);
        await this.deleteVisionAI(this.selectedVisionAI);
        this.closeDeleteModal();
        console.log({
          type: "success",
          message: "VisionAI deleted successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to delete VisionAI.",
        });
      }
    },
  },
  created() {
    // this.fetchVisionAIs(); // Fetch companies when component is created
  },

};
</script>