/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStartupShutdown = /* GraphQL */ `
  query GetStartupShutdown($idCompanyPartition: ID!, $iTimestamp: String!) {
    getStartupShutdown(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
    ) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iSystemStartup
      iSystemShutdown
      iTotalRuntime
      iStartupTimeTaken
      sSystemState
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listStartupShutdowns = /* GraphQL */ `
  query ListStartupShutdowns(
    $idCompanyPartition: ID
    $iTimestamp: ModelStringKeyConditionInput
    $filter: ModelStartupShutdownFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStartupShutdowns(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idCompanyPartition
        iTimestamp
        sTimeZone
        idVisionAI
        sFleetNumber
        iSystemStartup
        iSystemShutdown
        iTotalRuntime
        iStartupTimeTaken
        sSystemState
        tCreatedAt
        tUpdatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getPrestart = /* GraphQL */ `
  query GetPrestart($idCompanyPartition: ID!, $iTimestamp: String!) {
    getPrestart(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
    ) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      iDuration
      idVisionAI
      sFleetNumber
      stPrestartItem {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      xPrestartPassed
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listPrestarts = /* GraphQL */ `
  query ListPrestarts(
    $idCompanyPartition: ID
    $iTimestamp: ModelStringKeyConditionInput
    $filter: ModelPrestartFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrestarts(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idCompanyPartition
        iTimestamp
        sTimeZone
        iDuration
        idVisionAI
        sFleetNumber
        stPrestartItem {
          sSource
          iStatusCode
          sStatusCode
          __typename
        }
        stPosition {
          iTimestamp
          fLatitude
          fLongitude
          __typename
        }
        xPrestartPassed
        tCreatedAt
        tUpdatedAt
        __typename
        }
      nextToken
      __typename
    }
  }
`;
export const getSystemStatus = /* GraphQL */ `
  query GetSystemStatus($idCompanyPartition: ID!, $iTimestamp: String!) {
    getSystemStatus(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
    ) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      stDeviceStatus {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listSystemStatuses = /* GraphQL */ `
  query ListSystemStatuses(
    $idCompanyPartition: ID
    $iTimestamp: ModelStringKeyConditionInput
    $filter: ModelSystemStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemStatuses(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idCompanyPartition
        iTimestamp
        sTimeZone
        idVisionAI
        sFleetNumber
        stDeviceStatus {
          sSource
          iStatusCode
          sStatusCode
          __typename
        }
        tCreatedAt
        tUpdatedAt
        __typename
        }
      nextToken
      __typename
    }
  }
`;
export const getDetectionEvent = /* GraphQL */ `
  query GetDetectionEvent($idCompanyPartition: ID!, $iTimestamp: String!) {
    getDetectionEvent(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
    ) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iDuration
      stDetections {
        iTimestamp
        iCameraIdentity
        sCameraRoiType
        sDisplayZoneName
        sAlarmSeverity
        iConfidence
        sType
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sAlarmGroup
      sAlarmSeverity
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listDetectionEvents = /* GraphQL */ `
  query ListDetectionEvents(
    $idCompanyPartition: ID
    $iTimestamp: ModelStringKeyConditionInput
    $filter: ModelDetectionEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDetectionEvents(
      idCompanyPartition: $idCompanyPartition
      iTimestamp: $iTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idCompanyPartition
        iTimestamp
        sTimeZone
        idVisionAI
        sFleetNumber
        iDuration
        stDetections {
          iTimestamp
          iCameraIdentity
          sCameraRoiType
          sDisplayZoneName
          sAlarmSeverity
          iConfidence
          sType
          __typename
        }
        stPosition {
          iTimestamp
          fLatitude
          fLongitude
          __typename
        }
        sAlarmGroup
        sAlarmSeverity
        tCreatedAt
        tUpdatedAt
        __typename
        }
      nextToken
      __typename
    }
  }
`;
export const getMachine = /* GraphQL */ `
  query GetMachine($idCompanyPartition: ID!, $idItem: ID!) {
    getMachine(idCompanyPartition: $idCompanyPartition, idItem: $idItem) {
      idCompanyPartition
      idItem
      sFleetNumber
      sModel
      sMake
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sSerialNumber
      stVisionAI {
        idCompanyPartition
        idItem
        tCreatedAt
        tUpdatedAt
        visionAIStMachineIdCompanyPartition
        visionAIStMachineIdItem
        __typename
      }
      tCreatedAt
      tUpdatedAt
      machineStVisionAIIdCompanyPartition
      machineStVisionAIIdItem
      __typename
    }
  }
`;
export const listMachines = /* GraphQL */ `
  query ListMachines(
    $idCompanyPartition: ID
    $idItem: ModelIDKeyConditionInput
    $filter: ModelMachineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMachines(
      idCompanyPartition: $idCompanyPartition
      idItem: $idItem
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idCompanyPartition
        idItem
        sFleetNumber
        sModel
        sMake
        stPosition {
          iTimestamp
          fLatitude
          fLongitude
          __typename
        }
        sSerialNumber
        stVisionAI {
          idCompanyPartition
          idItem
          tCreatedAt
          tUpdatedAt
          visionAIStMachineIdCompanyPartition
          visionAIStMachineIdItem
          __typename
        }
        tCreatedAt
        tUpdatedAt
        machineStVisionAIIdCompanyPartition
        machineStVisionAIIdItem
        __typename
        }
      nextToken
      __typename
    }
  }
`;
export const getVisionAI = /* GraphQL */ `
  query GetVisionAI($idCompanyPartition: ID!, $idItem: ID!) {
    getVisionAI(idCompanyPartition: $idCompanyPartition, idItem: $idItem) {
      idCompanyPartition
      idItem
      stMachine {
        idCompanyPartition
        idItem
        sFleetNumber
        sModel
        sMake
        sSerialNumber
        tCreatedAt
        tUpdatedAt
        machineStVisionAIIdCompanyPartition
        machineStVisionAIIdItem
        __typename
      }
      stVPU {
        sSerialNumber
        sCrc
        sType
        __typename
      }
      tCreatedAt
      tUpdatedAt
      visionAIStMachineIdCompanyPartition
      visionAIStMachineIdItem
      __typename
    }
  }
`;
export const listVisionAIS = /* GraphQL */ `
  query ListVisionAIS(
    $idCompanyPartition: ID
    $idItem: ModelIDKeyConditionInput
    $filter: ModelVisionAIFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVisionAIS(
      idCompanyPartition: $idCompanyPartition
      idItem: $idItem
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
    items {
      idCompanyPartition
      idItem
      tCreatedAt
      tUpdatedAt
      stVPU {
        sCrc
        sSerialNumber
        sType
      __typename
      }
      tCreatedAt
      tUpdatedAt
      visionAIStMachineIdCompanyPartition
      visionAIStMachineIdItem
      __typename

      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($idItem: ID!) {
    getUser(idItem: $idItem) {
      idItem
      idCompanyPartition
      sEmail
      sFirstName
      sLastName
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $idItem: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      idItem: $idItem
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idItem
        idCompanyPartition
        sEmail
        sFirstName
        sLastName
        tCreatedAt
        tUpdatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($idItem: ID!) {
    getCompany(idItem: $idItem) {
      idItem
      sName
      sEmail
      xReceiveSystemFaultEmail
      idPrimaryAdmin
      idOwnedVisionAIDevices
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $idItem: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      idItem: $idItem
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idItem
        sName
        sEmail
        xReceiveSystemFaultEmail
        idPrimaryAdmin
        idOwnedVisionAIDevices
        tCreatedAt
        tUpdatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const visionAISByIdItemAndIdCompanyPartition = /* GraphQL */ `
  query VisionAISByIdItemAndIdCompanyPartition(
    $idItem: ID!
    $idCompanyPartition: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVisionAIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visionAISByIdItemAndIdCompanyPartition(
      idItem: $idItem
      idCompanyPartition: $idCompanyPartition
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        idCompanyPartition
        idItem
        tCreatedAt
        tUpdatedAt
        visionAIStMachineIdCompanyPartition
        visionAIStMachineIdItem
        __typename
      }
      nextToken
      __typename
    }
  }
`;
