<template>
  <v-container fluid>
    <v-col cols="12" md="8">
      <AdminCompanyDataTable />
    </v-col>

    <v-col cols="12" md="8">
      <AdminMachineDataTable />
    </v-col>

    <v-col cols="12" md="8">
      <AdminVisionAIDataTable />
    </v-col>

    <v-col cols="12" md="8">
      <AdminUserDataTable />
    </v-col>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AdminCompanyDataTable from "@/components/dataTables/AdminCompanyDataTable.vue";
import AdminVisionAIDataTable from "@/components/dataTables/AdminVisionAIDataTable.vue";
import AdminMachineDataTable from "@/components/dataTables/AdminMachineDataTable.vue";
import AdminUserDataTable from "@/components/dataTables/AdminUserDataTable.vue";
import {
  v4 as uuidv4
} from 'uuid';

export default {
  name: 'AdminPage',
  components: {
    AdminCompanyDataTable,
    AdminVisionAIDataTable,
    AdminMachineDataTable,
    AdminUserDataTable
  },
  data() {
    return {
      companyName: '',
      companyEmail: '',
      companies: [],
      deleteModalVisible: false,
      updateModalVisible: false,
      isCreating: false,
      selectedCompanies: [],
    };
  },
  computed: {
    ...mapState(["companies", "notification"]),
  },
  methods: {
    ...mapActions(['fetchCompanies', 'createCompany', 'deleteCompany', 'updateCompany',]),
    ...mapMutations(['setCompanies']),


    handleSelection(newSelection) {
      console.log('Selected companies:', newSelection);
      this.selectedCompanies = newSelection; // Update parent's state
    },

    async createCompanyItem() {
      this.isCreating = true;
      try {
        await this.createCompany({ idItem: uuidv4(), sName: this.companyName, sEmail: this.companyEmail });
      } catch (error) {
        console.error('Error creating company', error);
      } finally {
        this.isCreating = false;
      }
    },
  },
  mounted() {
    // this.fetchCompanies();
  }
};
</script>