<template>
  <v-col v-if="id">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="w-full">
          <!-- Header Section -->
          <v-card-title v-if="machine" class="text-2xl text-sv-text">{{ machine.sFleetNumber }}</v-card-title>
          <v-card-subtitle v-if="machine" class="text-sm tracking-widest text-sv-text uppercase">{{ machine.sMake }} {{
            machine.sModel
            }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :md="isMobile() ? 12 : 7">
        <GraphOnlyModule v-if="machine" :title="machine.sFleetNumber + ' Detections'" :detections="machineDetections" />
      </v-col>
      <v-col cols="12" :md="isMobile() ? 12 : 5">
        <Heatmap :heatmapData="machineDetections" :title="'Heatmap'" :showMarkers="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :md="isMobile() ? 12 : 6">
        <DetectionDataTable :idVisionAI="id" />
      </v-col>
      <v-col cols="12" :md="isMobile() ? 12 : 6">
        <SystemStatusDataTable :idVisionAI="id" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" :md="isMobile() ? 12 : 6">
        <PrestartDataTable :idVisionAI="id" />
      </v-col>
      <v-col cols="12" :md="isMobile() ? 12 : 6">
        <StartupShutdownDataTable :idVisionAI="id" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import GraphOnlyModule from "@/modules/GraphOnlyModule.vue";
import DetectionDataTable from "@/components/dataTables/DetectionDataTable.vue";
import StartupShutdownDataTable from "@/components/dataTables/StartupShutdownDataTable.vue";
import SystemStatusDataTable from "@/components/dataTables/SystemStatusDataTable.vue";
import PrestartDataTable from "@/components/dataTables/PrestartDataTable.vue";
import Heatmap from "@/components/maps/Heatmap.vue";

import { mapState } from "vuex";

export default {
  name: "MachineDetailPage",
  components: {
    GraphOnlyModule,
    DetectionDataTable,
    StartupShutdownDataTable,
    SystemStatusDataTable,
    PrestartDataTable,
    Heatmap
  },
  
  data() {
    return {
    };
  },
  props: ["id"],
  computed: {
    ...mapState({
      machine(state) {
        return state.machines.filter(
          machine => machine.machineStVisionAIIdItem === this.id
        )[0];
      },
      machineDetections(state) {
        return state.detections.filter(
          detection => detection.idVisionAI === this.id
        );
      }
    })
  },
  async mounted() {
    // Access `id` from the route parameters
    // this.id = this.$route.params.id || this.id;

    // Get the machine data
    console.log("Machine:", this.machine);
    console.log("Graph Start:", this.$store.state.graph.start.toISO());
    console.log("Graph End:", this.$store.state.graph.end.toISO());
    // Sometimes the page can be navigated to from a location where we do not request detection data first. So we need to check if the detection data is already loaded. If not, we need to load it.
    if (this.$store.state.detections.length === 0 && this.$store.state.graph.start && this.$store.state.graph.end) {
      await this.$store.dispatch("setGraphDetectionsBetweenTimePeriod", {
        start: this.$store.state.graph.start,
        end: this.$store.state.graph.end
      });
    }
    console.log("Detections After Load:", this.$store.state.detections);

  },
  methods: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>
<style scoped>
.machine-camera-entry {
  flex-basis: 50%;
}
</style>
