<template>
    <GenericDataTable :headers="headers" :query="query" :index="'listStartupShutdowns'" :exportDataType="'Startup Logs'"
        :expandedRowData="expandedRowData" :title="title" :startTimestamp="graphState[0]" :idVisionAI="idVisionAI"
        :endTimestamp="graphState[1]" />
</template>

<script>
import GenericDataTable from '@/components/dataTables/GenericDataTable.vue';
import * as queries from "@/graphql/queries";
import { mapState } from "vuex";

export default {
    name: 'StartupShutdownDataTable',
    components: {
        GenericDataTable
    },
    props: {
        title: {
            type: String,
            default: 'System Runtime Logs'
        },
        displayHeading: {
            type: Boolean,
            default: true
        },
        idVisionAI: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapState({
            graphState(state) {
                return [state.graph.start, state.graph.end];
            }
        }),
        headers() {
            if (this.idVisionAI !== '') {
                return [
                    { align: 'start', key: 'iSystemStartup', title: 'Timestamp', sortable: true },
                    { key: 'iTotalRuntime', title: 'Runtime', sortable: true },
                ];
            }
            return [
                { align: 'start', key: 'iSystemStartup', title: 'Timestamp', sortable: true },
                { key: 'sFleetNumber', title: 'Fleet Number', sortable: true },
                { key: 'iTotalRuntime', title: 'Runtime', sortable: true },
            ];
        },
        query() {
            return queries.listStartupShutdowns;
        },
        expandedRowData() {
            return {
                'sFleetNumber': 'Fleet Number',
                'iSystemStartup': 'Startup Timestamp',
                'iSystemShutdown': 'Shutdown Timestamp',
                'iStartupTimeTaken': 'Startup Time Taken',
                'iTotalRuntime': 'Runtime',
                'sSystemState': 'Event Type',
            };
        }
    }
};
</script>