<template>
  <div
    @click="triggerSelectOptionDropdown"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ 'bg-sv-primary-hover': hover }"
    class="bg-sv-primary py-2 px-4 cursor-pointer tracking-widest flex flex-row justify-between items-center relative mr-1px mb-1px"
  >
    <div class="font-2xl text-sv-white uppercase font-medium">
      {{ chipTitle }}
    </div>
    <font-awesome-icon class="text-sv-white ml-2 -mt-1" icon="chevron-down" />
    <div
      v-if="isDropdownOpen && this.type === 'date-range'"
      class="absolute right-0 z-50 top-16"
    >
      <DatePicker
        id="filterDatePicker"
        :value="date"
        @update:modelValue="handleDate"
        range
        inline
      />
    </div>
    <div
      v-if="isDropdownOpen && this.type !== 'date-range'"
      class="absolute z-50 right-0 top-16"
    >
      <OptionDropdown
        :contentType="type"
        :options="filterTypes"
        @set-selected-value="setFilterValue"
      />
    </div>
  </div>
</template>
<script>
import OptionDropdown from "./OptionDropdown.vue";
import { ref } from "vue";

export default {
  name: "FilterOptionChip",
  components: {
    OptionDropdown,
  },
  computed: {
    filterTypes() {
      console.log(this.getFilterTypes());
      return this.getFilterTypes();
    },
  },
  data() {
    const date = ref();

    const handleDate = (modelData) => {
      date.value = modelData;
      this.setDateFilterValue(date.value);
      this.isDropdownOpen = false;
    };
    return {
      hover: false,
      isDropdownOpen: false,
      selectedFilterType: [],
      chipTitle: this.title,
      date,
      handleDate,
    };
  },
  emits: ["filter-option-set", "open-dropdown", "close-dropdown"],
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  methods: {
    triggerSelectOptionDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.$emit("open-dropdown");
      } else if (!this.isDropdownOpen) {
        this.$emit("close-dropdown");
      }
    },
    getFilterTypes() {
      let filterOptions = [];
      console.log(this.type);
      switch (this.type) {
        case "site":
          filterOptions = this.sites;
          break;
        case "sMake":
          filterOptions = this.getAllMakes(this.$store.state.machines);
          break;
        case "sModel":
          filterOptions = this.getAllModels(this.$store.state.machines);
          break;
        case "sMachineType":
          filterOptions = this.getAllMachineTypes();
          break;
        case "idCompanyPartition":
          filterOptions = this.getAllCompanies(this.$store.state.detections);
          break;
        case "sType":
          filterOptions = this.getAllDetectionTypes(
            this.$store.state.detections
          );
          break;
        case "iDuration":
          filterOptions = this.getAllDurations(this.$store.state.detections);
          break;
        case "iCameraZoneID":
          filterOptions = this.getAllDetectionZones(
            this.$store.state.detections
          );
          break;
      }
      return filterOptions;
    },
    async getAllSites() {
      // getUserSites(id)
      // retrieve user
      // try {
      //     const sitesResults = await API.graphql({ query: queries.getUserSites, variables: {id: store.state.user.username} });
      //     this.getFilterTypes();
      //     return await sitesResults.data.getUserSites;
      // } catch (error) {
      //     console.error(error)
      // }
    },
    getAllMakes(machineList) {
      // list machines, then get each make inside each machine
      let makeList = [];
      for (var i = 0; i < machineList.length; i++) {
        makeList.push(machineList[i].sMake);
      }

      let filteredMakeList = [...new Set(makeList)];
      return filteredMakeList;
    },
    getAllModels(machineList) {
      // list mahcines, then get each model name inside each machine
      let modelList = [];
      for (var i = 0; i < machineList.length; i++) {
        modelList.push(machineList[i].sModel);
      }
      let filteredModelList = [...new Set(modelList)];
      return filteredModelList;
    },
    getAllCompanies(detectionList) {
      let companyList = [];
      for (var i = 0; i < detectionList.length; i++) {
        companyList.push(detectionList[i].idCompanyPartition);
      }
      let filteredCompanyList = [...new Set(companyList)];
      return filteredCompanyList;
    },
    getAllDetectionTypes(detectionList) {
      let detectionTypeList = [];
      for (var i = 0; i < detectionList.length; i++) {
        for (var j = 0; j < detectionList[i].stDetections.length; j++) {
          detectionTypeList.push(detectionList[i].stDetections[j].sType);
        }
      }
      let filteredDetectionTypeList = [...new Set(detectionTypeList)];
      return filteredDetectionTypeList;
    },
    getAllDetectionZones(detectionList) {
      let detectionZoneList = [];
      for (var i = 0; i < detectionList.length; i++) {
        for (var j = 0; j < detectionList[i].stDetections.length; j++) {
          detectionZoneList.push(
            detectionList[i].stDetections[j].iCameraZoneID
          );
        }
      }
      let filteredDetectionZoneList = [...new Set(detectionZoneList)];
      return filteredDetectionZoneList;
    },
    getAllDurations(detectionList) {
      let durationList = [];
      for (var i = 0; i < detectionList.length; i++) {
        durationList.push(detectionList[i].iDuration);
      }
      let filteredDurationList = [...new Set(durationList)];
      return filteredDurationList;
    },
    getAllMachineTypes() {
      // list machines, then get each model name inside each type
      // will need an update to the machine type schema
    },
    setFilterValue(filterValue) {
      this.chipTitle = filterValue;
      this.selectedFilterValue = filterValue;
      this.$emit("filter-option-set", {
        filterType: this.type,
        filterValue: this.selectedFilterValue,
      });
    },
    setDateFilterValue(filterValue) {
      var dateText = "";
      if (filterValue[1] !== null) {
        dateText =
          filterValue[0].toLocaleString("en-AU") +
          " - " +
          filterValue[1].toLocaleString("en-AU");
      } else {
        dateText = filterValue[0].toLocaleString("en-AU");
      }
      this.chipTitle = dateText;
      this.selectedFilterValue = filterValue;
      this.$emit("filter-option-set", {
        filterType: this.type,
        filterValue: this.selectedFilterValue,
      });
    },
  },
};
</script>
