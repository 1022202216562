<template>
    <div v-if="options !== null">
        <div v-if="options.name === null">
            <li
                @click="$emit('click-event')"
                @mouseenter="hover = true"
                @mouseleave="hover = false"
                @mousedown="selectOption(options)"
                class="py-4 px-6 cursor-pointer text-sv-text text-base tracking-widest uppercase flex flex-row"
                :class="{'bg-sv-primary text-sv-white' : hover}"
                >

                        <div class="mr-2">{{options}}</div>
                        <div>option should display</div>
                    <font-awesome-icon v-if="isSelected" class="ml-auto" icon="check"></font-awesome-icon>
            </li>
        </div>
        <div v-if="options.name !== null">
            <li
                @click="$emit('click-event')"
                @mouseenter="hover = true"
                @mouseleave="hover = false"
                @mousedown="selectOption(options)"
                class="py-4 px-6 cursor-pointer text-sv-text text-base tracking-widest uppercase flex flex-row"
                :class="{'bg-sv-primary text-sv-white' : hover}"
                >
                    <div class="mr-2">{{options.name || options}}</div>
                    <div v-if="options.value === 'custom-date'">
                        <font-awesome-icon class="ml-auto" icon="calendar" />
                    </div>
                    <font-awesome-icon v-if="isSelected" class="ml-auto" icon="check"></font-awesome-icon>
            </li>
        </div>

    </div>
    <div v-if="options === null">
        <li
            class="py-4 px-6 cursor-pointer text-sv-text text-base tracking-widest uppercase flex flex-row"
            :class="{'bg-sv-primary text-sv-white' : hover}"
            >
                <div class="mr-2">{{fallbackTitle}}</div>
        </li>
    </div>
</template>
<script>
    export default {
        name: 'OptionEntry',
        emits: [
            'click-event',
            'deselect-all',
            'trigger-dropdown',
            'set-selected-value'
        ],
        data() {
            return{
                hover: false,
                selected: {},
                isSelected: false
            }
        },
        props:{
            options: {},
            fallbackTitle: {
                type: String,
                default: ''
            }
        },
        methods: {
            selectOption(option) {
                this.$emit('deselect-all');
                this.selected = option;
                this.isSelected = true;
                this.$emit('trigger-dropdown');
                this.$emit('set-selected-value', this.selected);
            }
        }
    }
</script>