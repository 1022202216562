
function timezoneAbbrevToLocale(abbreviation) {
    const timezoneMappings = {
        'ACDT': 'Australia/Adelaide',  // Australian Central Daylight Saving Time
        'ACST': 'Australia/Adelaide',  // Australian Central Standard Time
        // 'ACT': 'America/Eirunepe',  // Acre Time
        'ACT': 'Asia/Singapore',  // ASEAN Common Time (proposed)
        'ACWST': 'Australia/Eucla',  // Australian Central Western Standard Time (unofficial)
        'ADT': 'America/Halifax',  // Atlantic Daylight Time
        'AEDT': 'Australia/Sydney',  // Australian Eastern Daylight Saving Time
        'AEST': 'Australia/Brisbane',  // Australian Eastern Standard Time
        'AET': 'Australia/Sydney',  // Australian Eastern Time
        'AFT': 'Asia/Kabul',  // Afghanistan Time
        'AKDT': 'America/Anchorage',  // Alaska Daylight Time
        'AKST': 'America/Anchorage',  // Alaska Standard Time
        'ALMT': 'Asia/Almaty',  // Alma-Ata Time
        'AMST': 'America/Manaus',  // Amazon Summer Time (Brazil)
        // 'AMT': 'America/Boa_Vista',  // Amazon Time (Brazil)
        'AMT': 'Asia/Yerevan',  // Armenia Time
        'ANAT': 'Asia/Anadyr',  // Anadyr Time
        'AQTT': 'Asia/Aqtau',  // Aqtobe Time
        'ART': 'America/Argentina/Buenos_Aires',  // Argentina Time
        'AST': 'Asia/Riyadh',  // Arabia Standard Time
        // 'AST': 'America/Port_of_Spain',  // Atlantic Standard Time
        'AWST': 'Australia/Perth',  // Australian Western Standard Time
        'AZOST': 'Atlantic/Azores',  // Azores Summer Time
        'AZOT': 'Atlantic/Azores',  // Azores Standard Time
        'AZT': 'Asia/Baku',  // Azerbaijan Time
        'BNT': 'Asia/Brunei',  // Brunei Time
        'BIOT': 'Indian/Chagos',  // British Indian Ocean Time
        'BIT': 'Pacific/Baker',  // Baker Island Time
        'BOT': 'America/La_Paz',  // Bolivia Time
        'BRST': 'America/Sao_Paulo',  // Brasília Summer Time
        'BRT': 'America/Sao_Paulo',  // Brasília Time
        // 'BST': 'Asia/Dhaka',  // Bangladesh Standard Time
        // 'BST': 'Pacific/Bougainville',  // Bougainville Standard Time
        'BST': 'Europe/London',  // British Summer Time (British Standard Time from Mar 1968 to Oct 1971)
        'BTT': 'Asia/Thimphu',  // Bhutan Time
        'CAT': 'Africa/Maputo',  // Central Africa Time
        'CCT': 'Indian/Cocos',  // Cocos Islands Time
        'CDT': 'America/Chicago',  // Central Daylight Time (North America)
        // 'CDT': 'America/Havana',  // Cuba Daylight Time
        'CEST': 'Europe/Paris',  // Central European Summer Time
        'CET': 'Europe/Paris',  // Central European Time
        'CHADT': 'Pacific/Chatham',  // Chatham Daylight Time
        'CHAST': 'Pacific/Chatham',  // Chatham Standard Time
        'CHOT': 'Asia/Choibalsan',  // Choibalsan Standard Time
        'CHOST': 'Asia/Choibalsan',  // Choibalsan Summer Time
        'CHST': 'Pacific/Guam',  // Chamorro Standard Time
        'CHUT': 'Pacific/Chuuk',  // Chuuk Time
        'CIST': 'Pacific/Enderbury',  // Clipperton Island Standard Time
        'CKT': 'Pacific/Rarotonga',  // Cook Island Time
        'CLST': 'America/Santiago',  // Chile Summer Time
        'CLT': 'America/Santiago',  // Chile Standard Time
        'COST': 'America/Bogota',  // Colombia Summer Time
        'COT': 'America/Bogota',  // Colombia Time
        // 'CST': 'America/Belize',  // Central Standard Time (Central America)
        // 'CST': 'Asia/Shanghai',  // China Standard Time
        // 'CST': 'America/Havana',  // Cuba Standard Time
        'CST': 'America/Chicago',  // Central Standard Time (North America)
        'CVT': 'Atlantic/Cape_Verde',  // Cape Verde Time
        'CWST': 'Australia/Eucla',  // Central Western Standard Time (Australia) unofficial
        'CXT': 'Indian/Christmas',  // Christmas Island Time
        'DAVT': 'Antarctica/Davis',  // Davis Time
        'DDUT': 'Antarctica/DumontDUrville',  // Dumont d'Urville Time
        'DFT': 'Europe/Paris',  // AIX-specific equivalent of Central European Time
        'EASST': 'Pacific/Easter',  // Easter Island Summer Time
        'EAST': 'Pacific/Easter',  // Easter Island Standard Time
        'EAT': 'Africa/Nairobi',  // East Africa Time
        'ECT': 'America/Guayaquil',  // Eastern Caribbean Time (does not recognise DST)
        // 'ECT': 'America/Guayaquil',  // Ecuador Time
        'EDT': 'America/New_York',  // Eastern Daylight Time (North America)
        'EEST': 'Europe/Athens',  // Eastern European Summer Time
        'EET': 'Europe/Athens',  // Eastern European Time
        'EGST': 'Atlantic/South_Georgia',  // Eastern Greenland Summer Time
        'EGT': 'Atlantic/South_Georgia',  // Eastern Greenland Time
        'EST': 'America/New_York',  // Eastern Standard Time (North America)
        'ET': 'America/New_York',  // Eastern Time (North America)
        'FET': 'Europe/Minsk',  // Further-eastern European Time
        'FJT': 'Pacific/Fiji',  // Fiji Time
        'FKST': 'Atlantic/Stanley',  // Falkland Islands Summer Time
        'FKT': 'Atlantic/Stanley',  // Falkland Islands Time
        'FNT': 'America/Noronha',  // Fernando de Noronha Time
        'GALT': 'Pacific/Galapagos',  // Galápagos Time
        'GAMT': 'Pacific/Gambier',  // Gambier Islands Time
        'GET': 'Asia/Tbilisi',  // Georgia Standard Time
        'GFT': 'America/Cayenne',  // French Guiana Time
        'GILT': 'Pacific/Tarawa',  // Gilbert Island Time
        'GIT': 'Pacific/Gambier',  // Gambier Island Time
        'GMT': 'Europe/London',  // Greenwich Mean Time
        // 'GST': 'Atlantic/South_Georgia',  // South Georgia and the South Sandwich Islands Time
        'GST': 'Asia/Dubai',  // Gulf Standard Time
        'GYT': 'America/Guyana',  // Guyana Time
        'HDT': 'Pacific/Honolulu',  // Hawaii–Aleutian Daylight Time
        'HAEC': 'Europe/Paris',  // Heure Avancée d'Europe Centrale French-language name for CEST
        'HST': 'Pacific/Honolulu',  // Hawaii–Aleutian Standard Time
        'HKT': 'Asia/Hong_Kong',  // Hong Kong Time
        'HMT': 'Asia/Almaty',  // Heard and McDonald Islands Time
        'HOVST': 'Asia/Hovd',  // Hovd Summer Time (not used from 2017-present)
        'HOVT': 'Asia/Hovd',  // Hovd Time
        'ICT': 'Asia/Bangkok',  // Indochina Time
        'IDLW': 'Pacific/Midway',  // International Date Line West time zone
        'IDT': 'Asia/Jerusalem',  // Israel Daylight Time
        'IOT': 'Indian/Chagos',  // Indian Ocean Time
        'IRDT': 'Asia/Tehran',  // Iran Daylight Time
        'IRKT': 'Asia/Irkutsk',  // Irkutsk Time
        'IRST': 'Asia/Tehran',  // Iran Standard Time
        // 'IST': 'Asia/Kolkata',  // Indian Standard Time
        'IST': 'Europe/Dublin',  // Irish Standard Time
        // 'IST': 'Asia/Jerusalem',  // Israel Standard Time
        'JST': 'Asia/Tokyo',  // Japan Standard Time
        'KALT': 'Europe/Kaliningrad',  // Kaliningrad Time
        'KGT': 'Asia/Bishkek',  // Kyrgyzstan Time
        'KOST': 'Pacific/Kosrae',  // Kosrae Time
        'KRAT': 'Asia/Krasnoyarsk',  // Krasnoyarsk Time
        'KST': 'Asia/Seoul',  // Korea Standard Time
        'LHST': 'Australia/Lord_Howe',  // Lord Howe Standard Time
        // 'LHST': 'Australia/Lord_Howe',  // Lord Howe Summer Time
        'LINT': 'Pacific/Kiritimati',  // Line Islands Time
        'MAGT': 'Asia/Magadan',  // Magadan Time
        'MART': 'Pacific/Marquesas',  // Marquesas Islands Time
        'MAWT': 'Antarctica/Mawson',  // Mawson Station Time
        'MDT': 'America/Denver',  // Mountain Daylight Time (North America)
        'MET': 'Europe/Paris',  // Middle European Time (same zone as CET)
        'MEST': 'Europe/Paris',  // Middle European Summer Time (same zone as CEST)
        'MHT': 'Pacific/Kwajalein',  // Marshall Islands Time
        'MIST': 'Antarctica/Macquarie',  // Macquarie Island Station Time
        'MIT': 'Pacific/Marquesas',  // Marquesas Islands Time
        'MMT': 'Asia/Yangon',  // Myanmar Standard Time
        'MSK': 'Europe/Moscow',  // Moscow Time
        'MST': 'Asia/Kuala_Lumpur',  // Malaysia Standard Time
        // 'MST': 'America/Phoenix',  // Mountain Standard Time (North America)
        'MUT': 'Indian/Mauritius',  // Mauritius Time
        'MVT': 'Indian/Maldives',  // Maldives Time
        'MYT': 'Asia/Kuala_Lumpur',  // Malaysia Time
        'NCT': 'Pacific/Noumea',  // New Caledonia Time
        'NDT': 'America/St_Johns',  // Newfoundland Daylight Time
        'NFT': 'Pacific/Norfolk',  // Norfolk Island Time
        'NOVT': 'Asia/Novosibirsk',  // Novosibirsk Time
        'NPT': 'Asia/Kathmandu',  // Nepal Time
        'NST': 'America/St_Johns',  // Newfoundland Standard Time
        'NT': 'America/St_Johns',  // Newfoundland Time
        'NUT': 'Pacific/Niue',  // Niue Time
        'NZDT': 'Pacific/Auckland',  // New Zealand Daylight Time
        'NZST': 'Pacific/Auckland',  // New Zealand Standard Time
        'OMST': 'Asia/Omsk',  // Omsk Time
        'ORAT': 'Asia/Oral',  // Oral Time
        'PDT': 'America/Los_Angeles',  // Pacific Daylight Time (North America)
        'PET': 'America/Lima',  // Peru Time
        'PETT': 'Asia/Kamchatka',  // Kamchatka Time
        'PGT': 'Pacific/Port_Moresby',  // Papua New Guinea Time
        'PHOT': 'Pacific/Enderbury',  // Phoenix Island Time
        'PHT': 'Asia/Manila',  // Philippine Time
        'PHST': 'Asia/Manila',  // Philippine Standard Time
        'PKT': 'Asia/Karachi',  // Pakistan Standard Time
        'PMDT': 'America/Miquelon',  // Saint Pierre and Miquelon Daylight Time
        'PMST': 'America/Miquelon',  // Saint Pierre and Miquelon Standard Time
        'PONT': 'Pacific/Pohnpei',  // Pohnpei Standard Time
        'PST': 'America/Los_Angeles',  // Pacific Standard Time (North America)
        'PWT': 'Pacific/Palau',  // Palau Time
        'PYST': 'America/Asuncion',  // Paraguay Summer Time
        'PYT': 'America/Asuncion',  // Paraguay Time
        'RET': 'Indian/Reunion',  // Réunion Time
        'ROTT': 'Antarctica/Rothera',  // Rothera Research Station Time
        'SAKT': 'Asia/Sakhalin',  // Sakhalin Island Time
        'SAMT': 'Europe/Samara',  // Samara Time
        'SAST': 'Africa/Johannesburg',  // South African Standard Time
        'SBT': 'Pacific/Guadalcanal',  // Solomon Islands Time
        'SCT': 'Indian/Mahe',  // Seychelles Time
        'SDT': 'Pacific/Apia',  // Samoa Daylight Time
        'SGT': 'Asia/Singapore',  // Singapore Time
        'SLST': 'Asia/Colombo',  // Sri Lanka Standard Time
        'SRET': 'Asia/Srednekolymsk',  // Srednekolymsk Time
        'SRT': 'America/Paramaribo',  // Suriname Time
        // 'SST': 'Pacific/Midway',  // Samoa Standard Time
        'SST': 'Asia/Singapore',  // Singapore Standard Time
        'SYOT': 'Antarctica/Syowa',  // Showa Station Time
        'TAHT': 'Pacific/Tahiti',  // Tahiti Time
        'THA': 'Asia/Bangkok',  // Thailand Standard Time
        'TFT': 'Indian/Kerguelen',  // French Southern and Antarctic Time
        'TJT': 'Asia/Dushanbe',  // Tajikistan Time
        'TKT': 'Pacific/Fakaofo',  // Tokelau Time
        'TLT': 'Asia/Dili',  // Timor Leste Time
        'TMT': 'Asia/Ashgabat',  // Turkmenistan Time
        'TRT': 'Europe/Istanbul',  // Turkey Time
        'TOT': 'Pacific/Tongatapu',  // Tonga Time
        'TST': 'Asia/Taipei',  // Taiwan Standard Time
        'TVT': 'Pacific/Funafuti',  // Tuvalu Time
        'ULAST': 'Asia/Ulaanbaatar',  // Ulaanbaatar Summer Time
        'ULAT': 'Asia/Ulaanbaatar',  // Ulaanbaatar Standard Time
        'UTC': 'Etc/UTC',  // Coordinated Universal Time
        'UYST': 'America/Montevideo',  // Uruguay Summer Time
        'UYT': 'America/Montevideo',  // Uruguay Standard Time
        'UZT': 'Asia/Tashkent',  // Uzbekistan Time
        'VET': 'America/Caracas',  // Venezuelan Standard Time
        'VLAT': 'Asia/Vladivostok',  // Vladivostok Time
        'VOLT': 'Europe/Volgograd',  // Volgograd Time
        'VOST': 'Antarctica/Vostok',  // Vostok Station Time
        'VUT': 'Pacific/Efate',  // Vanuatu Time
        'WAKT': 'Pacific/Wake',  // Wake Island Time
        'WAST': 'Africa/Windhoek',  // West Africa Summer Time
        'WAT': 'Africa/Lagos',  // West Africa Time
        'WEST': 'Europe/Lisbon',  // Western European Summer Time
        'WET': 'Europe/Lisbon',  // Western European Time
        'WIB': 'Asia/Jakarta',  // Western Indonesian Time
        'WIT': 'Asia/Jayapura',  // Eastern Indonesian Time
        'WITA': 'Asia/Makassar',  // Central Indonesia Time
        'WGST': 'America/Godthab',  // West Greenland Summer Time
        'WGT': 'America/Godthab',  // West Greenland Time
        'WST': 'Australia/Perth',  // Western Standard Time
        'YAKT': 'Asia/Yakutsk',  // Yakutsk Time
        'YEKT': 'Asia/Yekaterinburg',  // Yekaterinburg Time
    }
    if (abbreviation in timezoneMappings) {
        console.log('Timezone found');
        console.log(timezoneMappings[abbreviation]);
        return timezoneMappings[abbreviation];
    } else {
        console.log('Timezone not found: ' + abbreviation);
        return null; // Or handle error as needed
    }
}


module.exports = {
    timezoneAbbrevToLocale
};