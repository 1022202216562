<template>
  <div class="flex flex-col">
    <StatsBanner v-if="!isMobile()" />
    <v-row>
      <v-col cols="12" md="6">
        <TimeOfDay :title="'Time of Day Detection Frequency'" :dataToPassToGraph="$store.state.detections" />
      </v-col>
      <v-col cols="12" md="6">
        <DetectionPerMachine :title="'Detections Per Machine'" :detections="$store.state.detections" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <DetectionDataTable :defaultItemsPerPage=10 />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DetectionDataTable from "@/components/dataTables/DetectionDataTable.vue";
import StatsBanner from "@/components/StatsBanner.vue";
import TimeOfDay from "@/modules/charts/TimeOfDay.vue";
import DetectionPerMachine from "@/modules/charts/DetectionPerMachine.vue";
import { DateTime } from 'luxon';

export default {
  name: "DetectionsPage",
  components: {
    DetectionDataTable,
    StatsBanner,
    DetectionPerMachine,
    TimeOfDay
  },
  created() {
  },
  data() {
    return {
      startTime: null,
      endTime: null,
    };
  },
  methods: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    async fetchData() {
      await this.$store.dispatch("setGraphDetectionsBetweenTimePeriod", {
          start: this.startTime,
          end: this.endTime
        });
    },
  },
  async mounted() {
    this.startTime = DateTime.fromISO(this.$store.state.graph.start);
    this.endTime = DateTime.fromISO(this.$store.state.graph.end);

    await this.fetchData();                               // Call fetchData on either change

    // Watch both `graph.start` and `graph.end` together
    this.$store.watch(
      (state) => [state.graph.start, state.graph.end],  // Watching both properties
      ([newStart, newEnd]) => {                         // Destructure new values
        this.startTimestamp = newStart;                 // Update startTimestamp
        this.endTimestamp = newEnd;                     // Update endTimestamp
        this.fetchData();                               // Call fetchData on either change
        console.log("Data changed, fetching new data MachinePage.vue");
      },
      { deep: true }  // Optional: ensures nested properties are tracked
    );
  }
};
</script>


