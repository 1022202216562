import {
  Auth,
  API,
} from 'aws-amplify';
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'
import * as querySubset from "@/state/querySubset";
import {
  v4 as uuidv4
} from 'uuid';

import router from "@/helpers/router";
import { createStore } from "vuex";
// import createPersistedState from 'vuex-persistedstate';

// Import timezone functions from timezone.js
import { timezoneAbbrevToLocale } from "@/helpers/timezone"
import { DateTime } from 'luxon';
var store;

function processTimestamp(item, timestampField, timezoneField) {
  if (item && item[timestampField]) {
    const timestampString = item[timestampField].split('#')[0];
    item[timestampField] = timestampString;
    
    console.log("Original String: ", timestampString);
    const dateTime = DateTime.fromISO(timestampString);

    const localeString = timezoneAbbrevToLocale(item[timezoneField]);
    console.log("Locale String: ", localeString)

    const reZonedTime = dateTime.setZone(localeString);
    console.log("Rezoned Time: ", reZonedTime);

    let adjustedTimestamp = reZonedTime.toISO();

    console.log("Converted Datetime: ", adjustedTimestamp);
    return {
      iTimestamp: adjustedTimestamp,
      sAdjustedTimestamp: adjustedTimestamp,
      sTimestampString: dateTime.toFormat('d LLLL yyyy \'at\' hh:mm a')
    };
  }
  return null;
}

function cleanTimestamps(item) {
  const baseTimestamp = processTimestamp(item, 'iTimestamp', 'sTimeZone');
  if (baseTimestamp) {
    item.iTimestamp = baseTimestamp.sAdjustedTimestamp;
    item.sAdjustedTimestamp = baseTimestamp.sAdjustedTimestamp;
    item.sTimestampString = baseTimestamp.sTimestampString;
  }

  // stPosition timestamp processing
  const stPositionTimestamp = processTimestamp(item.stPosition, 'iTimestamp', 'sTimeZone');
  if (stPositionTimestamp) {
    item.stPosition.iTimestamp = stPositionTimestamp.sAdjustedTimestamp;
    item.stPosition.sAdjustedTimestamp = stPositionTimestamp.sAdjustedTimestamp;
  }

  // stDetections timestamp processing
  const stDetectionsTimestamp = processTimestamp(item.stDetections, 'iTimestamp', 'sTimeZone');
  if (stDetectionsTimestamp) {
    item.stDetections.iTimestamp = stDetectionsTimestamp.sAdjustedTimestamp;
    item.stDetections.sAdjustedTimestamp = stDetectionsTimestamp.sAdjustedTimestamp;
  }
  return item;
}



async function setItemsBetweenTimePeriod({ commit, query, index, mutation, start, end }) {
  // Convert to ISO string
  start = start.toISO();
  end = end.toISO();

  console.log(`setItemsBetweenTimePeriod Start: ${start} End: ${end}`);
  try {
    let allItems = [];
    let nextToken = null;

    do {
      console.log("Requesting Token: ", nextToken);

      const itemsResult = await API.graphql({
        query,
        variables: {
          idCompanyPartition: "",
          iTimestamp: { between: [start, end] },
          limit: 1000,
          nextToken: nextToken,
        },
      });

      const items = itemsResult.data[index].items;
      nextToken = itemsResult.data[index].nextToken;
      let processedTimestampItems = [];

      // Process the timestamps for each item
      for (let i = 0; i < items.length; i++) {
        // Base level timestamp processing
        processedTimestampItems[i] = cleanTimestamps(items[i]);
      }

      // Add the items to the allItems array
      allItems = allItems.concat(processedTimestampItems);
      commit(mutation, allItems);
    } while (nextToken); // Continue fetching until no nextToken is returned

    // Commit all items at once
    // commit(mutation, allItems);

  } catch (error) {
    console.error(error);
  }
}

const start = DateTime.now().minus({ days: 7 });
const end = DateTime.now();
function resetState(state) {
  // Reset each property to its initial value
  state.graph.start = null;
  state.graph.end = null;
  state.graph.graphDataDateRangeTitle = "Last 7 Days";
  state.graph.graphDataDateRangeType = "last-week";

  state.authorized = null;
  state.user = null;
  state.company = null;
  state.companyFromQuery = null;
  state.userFromQuery = null;
  state.isAdmin = false;
  state.isLoading = false;
  state.email = null;
  state.phone_number = null;
  state.company_name = null;
  state.sub = null;
  state.username = null;
  state.confirm = false;
  state.notifications = [];
  state.loginError = "";
  state.machines = [];
  state.detections = [];
  state.startupShutdowns = [];
  state.prestarts = [];
  state.systemStatuses = [];
  state.activeDetectionFilters = [];
  state.isMobile = false;
  state.companies = [];
  state.dataCache = {}; // Reset cache data

  state.admin.users = [];
  state.admin.machines = [];
  state.admin.visionAIs = [];

  state.notification.type = '';
  state.notification.message = '';
  state.notification.isVisible = false;
}


// TODO: Make graph component take defaults from here:
store = createStore({
  state() {
    return {
      graph: {
        start: start,
        end: end,
        graphDataDateRangeTitle: "Last 7 Days",
        graphDataDateRangeType: "last-week",
      },
      companyFromQuery: null,
      userFromQuery: null,
      authorized: null,
      user: null,
      isAdmin: false,
      isLoading: false,
      company: null,
      email: null,
      phone_number: null,
      company_name: null,
      sub: null,
      username: null,
      confirm: false,
      notifications: [],
      loginError: "",
      machines: [],
      detections: [],
      startupShutdowns: [],
      prestarts: [],
      systemStatuses: [],
      activeDetectionFilters: [],
      isMobile: false,
      companies: [],
      dataCache: {}, // cache data for each index type, e.g., 'listMachines', 'listDetections', etc.
      admin: {
        users: [],
        machines: [],
        visionAIs: []
      },
      notification: {
        type: '',
        message: '',
        isVisible: false
      }
    };
  },
  mutations: {
    user(state, user) {
      state.authorized =
        !!user && user.attributes && user.attributes.email_verified;
      state.user = user;
    },
    userFromQuery(state, user) {
      state.userFromQuery = user.data.getUser;
    },
    companyFromQuery(state, company) {
      state.companyFromQuery = company.data.getCompany;
    },
    confirm(state, showConfirm) {
      state.confirm = !!showConfirm;
    },
    machines(state, machines) {
      state.machines = machines;
    },
    detections(state, detections) {
      state.detections = detections;
    },
    startupShutdowns(state, startupShutdowns) {
      state.startupShutdowns = startupShutdowns;
    },
    prestarts(state, prestarts) {
      state.prestarts = prestarts;
    },
    systemStatuses(state, systemStatuses) {
      state.systemStatuses = systemStatuses;
    },
    activeDetectionFilters(state, filters) {
      state.activeDetectionFilters = filters;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },

    addMachine(state, machine) {
      state.machines.push(machine);
    },
    updateMachine(state, updatedMachine) {
      const index = state.machines.findIndex(machine => machine.id === updatedMachine.id);
      if (index !== -1) {
        state.machines.splice(index, 1, updatedMachine);
      }
    },
    deleteMachine(state, machineId) {
      state.machines = state.machines.filter(machine => machine.id !== machineId);
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setUsers(state, users) {
      state.users = users;
    },
    addCompany(state, company) {
      state.companies.push(company);
    },
    modifyCompany(state, updatedCompany) {
      const index = state.companies.findIndex(c => c.idItem === updatedCompany.idItem);
      if (index !== -1) {
        state.companies.splice(index, 1, updatedCompany);
      }
    },
    removeCompany(state, companyId) {
      state.companies = state.companies.filter(company => company.idItem !== companyId);
    },
    setNotification(state, { type, message, isVisible }) {
      state.notification = { type, message, isVisible };
    },
    hideNotification(state) {
      state.notification.isVisible = false;
    },
    SET_DATA(state, { index, items, nextToken }) {
      if (!state.dataCache[index]) {
        state.dataCache[index] = {
          items: [],
          nextToken: null,
        };
      }
      state.dataCache[index].items = state.dataCache[index].items.concat(items);
      state.dataCache[index].nextToken = nextToken;
    },
  },
  getters: {
    getData: (state) => (index) => {
      if (!state.dataCache[index]) {
        // Initialize items as an empty array if not present
        state.dataCache[index] = { items: [], nextToken: null };
      }
      return state.dataCache[index].items;
    },
    getNextToken: (state) => (index) => {
      if (!state.dataCache[index]) {
        // Initialize nextToken as null if not present
        state.dataCache[index] = { items: [], nextToken: null };
      }
      return state.dataCache[index].nextToken;
    },
  },
  actions: {
    async fetchCachedData({ commit, state }, { index, queryVariables, query }) {

      // TODO: Implement caching by uncommenting the following code:
      // TODO: Rework other parts of the code to enable caching and clearing of cache when required
      const cache = state.dataCache[index];

      if (cache && cache.items.length && !queryVariables.nextToken) {
        // Data exists and there's no need to paginate further
        return Promise.resolve(cache.items);
      }

      // Fetch data from the API
      return await API.graphql({ query, variables: queryVariables }).then(({ data }) => {

        let fetchedData = [];
        // Process the timestamps for each item
        for (let i = 0; i < data[index].items.length; i++) {
          // Base level timestamp processing
          fetchedData[i] = cleanTimestamps(data[index].items[i]);
        }
        const nextToken = data[index].nextToken;

        commit('SET_DATA', { index, items: fetchedData, nextToken });
        return fetchedData;
      });
    },
    async fetchTableData({ commit, state }, { index, queryVariables, query }) {
      // Fetch data from the API
      return await API.graphql({ query, variables: queryVariables }).then(({ data }) => {

        let fetchedData = [];
        // Process the timestamps for each item
        for (let i = 0; i < data[index].items.length; i++) {
          // Base level timestamp processing
          fetchedData[i] = cleanTimestamps(data[index].items[i]);
        }
        const nextToken = data[index].nextToken;

        commit('SET_DATA', { index, items: [], nextToken });
        return {data: fetchedData, token: nextToken};
      });
    },

    async refreshCachedData({ commit, state }, { index, queryVariables, query }) {
      state.dataCache[index] = {
        items: [],
        nextToken: null
      };
      const cache = state.dataCache[index];

      if (cache && cache.items.length && !queryVariables.nextToken) {
        // Data exists and there's no need to paginate further
        return Promise.resolve(cache.items);
      }

      // Fetch data from the API
      return await API.graphql({ query, variables: queryVariables }).then(({ data }) => {

        let fetchedData = [];
        // Process the timestamps for each item
        for (let i = 0; i < data[index].items.length; i++) {
          // Base level timestamp processing
          fetchedData[i] = cleanTimestamps(data[index].items[i]);
        }
        const nextToken = data[index].nextToken;

        commit('SET_DATA', { index, items: [], nextToken });
        return {data: fetchedData, token: nextToken};
      });
    },

    // Action to show notification and hide it after timeout
    showNotification({ commit, dispatch }, { type, message }) {
      // Show the notification
      commit('setNotification', { type, message, isVisible: true });

      // Set a timeout to hide the notification after 2.5 seconds
      setTimeout(() => {
        commit('setNotification', { type: '', message: '', isVisible: false });
      }, 2500);
    },
    detectDevice({ commit, dispatch }) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice = /android|iPhone|iPad|iPod|opera mini|iemobile|windows phone|blackberry/i.test(userAgent);
      const isSmallScreen = window.matchMedia("(max-width: 760px)").matches;
      const isMobile = isMobileDevice || isSmallScreen;

      commit('setIsMobile', isMobile);
    },
    async login({ dispatch, state }, { email, password }) {
      state.loginError = "";
      try {
        await Auth.signIn(email, password);

        // push to dashboard page
        // push to route
        router.push("dashboard");
        await dispatch("setAllMachines");
      } catch (err) {
        state.loginError = err.message;
        return;
      }
      await dispatch("fetchUser");
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    async signup({ commit, state }, { email, password }) {
      state.signupError = "";
      try {
        await Auth.signUp({
          username: email,
          email: email,
          password: password,
          attributes: {
            email: email,
          },
          validationData: [],
        });
        //switch email confirmation form
        commit("confirm", true);
      } catch (err) {
        if (err) state.signupError = err.message || err;
        commit("confirm", false);
      }
    },
    async confirm({ commit, state }, { email, code }) {
      state.confirmError = "";
      try {
        await Auth.confirmSignUp(email, code, {
          forceAliasCreation: true,
        });
      } catch (err) {
        if (err) state.confirmError = err.message || err;
        return;
      }
      commit("confirm", false);
    },
    async authState({ commit, dispatch }, state) {
      if (state === "signedIn") await dispatch("fetchUser");
      else commit("user", null);
    },
    async getUserFromQuery({ commit, dispatch }) {
      try {
        let userFromQuery = await API.graphql({
          query: queries.getUser,
          variables: { idItem: "" },
        });
        commit("userFromQuery", userFromQuery);
      } catch (err) {
        commit("userFromQuery", null);
      }
    },
    // Update Machine
    async updateUser({ commit, dispatch }, user) {
      try {
        const response = await API.graphql({
          query: mutations.updateUser,
          variables: {
            input: {
              idItem: user.idItem,
              idCompanyPartition: user.idCompanyPartition,
              sEmail: user.sEmail,
              sFirstName: user.sFirstName,
              sLastName: user.sLastName,
            }
          }
        });
        if (response.data?.updateUser) {
          commit('updateUser', response.data.updateUser);
          dispatch('showNotification', { type: 'success', message: 'User updated successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error updating user: ${error.message}` });
        console.log(error);
        throw error;
      }
    },
    async getCompanyFromQuery({ commit, dispatch }) {
      try {
        let companyFromQuery = await API.graphql({
          query: queries.getCompany,
          variables: { idItem: "" },
        });
        commit("companyFromQuery", companyFromQuery);
      } catch (err) {
        commit("companyFromQuery", null);
      }
    },
    async fetchUser({ commit, dispatch }) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const expires =
          user.getSignInUserSession().getIdToken().payload.exp -
          Math.floor(new Date().getTime() / 1000);
        setTimeout(async () => {
          await dispatch("fetchUser");
        }, expires * 1000);
        commit("user", user);
        dispatch("getUserFromQuery");
      } catch (err) {
        commit("user", null);
      }
    },
    async logout({ state }) {
      try {
        resetState(state)
        await Auth.signOut();
        // commit('resetState');
        router.push("/login");
      } catch (error) {
        console.error(error);
      }
    },
    // query all machines ever created. Most companies will have up to 50 machines...
    // TODO setup pagination, this requires page numbering to be added
    async setAllMachines({ commit, dispatch }) {
      if (
        store.state.machines === null ||
        store.state.machines === undefined ||
        store.state.machines.length === 0 || // Check for an empty array
        store.state.machines <= 0 // Check for non-positive values
      ) {
        try {
          let listMachines = await API.graphql({
            query: queries.listMachines,
            variables: { idCompanyPartition: "" },
            limit: 1000
          });
          commit("machines", listMachines.data.listMachines.items);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async setDetectionsBetweenTimePeriod({ commit, dispatch }, { start, end }) {
      await setItemsBetweenTimePeriod({
        commit,
        query: queries.listDetectionEvents,
        index: "listDetectionEvents",
        mutation: "detections",
        start,
        end
      });
    },
    async setGraphDetectionsBetweenTimePeriod({ commit, dispatch }, { start, end }) {
      await setItemsBetweenTimePeriod({
        commit,
        query: querySubset.listDetectionEvents,
        index: "listDetectionEvents",
        mutation: "detections",
        start,
        end
      });
    },
    async setStartupShutdownsBetweenTimePeriod({ commit, dispatch }, { start, end }) {
      await setItemsBetweenTimePeriod({
        commit,
        query: queries.listStartupShutdowns,
        index: "listStartupShutdowns",
        mutation: "startupShutdowns",
        start,
        end
      });
    },

    async setPrestartsBetweenTimePeriod({ commit, dispatch }, { start, end }) {
      await setItemsBetweenTimePeriod({
        commit,
        query: queries.listPrestarts,
        index: "listPrestarts",
        mutation: "prestarts",
        start,
        end
      });
    },

    async setSystemStatusesBetweenTimePeriod({ commit, dispatch }, { start, end }) {
      await setItemsBetweenTimePeriod({
        commit,
        query: queries.listSystemStatuses,
        index: "listSystemStatuses",
        mutation: "systemStatuses",
        start,
        end
      });
    },

    // Fetch all companies
    async fetchCompanies({ commit, dispatch }) {
      try {
        const response = await API.graphql({ query: queries.listCompanies });
        if (response.data?.listCompanies?.items) {
          commit('setCompanies', response.data.listCompanies.items);
          console.log(response.data.listCompanies.items);
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error fetching companies: ${error.message}` });
      }
    },
    // Similarly for VisionAI
    async createVisionAI({ commit, dispatch }, visionAI) {
      try {
        const response = await API.graphql({
          query: mutations.createVisionAI,
          variables: {
            input: {
              stVPU: { sCrc: visionAI.stVPU.sCrc, sSerialNumber: visionAI.stVPU.sSerialNumber, sType: visionAI.stVPU.sType },
              idItem: visionAI.idItem,
              idCompanyPartition: visionAI.idCompanyPartition,
              visionAIStMachineIdCompanyPartition: visionAI.visionAIStMachineIdCompanyPartition,
              visionAIStMachineIdItem: visionAI.visionAIStMachineIdItem,
            }
          }
        });
        if (response.data?.createVisionAI) {
          dispatch('showNotification', { type: 'success', message: 'VisionAI created successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error creating VisionAI: ${error.message}` });
        throw error;
      }
    },
    // Update VisionAI
    async updateVisionAI({ commit, dispatch }, visionAI) {
      try {
        const response = await API.graphql({
          query: mutations.updateVisionAI,
          variables: {
            input: {
              stVPU: { sCrc: visionAI.stVPU.sCrc, sSerialNumber: visionAI.stVPU.sSerialNumber, sType: visionAI.stVPU.sType },
              idItem: visionAI.idItem,
              idCompanyPartition: visionAI.idCompanyPartition,
              visionAIStMachineIdCompanyPartition: visionAI.visionAIStMachineIdCompanyPartition,
              visionAIStMachineIdItem: visionAI.visionAIStMachineIdItem,
            }
          }
        });
        if (response.data?.updateVisionAI) {
          dispatch('showNotification', { type: 'success', message: 'VisionAI updated successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error updating VisionAI: ${error.message}` });
        throw error;
      }
    },

    // Delete VisionAI
    async deleteVisionAI({ commit, dispatch }, visionAI) {
      try {
        console.log("deleteVisionAI");
        console.log(visionAI);
        const response = await API.graphql({
          query: mutations.deleteVisionAI,
          variables: {
            input: {
              idItem: visionAI.idItem,
              idCompanyPartition: visionAI.idCompanyPartition,
            }
          }
        });
        if (response.data?.deleteVisionAI) {
          dispatch('showNotification', { type: 'success', message: 'VisionAI deleted successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error deleting VisionAI: ${error}` });
        console.log(error);
        throw error;
      }
    },





    ///
    /// QUERY MUTATIONS GRAPHQL
    ///

    // Create Machine
    async createMachine({ commit, dispatch }, machine) {
      console.log(machine);
      console.log("mutations.createMachine");
      try {
        const response = await API.graphql({
          query: mutations.createMachine,
          variables: {
            input: {
              idCompanyPartition: machine.idCompanyPartition,
              idItem: machine.idItem,
              sFleetNumber: machine.sFleetNumber,
              sModel: machine.sModel,
              sMake: machine.sMake,
              stPosition: { fLatitude: machine.stPosition.fLatitude, fLongitude: machine.stPosition.fLongitude, iTimestamp: 0 },
              sSerialNumber: machine.sSerialNumber,
              machineStVisionAIIdItem: machine.machineStVisionAIIdItem,
              machineStVisionAIIdCompanyPartition: machine.machineStVisionAIIdCompanyPartition
            }
          }
        });
        if (response.data?.createMachine) {
          commit('addMachine', response.data.createMachine);
          dispatch('showNotification', { type: 'success', message: 'Machine created successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error creating machine: ${error.message}` });
        console.log(error);
        throw error;
      }
    },

    // Update Machine
    async updateMachine({ commit, dispatch }, machine) {
      try {
        console.log("updateMachine");
        console.log(machine);
        const response = await API.graphql({
          query: mutations.updateMachine,
          variables: {
            input: {
              idCompanyPartition: machine.idCompanyPartition,
              idItem: machine.idItem,
              sFleetNumber: machine.sFleetNumber,
              sModel: machine.sModel,
              sMake: machine.sMake,
              sSerialNumber: machine.sSerialNumber,
              stPosition: { fLatitude: machine.stPosition.fLatitude, fLongitude: machine.stPosition.fLongitude, iTimestamp: 0 },
              machineStVisionAIIdItem: machine.machineStVisionAIIdItem,
              machineStVisionAIIdCompanyPartition: machine.machineStVisionAIIdCompanyPartition
            }
          }
        });
        if (response.data?.updateMachine) {
          commit('updateMachine', response.data.updateMachine);
          dispatch('showNotification', { type: 'success', message: 'Machine updated successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error updating machine: ${error}` });
        console.log(error);
        throw error;
      }
    },
    // Delete Machine
    async deleteMachine({ commit, dispatch }, machine) {
      try {
        const response = await API.graphql({
          query: mutations.deleteMachine,
          variables: {
            input: {
              idItem: machine.idItem,
              idCompanyPartition: machine.idCompanyPartition,
            }
          }
        });
        if (response.data?.deleteMachine) {
          commit('deleteMachine', machine.idItem);
          dispatch('showNotification', { type: 'success', message: 'Machine deleted successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error deleting machine: ${error.message}` });
        console.log(error);
        throw error;
      }
    },

    ///
    /// QUERY MUTATIONS GRAPHQL
    ///

    // Create Company
    async createCompany({ commit, dispatch }, company) {
      console.log(company);
      console.log("mutations.createCompany");
      try {
        const response = await API.graphql({
          query: mutations.createCompany,
          variables: {
            input: {
              idItem: uuidv4(),
              sName: company.sName,
              sEmail: company.sEmail,
              xReceiveSystemFaultEmail: company.xReceiveSystemFaultEmail,
              idPrimaryAdmin: company.idPrimaryAdmin,
              idOwnedVisionAIDevices: company.idOwnedVisionAIDevices,
            }
          }
        });
        if (response.data?.createCompany) {
          commit('addCompany', response.data.createCompany);
          dispatch('showNotification', { type: 'success', message: 'Company created successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error creating company: ${error.message}` });
        console.log(error);
        throw error;
      }
    },
    // Update Company
    async updateCompany({ commit, dispatch }, company) {
      try {
        const response = await API.graphql({
          query: mutations.updateCompany,
          variables: {
            input: {
              idItem: company.idItem,
              sName: company.sName,
              sEmail: company.sEmail,
              xReceiveSystemFaultEmail: company.xReceiveSystemFaultEmail,
              idPrimaryAdmin: company.idPrimaryAdmin,
              idOwnedVisionAIDevices: company.idOwnedVisionAIDevices,
            }
          }
        });
        if (response.data?.updateCompany) {
          commit('updateCompany', response.data.updateCompany);
          dispatch('showNotification', { type: 'success', message: 'Company updated successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error updating company: ${error.message}` });
        console.log(error);
        throw error;
      }
    },
    // Delete Company
    async deleteCompany({ commit, dispatch }, companyId) {
      try {
        const response = await API.graphql({
          query: mutations.deleteCompany,
          variables: { input: { idItem: companyId } }
        });
        if (response.data?.deleteCompany) {
          commit('deleteCompany', companyId);
          dispatch('showNotification', { type: 'success', message: 'Company deleted successfully!' });
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error deleting company: ${error.message}` });
        console.log(error);
        throw error;
      }
    },

    ///
    /// REST API
    ///
    // Create/Delete Company
    async createCompanyRest({ commit, dispatch }, company) {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';

        const path = '/createCompany';
        const options = {
          body: {
            sEmail: company.sEmail,
            sCompanyName: company.sName
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        console.log('query response: ', result);

        // commit('addCompany', result);
        dispatch('showNotification', { type: 'success', message: 'Company created successfully!' });
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error creating company: ${error.message}` });
        throw error;
      }
    },
    async deleteCompanyRest({ commit, dispatch }, companyId) {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';
        const path = '/deleteCompany';
        const options = {
          body: { sCompanyPartitionID: companyId },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        console.log('query response: ', result);

        commit('removeCompany', companyId);
        dispatch('showNotification', { type: 'success', message: 'Company deleted successfully!' });
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error deleting company: ${error.message}` });
        throw error;
      }
    },

    ///
    /// REST API
    ///
    // Create User
    async createUser({ commit, dispatch }, user) {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';
        const path = '/createUser';
        const options = {
          body: {
            sCompanyPartitionID: user.sCompanyPartitionID,
            sFirstName: user.sFirstName,
            sLastName: user.sLastName,
            sEmail: user.sEmail,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        console.log('query response: ', result);
        dispatch('showNotification', { type: 'success', message: 'User created successfully!' });

        return result;
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error creating user: ${error.message}` });
        throw error;
      }
    },
    async fetchUsers() {
      try {
        const response = await API.graphql({ query: queries.listUsers });
        if (response.data?.listUsers?.items) {
          commit('setUsers', response.data.listUsers.items);
        }
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error fetching users: ${error.message}` });
      }
    },
    async deleteUser({ commit, dispatch }, userId) {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';
        const path = '/deleteUser';
        const options = {
          body: { idCognitoSub: userId },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        console.log('query response: ', result);

        // commit('removeUser', userId);
        dispatch('showNotification', { type: 'success', message: 'User deleted successfully!' });

        return result;
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: `Error deleting user: ${error.message}` });
        throw error;
      }
    },
    // Add Remove User to Group
    async addUserToGroup({ commit, dispatch }, { userUUID, groupName }) {
      try {
        console.log('addUserToGroup', userUUID, groupName);
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';
        const path = '/addUserToGroup';
        const options = {
          body: {
            username: userUUID,
            groupname: groupName
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        dispatch('showNotification', { type: 'success', message: 'Successfully added ' + userUUID + ' to ' + groupName + ' :' + JSON.stringify(result) });
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: 'Error adding ' + userUUID + ' to ' + groupName + ' :', error });
      }
    },
    async removeUserFromGroup({ commit, dispatch }, { userUUID, groupName }) {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const apiName = 'AdminQueries';
        const path = '/removeUserFromGroup';
        const options = {
          body: {
            username: userUUID,
            groupname: groupName
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        };
        const result = await API.post(apiName, path, options);
        dispatch('showNotification', { type: 'success', message: 'Successfully added ' + userUUID + ' to ' + groupName + ' :' + JSON.stringify(result) });
      } catch (error) {
        dispatch('showNotification', { type: 'error', message: 'Error adding ' + userUUID + ' to ' + groupName + ' :', error });
      }
    }
  },
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage,
  // })],
});

export default store;
