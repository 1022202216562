<template>
  <GenericDataTable :headers="headers" :query="query" :index="'listMachines'" :exportDataType="'Machines'"
    :expandedRowData="expandedRowData" :title="title" :startTimestamp="graphState[0]" :endTimestamp="graphState[1]" />
</template>

<script>
import GenericDataTable from '@/components/dataTables/GenericDataTable.vue';
import * as queries from "@/graphql/queries";
import { mapState } from "vuex";

export default {
  name: 'MachineDataTable',
  components: {
    GenericDataTable
  },
  props: {
    title: {
      type: String,
      default: 'Machine'
    },
    displayHeading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      graphState(state) {
        return [state.graph.start, state.graph.end];
      }
    }),
    headers() { 
      return [
      { align: 'start', key: 'sFleetNumber', title: 'Fleet Number', sortable: true },
      { key: 'sMake',  title: 'Make', sortable: true },
        { key: 'sModel', title: 'Model', sortable: true },
      ];
    },
    query() {
      return queries.listMachines;
    },
    expandedRowData() {
      return {
        'sFleetNumber': 'Fleet Number',
        'sMake': 'Make',
        'sModel': 'Model',
        'machineStVisionAIIdItem': 'Vision AI Serial Number',
        // 'stPosition': 'Last Known Location',
        'sSerialNumber': 'Machine Serial Number'
      };
    }
  }
};
</script>