<template>
  <v-layout class="rounded rounded-md w-full h-full">
    <NavigationComponent :breadcrumbs="breadcrumbs" />
    <v-main class="d-flex align-center justify-center overflow-y-auto bg-sv-background" style="min-height: 300px;">
      <div class="flex flex-col h-full w-full mt-10">

        <v-container fluid fill-height class="mb-4 flex-grow -mt-20" :max-width="!isMobile() ? '95%' : undefined" >
          <v-alert v-if="notification.isVisible" :type="notification.type" dismissible
            style="position: absolute; top: 20px; z-index:10">
            {{ notification.message }}
          </v-alert>
          <router-view style="min-height: 100vh;"/>
        </v-container>
        <Footer></Footer>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import NavigationComponent from "@/components/NavigationComponent.vue";
import 'bootstrap-icons/font/bootstrap-icons.css';
import router from '@/helpers/router';
import Footer from '@/layouts/Footer.vue';
import { mapState } from 'vuex';

export default {
  components: {
    NavigationComponent,
    Footer
  },
  name: 'App',
  data() {
    return {
      nextToken: "",
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapState(["notification"]),
  },
  computed: {
    ...mapState(["notification"]),
  },
  mounted() {
    window.addEventListener('resize', this.updateDeviceType);
    this.updateDeviceType();

    // Watch for route changes to update breadcrumbs reactively
    this.updateBreadcrumbs();
    router.afterEach(() => {
      this.updateBreadcrumbs();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDeviceType);
  },
  methods: {
    updateDeviceType() {
      this.$store.dispatch('detectDevice');
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    getBreadcrumbs() {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        };
      });
    },
    updateBreadcrumbs() {

      this.breadcrumbs = this.getBreadcrumbs();
    }
  }
};
</script>