<template>
    <v-card class="h-full overflow-visible">
        <v-card-title class=" -mb-5">
            <v-row class="mx-5 mt-3">
                <div class="text-l text-sv-text"> {{ title }} </div>
                <div v-if="graph.graphDataDateRangeTitle"
                    class="text-sm tracking-widest text-sv-text uppercase ml-4 mt-1">
                    {{ graph.graphDataDateRangeTitle }}
                </div>
                <v-spacer> </v-spacer>
                <div class="flex flex-row">
                    <div class="search-container">
                        <v-text-field v-model.trim="search" dense filled rounded clearable variant="outlined"
                            placeholder="Search" prepend-inner-icon="fas fa-magnifying-glass"
                            class="shrink expanding-search" :class="{ closed: searchBoxClosed && !search }"
                            @keyup.enter="searchItems" @focus="searchBoxClosed = false" @blur="searchBoxClosed = true"
                            density="compact" @click:clear="clearSearch">
                        </v-text-field>
                    </div>
                    <v-btn :disabled="loading" text="Refresh" color="#797979" variant="outlined" size="small"
                        @click="refresh" icon="fa fa-refresh" class="secondary mx-2">
                    </v-btn>
                    <RequestDataModule :detections="detections" class="secondary ml-1 mr-2" style="margin-top: 1px;" />
                    <ExportModule @clicked="onLoadDataRequest" :isIcon=true
                        :importedData="(selected.length === 0) ? filteredItems : selected" :dataType="exportDataType"
                        :isLoading="loading" :exportData="exportData" />

                    <slot name="extraButtons"></slot>
                </div>
            </v-row>
        </v-card-title>



        <v-card-text>
            <!-- Data Table -->
            <v-data-table v-model:expanded="expanded" :items="items" :loading="loading" :items-per-page="itemsPerPage"
                :headers="trimmedHeaders" :server-items-length="totalItems" item-value="idItem" show-expand
                @update:items-per-page="onItemsPerPageChange" return-object v-model="selected" hover>
                <!-- :show-select="!isMobile()" -->


                <template v-slot:item.iTimestamp="{ item }">
                    <font-awesome-icon class="mr-2" icon="clock" />
                    {{ formatToHumanReadable(item.sTimeZone, item.sAdjustedTimestamp) }} {{ item.sTimeZone }}
                </template>

                <template v-slot:item.iSystemStartup="{ item }">
                    <font-awesome-icon class="mr-2" icon="clock" />
                    {{ formatToHumanReadable(item.sTimeZone, item.iSystemStartup) }} {{ item.sTimeZone }}
                </template>

                <template v-slot:item.stDeviceStatus="{ item }">
                    <font-awesome-icon v-if="isOverallStatusOK(item.stDeviceStatus)" class="mr-2 text-success text-xl"
                        icon="check" />
                    <font-awesome-icon v-else class="mr-2 text-error text-xl" icon="times" />
                </template>

                <template v-slot:item.stPrestartItem="{ item }">
                    <font-awesome-icon v-if="isOverallStatusOK(item.stPrestartItem)" class="mr-2 text-success text-xl"
                        icon="check" />
                    <font-awesome-icon v-else class="mr-2 text-error text-xl" icon="times" />
                </template>

                <template v-slot:item.iTotalRuntime="{ item }">
                    <font-awesome-icon class="mr-2" icon="clock-rotate-left" />
                    {{ convertSecondsToHMS(item.iTotalRuntime) }}
                </template>

                <template v-slot:item.sAlarmSeverity="{ item }">
                    <font-awesome-icon class="mr-2" icon="exclamation-triangle" />
                    {{ item.sAlarmSeverity }}
                </template>

                <template v-slot:item.iDuration="{ item }">
                    <font-awesome-icon class="mr-2" icon="clock-rotate-left" />
                    {{ convertSecondsToHMS(item.iDuration) }}
                </template>

                <template v-slot:item.stVPU="{ item }">
                    <div v-for="vpuDetails in item.stVPU">
                        <td>
                            {{ vpuDetails.sSerialNumber }}: {{ vpuDetails.sCrc }} ({{ vpuDetails.sType }})
                        </td>
                    </div>
                </template>

                <template v-slot:expanded-row="{ columns, item }">
                    <tr class="table-dark">
                        <td :colspan="columns.length">
                            <v-table class="table-dark">
                                <tbody>
                                    <!-- Expanded Row Data -->
                                    <tr v-for="(value, key) in expandedRowData" :key="key">
                                        <td>
                                            <div class="text-sm tracking-widest text-sv-text uppercase">
                                                {{ value }}:
                                            </div>
                                        </td>
                                        <td v-if="key === 'iTimestamp'">
                                            {{ formatToHumanReadable(item.sTimeZone, item[key]) }} {{ item.sTimeZone }}
                                        </td>
                                        <td v-else-if="key === 'stPosition'">
                                            <v-btn
                                                v-if="item.stPosition.fLatitude != 0 && item.stPosition.fLongitude != 0"
                                                class="text-sv-text text-xl" variant="flat"
                                                :href="'https://www.google.com/maps/place/' + item.stPosition.fLatitude + ',' + item.stPosition.fLongitude"
                                                target="_blank">
                                                {{ item.stPosition.fLatitude }}, {{ item.stPosition.fLongitude }}
                                                <font-awesome-icon class="text-sv-text text-l" icon="map-marker-alt" />
                                            </v-btn>

                                            <v-btn v-else class="text-sv-text text-xl" variant="flat" disabled>
                                                No GPS
                                            </v-btn>
                                        </td>

                                        <td v-else-if="key === 'stDetections'">
                                        </td>
                                        <td v-else-if="key === 'stDeviceStatus'">
                                        </td>
                                        <td v-else-if="key === 'stPrestartItem'">
                                        </td>
                                        <td v-else-if="key === 'iSystemStartup'">
                                            {{ formatToHumanReadable(item.sTimeZone, item[key]) }} {{ item.sTimeZone }}
                                        </td>
                                        <td v-else-if="key === 'iSystemShutdown'">
                                            {{ formatToHumanReadable(item.sTimeZone, item[key]) }} {{ item.sTimeZone }}
                                        </td>
                                        <td v-else-if="key === 'iTotalRuntime'">
                                            {{ convertSecondsToHMS(item.iTotalRuntime) }}
                                        </td>
                                        <td v-else-if="key === 'iStartupTimeTaken'">
                                            {{ item[key] }}s
                                        </td>
                                        <td v-else>{{ item[key] }}</td>
                                    </tr>

                                    <!-- stDeviceStatus -->
                                    <tr v-if="item.stDeviceStatus" v-for="(device) in item.stDeviceStatus"
                                        :key="device.sSource">
                                        <td v-if="parseInt(device.iStatusCode) != 0">
                                            <div class="text-sm tracking-widest text-sv-text uppercase">
                                                {{ device.sSource }}:
                                            </div>
                                        </td>
                                        <td v-if="parseInt(device.iStatusCode) != 0">
                                            <font-awesome-icon v-if="parseInt(device.iStatusCode) === 1"
                                                class="mr-2 text-success text-xl" icon="check" />
                                            <font-awesome-icon v-else-if="parseInt(device.iStatusCode) === 0"
                                                class="mr-2 text-grey text-xl" icon="circle-dot" />
                                            <font-awesome-icon v-else class="mr-2 text-error text-xl" icon="times" />
                                        </td>
                                    </tr>

                                    <!-- stPrestartItem -->
                                    <tr v-if="item.stPrestartItem" v-for="(device) in item.stPrestartItem"
                                        :key="device.sSource">
                                        <td v-if="parseInt(device.iStatusCode) != 0">
                                            <div class="text-sm tracking-widest text-sv-text uppercase">
                                                {{ device.sSource }}:
                                            </div>
                                        </td>
                                        <td v-if="parseInt(device.iStatusCode) != 0">
                                            <font-awesome-icon v-if="parseInt(device.iStatusCode) === 1"
                                                class="mr-2 text-success text-xl" icon="check" />
                                            <font-awesome-icon v-else-if="parseInt(device.iStatusCode) === 0"
                                                class="mr-2 text-grey text-xl" icon="circle-dot" />
                                            <font-awesome-icon v-else class="mr-2 text-error text-xl" icon="times" />
                                        </td>
                                    </tr>

                                </tbody>
                            </v-table>

                            <!-- stDetections -->
                            <div v-if="item.stDetections && isAdmin()">
                                <v-col v-for="detection in item.stDetections" :key="detection.iTimestamp">
                                    <v-table class="table-dark">
                                        <tbody>

                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="clock" />
                                                    Timestamp
                                                </th>
                                                <td>{{ formatToHumanReadable(item.sTimeZone, detection.iTimestamp) }} {{
                                                    item.sTimeZone }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="camera" />
                                                    Camera
                                                </th>
                                                <td>{{ detection.iCameraIdentity }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="map-signs" />
                                                    Zone
                                                </th>
                                                <td>{{ detection.sCameraRoiType }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="eye" />
                                                    Display Zone
                                                </th>
                                                <td>{{ detection.sDisplayZoneName }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="exclamation" />
                                                    Alarm Severity
                                                </th>
                                                <td>{{ detection.sAlarmSeverity }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="star-half-alt" />
                                                    Confidence
                                                </th>
                                                <td>{{ detection.iConfidence }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="glasses" />
                                                    Detection Type
                                                </th>
                                                <td>{{ detection.sType }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-sm tracking-widest text-sv-text uppercase">
                                                    <font-awesome-icon class="mr-2" icon="map-marker-alt" />
                                                    GPS Location
                                                </th>
                                                <td>{{ detection.sGpsLocation }}</td>
                                            </tr>
                                        </tbody>

                                    </v-table>
                                </v-col>
                            </div>
                        </td>
                    </tr>
                </template>

                <template v-slot:item.sFleetNumber="{ item }">
                    <v-btn v-if="item.machineStVisionAIIdItem" class="text-primary text-xl" variant="flat"
                        target="_blank">
                        <router-link :to="{
                            name: 'Machine Detail',
                            params: { id: item.machineStVisionAIIdItem },
                        }" @mouseenter="hover = true" @mouseleave="hover = false">
                            <!-- fleet name -->
                            {{ item.sFleetNumber || "Fleet Number" }}
                            <font-awesome-icon class="text-primary text-xl" icon="arrow-up-right-from-square" />
                        </router-link>
                    </v-btn>
                    <div v-else>
                        <!-- <font-awesome-icon class="mr-2" icon="truck" /> -->
                        {{ item.sFleetNumber }}
                    </div>
                </template>

                <!-- Loading Skeleton -->
                <template v-slot:loading>
                    <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
                </template>

                <!-- Custom Pagination -->
                <template v-slot:bottom>
                    <v-row class="align-center justify-center my-3 mt-16">
                        <v-pagination v-model="page" :length="totalPages()" @update:modelValue="onPageChange"
                            class="w-full mb-7" style="position: absolute; bottom: 0; max-width: 60%"
                            :next-icon="displayEllipsis()"></v-pagination>

                        <v-select v-if="!isMobile()" label="Items Per Page" :items="[5, 10, 15, 20, 100]"
                            v-model="itemsPerPage" density="compact" color="#e4e4e4" variant="outlined"
                            @update:modelValue="onItemsPerPageChange" class="mr-8 mb-4"
                            style="position: absolute; right: 0; bottom: 0; width: 130px;"></v-select>
                    </v-row>
                </template>

                <!-- Allow custom slots for the row actions -->
                <template v-slot:item.action="{ item }">
                    <slot name="item.action" :item="item"></slot>
                </template>

                <template v-slot:item.modify="{ item }">
                    <slot name="item.modify" :item="item"></slot>
                </template>

                <template v-slot:item.delete="{ item }">
                    <slot name="item.delete" :item="item"></slot>
                </template>

                <template v-slot:item.groupAction="{ item }">
                    <slot name="item.groupAction" :item="item"></slot>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<style scoped>
.table-dark {
    background-color: #f6f6f6fc;
}

.table-dark:hover {
    background-color: #f6f6f6fc;
}

/* .search-container {
    width: 200px;
} */
/* Base style for expanding search input */
.expanding-search {
    float: right;
    transition: width 0.5s ease;
    width: 200px;
    /* Expanded width */
}

/* When the search box is closed, set width to a smaller value */
.expanding-search.closed {
    width: 70px;
}

/* Ensure that the inner slot of the input respects the width */
.v-input__slot {
    width: 100%;
}
</style>

<script>
import ExportModule from "@/modules/ExportModule.vue";
import { DateTime } from 'luxon';
import { mapState, mapGetters, mapActions } from "vuex";
import RequestDataModule from "@/modules/RequestDataModule.vue";
import { timezoneAbbrevToLocale } from "@/helpers/timezone"

export default {
    name: "GenericDataTable",
    components: {
        ExportModule,
        RequestDataModule
    },
    props: {
        title: {
            type: String,
            default: 'Data'
        },
        headers: {
            type: Array,
            required: true
        },
        query: {
            type: Function,
            required: true
        },
        index: {
            type: String,
            default: 'Data'
        },
        exportDataType: {
            type: String,
            default: 'Data'
        },
        expandedRowData: {
            type: Object,
            default: () => ({})
        },
        defaultItemsPerPage: {
            type: Number,
            default: 5, // Default value if none provided
        },
        idVisionAI: {
            type: String,
            default: ''
        },
        triggerRefresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            startTimestamp: null,
            endTimestamp: null,
            searchBoxClosed: true,
            search: '',
            loading: false,
            firstQuery: true,
            items: [],
            filteredItems: [],
            allItems: [],
            expanded: [],
            selected: [],
            page: 1,
            itemsPerPage: 5,
            nextToken: null,
            doRefresh: false,
            exportData: false,
        };
    },
    computed: {
        ...mapGetters(['getData', 'getNextToken']),
        ...mapState({
            graph: state => state.graph
        }),
        trimmedHeaders() {
            if (this.isMobile()) {
                return this.headers.slice(0, 2);
            }
            return this.headers;
        },
        graphTimeRange() {
            if (this.$store.state.graph.start && this.$store.state.graph.end) {
                this.startTimestamp = this.$store.state.graph.start;
                this.endTimestamp = this.$store.state.graph.end;
                this.fetchData();
                this.loading = false;
            }
        },
        queryVariables() {
            const nextToken = this.nextToken;

            if (["listMachines", "listCompanies", "listVisionAIS"].includes(this.index)) {
                return {
                    idCompanyPartition: "",
                    ...(nextToken && { nextToken })
                };
            } else if (this.index === "listUsers") {
                return {
                    ...(nextToken && { nextToken })
                };
            } else {
                // Convert timestamps if necessary
                if (this.startTimestamp instanceof DateTime) {
                    this.startTimestamp = this.startTimestamp.toISO();
                    this.endTimestamp = this.endTimestamp.toISO();
                }

                const startTime = this.startTimestamp;
                const endTime = this.endTimestamp;
                return {
                    idCompanyPartition: "",
                    iTimestamp: { between: [startTime, endTime] },
                    sortDirection: "DESC",
                    ...(nextToken && { nextToken })
                };
            }
        },
    },
    methods: {
        ...mapActions(['fetchTableData', 'refreshCachedData']),

        isMobile() {
            return this.$store.state.isMobile;
        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        totalPages() {
            if (this.nextToken) {
                return this.page + 1;
            }
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        async onLoadDataRequest(value) {
            this.exportData = false;
            this.loading = true;

            console.log("onLoadDataRequest");
            await this.refresh();

            console.log("this.nextToken", this.nextToken);

            while (this.nextToken !== null) {
                this.page = this.totalPages() + 1;
                await this.fetchData();
            }
            await this.sleep(200);
            this.loading = false;
            this.exportData = true;

            await this.sleep(200);
            this.exportData = false;
        },
        displayEllipsis() {
            if (this.nextToken && this.page === (this.totalPages() - 1)) {
                return 'fa fa-ellipsis';
            }
            return 'fa fa-chevron-right';
        },
        isAdmin() {
            return this.$store.state.isAdmin;
        },
        isOverallStatusOK(statusList) {
            // Flag to track if any device is not in error
            let allDevicesInError = true;

            // Iterate through each sourceDevice
            for (let sourceDevice of statusList) {
                let statusCode = parseInt(sourceDevice.iStatusCode);

                // If any status code is not 0 or 1, set the flag to false
                if (statusCode !== 0 && statusCode !== 1) {
                    allDevicesInError = false;
                    break; // No need to continue checking, we already found a non-0 or non-1 status code
                }
            }

            // Return the appropriate class based on the flag
            return allDevicesInError;
        },
        formatToHumanReadable(timeZone, isoString) {
            let dateTime = null;
            console.log("isoString", isoString);

            // Parse isoString as seconds or ISO string
            if (typeof isoString === 'number') {
                dateTime = DateTime.fromSeconds(isoString);
            } else {
                dateTime = DateTime.fromISO(isoString, { setZone: true });
            }

            // Convert timezone abbreviation to locale-compatible format
            const localeString = timezoneAbbrevToLocale(timeZone);
            const adjustedDateTime = dateTime.setZone(localeString);

            // Log adjusted values for debugging
            console.log("adjustedDateTime", adjustedDateTime);
            console.log("originalDateTime", dateTime);

            // Format and return the date in a human-readable format
            return adjustedDateTime.toFormat('dd/MM/yyyy, hh:mm a');
        },
        convertSecondsToHMS(seconds) {
            const h = Math.floor(seconds / 3600); // Calculate hours
            const m = Math.floor((seconds % 3600) / 60); // Calculate remaining minutes
            const s = seconds % 60; // Calculate remaining seconds

            if (h === 0 && m === 0) {
                return `${s}s`;
            }

            if (h === 0) {
                return `${m}m ${s}s`;
            }

            return `${h}h ${m}m ${s}s`;
        },
        filterUniqueByTimestamp(dataArray) {
            const uniqueTimestamps = new Set(); // To track unique timestamps
            return dataArray.filter(item => {
                const timestamp = item.iTimestamp.split('#')[0];

                // If the timestamp is not in the set, add it and keep the item
                if (!uniqueTimestamps.has(timestamp) && item.iTotalRuntime !== 0) {
                    uniqueTimestamps.add(timestamp);
                    return true;
                }
                // If the timestamp is already in the set, filter it out
                return false;
            });
        },
        filterUniqueByIdItem(dataArray) {
            const uniqueIds = new Set(); // To track unique idItem values
            return dataArray.filter(item => {
                const idItem = item.idItem;

                // If the idItem is not in the set add it and keep the item
                if (!uniqueIds.has(idItem)) {
                    uniqueIds.add(idItem);
                    return true;
                }
                // If the idItem is already in the set, filter it out
                return false;
            });
        },
        async fetchData() {
            this.loading = true;
            let iRetry = 0;
            const maxRetries = 1000;

            // Determine if data fetching is needed
            while (this.shouldRequestMoreData() && iRetry < maxRetries) {
                this.firstQuery = false;

                const queryResults = await this.fetchItems();
                const fetchedItems = queryResults.data;
                this.nextToken = queryResults.token;

                console.log("fetchedItems", fetchedItems);

                // Update the data state
                this.updateData(fetchedItems);
                iRetry++;
            }

            console.log("this.nextToken", this.nextToken);

            // Update displayed items for the current page
            this.items = this.getItemsForCurrentPage();
            console.log("this.filteredItems", this.filteredItems);
            this.loading = false;
        },

        async fetchItems() {
            // Fetch data from Vuex action (which calls the API)
            const action = this.doRefresh ? 'refreshCachedData' : 'fetchTableData';


            console.log("action", action);
            console.log("this.index", this.index);
            console.log("this.query", this.query);
            console.log("this.queryVariables", this.queryVariables);

            try {
                return await this[action]({
                    index: this.index,
                    query: this.query,
                    queryVariables: this.queryVariables,
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        updateData(fetchedItems) {
            // Combine new items with existing ones
            this.allItems = this.allItems.concat(fetchedItems);

            // Apply filters to the data
            this.filteredItems = this.allItems.filter((item) => {
                return (this.idVisionAI === '' || item.idVisionAI === this.idVisionAI);
            });

            // Apply specific filtering for the "listStartupShutdowns" index
            if (this.index === "listStartupShutdowns") {
                this.filteredItems = this.filterUniqueByTimestamp(this.filteredItems);
            }

            if (this.index === "listMachines") {
                console.log("sorting by fleet number");
                this.filteredItems = this.filterUniqueByIdItem(this.filteredItems);

                this.filteredItems = this.filteredItems.sort((a, b) => {
                    return a.sFleetNumber.localeCompare(b.sFleetNumber);
                });
                console.log("sorted by fleet number");
                console.log(this.filteredItems);
            }

            // Update the total items count
            this.totalItems = this.filteredItems.length;
        },

        shouldRequestMoreData() {
            console.log("this.getNextToken");
            console.log(this.nextToken);
            // Determine if more data needs to be requested
            return (this.totalItems < (this.page * this.itemsPerPage) && this.nextToken) || this.firstQuery;
        },

        getItemsForCurrentPage() {
            // Slice the filtered items to only include those for the current page
            console.log("this.page", this.page);
            console.log("this.totalPages", this.totalPages());
            const start = (this.page - 1) * this.itemsPerPage;
            const end = this.page * this.itemsPerPage;
            console.log("this.totalItems", this.totalItems);
            console.log("start", start);
            console.log("end", end);
            return this.filteredItems.slice(start, end);
        },

        async searchItems() {
            if (this.search === null) {
                this.search = '';
                return;
            }

            if (this.search === '') {
                this.items = this.filteredItems;
                return;
            }

            await this.onLoadDataRequest();

            this.items = this.filteredItems.filter((item) => {
                return Object.values(item).some((value) => {
                    return String(value).toLowerCase().includes(this.search.toLowerCase());
                });
            });
        },
        clearSearch() {
            this.search = '';
            this.searchItems();
        },
        async onPageChange(newPage) {
            this.page = newPage;
            await this.fetchData();
            this.loading = false;
        },
        async refresh() {
            this.firstQuery = true;
            this.nextToken = null;
            this.page = 1;
            this.filteredItems = [];
            this.allItems = [];
            this.items = [];
            this.doRefresh = true;

            console.log("this.filteredItems", this.filteredItems);

            await this.fetchData();
            this.loading = false;
        },
        async onItemsPerPageChange(newItemsPerPage) {
            this.itemsPerPage = newItemsPerPage;
            await this.refresh();
        },
        expandRow(item) {
            const index = this.expanded.indexOf(item);
            if (index > -1) {
                this.expanded.splice(index, 1);
            } else {
                this.expanded.push(item);
            }
        },
        navigateToMachine(item) {

        },
    },
    watch: {
        triggerRefresh(newValue) {
            if (newValue) {
                this.refresh();
            }
        },
        idVisionAI(newValue) {
            this.refresh();
        }
    },
    mounted() {
        this.itemsPerPage = this.defaultItemsPerPage;

        this.startTimestamp = this.$store.state.graph.start;
        this.endTimestamp = this.$store.state.graph.end;

        console.log("refreshing data on first load: " + this.index);
        this.refresh();                               // Call fetchData on either change
        this.loading = false;

        // Watch both `graph.start` and `graph.end` together
        this.$store.watch(
            (state) => [state.graph.start, state.graph.end],  // Watching both properties
            ([newStart, newEnd]) => {                         // Destructure new values
                console.log(this.index, "old start timestamp", this.$store.state.graph.start);
                console.log("old end timestamp", this.$store.state.graph.end);
                this.startTimestamp = newStart;                 // Update startTimestamp
                this.endTimestamp = newEnd;                     // Update endTimestamp
                console.log("refreshing data on graph change: " + this.index);
                console.log("this.startTimestamp", this.startTimestamp);
                console.log("this.endTimestamp", this.endTimestamp);
                this.refresh();                               // Call fetchData on either change
            },
            { deep: true }  // Optional: ensures nested properties are tracked
        );
    },
};
</script>
