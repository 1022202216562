import { createApp } from 'vue'
import App from './App.vue'
import store from './state/index'
import router from './helpers/router'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import JsonCSV from 'vue-json-csv'
import {
  faCaretDown,
  faInfoCircle,
  faCheckSquare,
  faSquare,
  faCheck,
  faTimes,
  faTimesCircle,
  faChevronCircleRight,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faLineChart,
  faMapMarkerAlt,
  faTruck,
  faExclamationTriangle,
  faExclamationCircle,
  faBarChart,
  faSignOutAlt,
  faClock,
  faSort,
  faMousePointer,
  faFilter,
  faFileExport,
  faEdit,
  faTrash,
  faPlus,
  faCalendar,
  faPerson,
  faRefresh,
  faCamera,
  faBuilding,
  faBars,
  faX,
  faMagnifyingGlass,
  faArrowUpRightFromSquare,
  faVideo, // new icons added
  faExclamation, // for Alarm Severity
  faEye, // for Display Zone
  faMapSigns, // for Zone
  faStarHalfAlt, // for Confidence
  faGlasses, // for Detection Type
  faClockRotateLeft,
  faCircleDot,
  faUser,
  faGlobe,
  faEnvelope,  
  faThumbTack,
  faEyeSlash,
  faPhone,
  faPencil,
  faDeleteLeft,
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faEllipsis,
  faCalendarDays,
  faCalendarWeek,
  
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

library.add(
  faCaretDown,
  faInfoCircle,
  faCheckSquare,
  faSquare,
  faCheck,
  faTimes,
  faTimesCircle,
  faChevronCircleRight,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faLineChart,
  faMapMarkerAlt,
  faTruck,
  faExclamationTriangle,
  faExclamationCircle,
  faBarChart,
  faSignOutAlt,
  faClock,
  faSort,
  faMousePointer,
  faFilter,
  faFileExport,
  faEdit,
  faTrash,
  faPlus,
  faCalendar,
  faPerson,
  faRefresh,
  faCamera,
  faBuilding,
  faBars,
  faX,
  faMagnifyingGlass,
  faArrowUpRightFromSquare,
  faVideo,
  faExclamation,
  faEye,
  faMapSigns,
  faStarHalfAlt,
  faGlasses,
  faClockRotateLeft,
  faCircleDot,
  faUser,
  faGlobe,
  faEnvelope,
  faThumbTack,
  faEyeSlash,
  faPhone,
  faPencil,
  faDeleteLeft,
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faEllipsis,
  faCalendarDays,
  faCalendarWeek,
);

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { aliases, fa } from 'vuetify/iconsets/fa'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#00aeef',
          secondary: '#212631',
          accent: '#a5e7ff',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
      },
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  }
})


// DISABLE CONSOLE LOGS
// console.log = function () { };
// console.warn = function () { };
// console.error = function () { };



const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify); // Add Vuetify here
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('DatePicker', Datepicker);
app.component('downloadCsv', JsonCSV);
app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

app.mount('#app');


