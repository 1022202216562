<template>
  <v-navigation-drawer v-model="drawer" app :temporary="isMobile()" :mini-variant="isMobile()">
    <v-col class="d-flex flex-column h-100">
      <!-- logo banner display -->
      <router-link to="/dashboard">
        <img src="@/assets/sentinel_vision_ai_logo_white.png" />
        <!-- <img src="@/assets/prm_engineering_logo.png" /> -->
        <!-- <img src="@/assets/sentinel_vision_ai_logo.png" /> -->
      </router-link>

      <!-- profile link -->
      <div class="bg-sv-primary-tint flex flex-col">
        <!-- profile name and link to profile page -->
        <router-link to="/profile" class=" p-4">
          <div class="flex flex-row">
            <div class="flex flex-col">
              <div class="text-sv-white text-xl">
                {{
                  company !== null
                    ? company.sName
                    : "Unknown Company"
                }}
              </div>
              <div class="text-sv-white text-m">
                {{
                  userDetails !== null
                    ? userDetails.sFirstName +
                    " " +
                    userDetails.sLastName
                    : "Unknown Name"
                }}
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <font-awesome-icon class="text-sv-white text-xl ml-2" icon="chevron-circle-right"></font-awesome-icon>
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="this.isAdmin()" class="mt-2">
        <v-select label="Change Company" :items="companyIDs" v-model="selectedCompany" variant="outlined"
          @update:modelValue="onUpdatedCompany" bg-color="#59d0fd" hide-details></v-select>
      </div>

      <!-- page link area -->
      <v-list class="flex-grow overflow-y-scroll no-scrollbar-override">
        <v-list-item class="SelectedTile" active-class="SelectedTile-active" v-for="(item, index) in menuItems"
          :key="index" :to="item.route" :disabled="item.disabled">
          <v-list-item-content>
            <v-list-item-title class="tracking-widest font-medium uppercase text-3xl text-sv-white">
              <font-awesome-icon class="mr-6" :icon="item.icon" /> {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Machines -->
        <v-list-group class="tracking-widest uppercase text-sv-white" >
          <template v-slot:activator="{ props }">
            <v-list-item class="SelectedTile" v-bind="props" :class="{ 'SelectedTile-active': isActive }">
              <router-link to="/machines">

                <v-list-item-title class="tracking-widest font-medium uppercase text-3xl text-sv-white">
                  <font-awesome-icon class="mr-6" :icon="'fa fa-truck'" /> Machines
                </v-list-item-title>
              </router-link>
            </v-list-item>
          </template>

          <v-list-item class="SelectedTile" active-class="SelectedTile-active" v-for="(item, index) in machines"
            :key="index" :to="{
              name: 'Machine Detail',
              params: { id: item.machineStVisionAIIdItem },
            }">
            <v-list-item-content>
              <v-list-item-title class="tracking-widest font-medium uppercase text-3xl text-sv-white">
                <font-awesome-icon class="mr-6" :icon="'fa-truck'" /> {{ item.sFleetNumber }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Admin -->
        <v-list-item v-if="this.isAdmin()" class="SelectedTile" active-class="SelectedTile-active" :key="1"
          :to="'/admin'">
          <v-list-item-content>
            <v-list-item-title class="tracking-widest font-medium uppercase text-3xl text-sv-white">
              <font-awesome-icon class="mr-6" :icon="'fa fa-building'" /> Admin
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- sign out/footer area -->
      <v-list-item active-class="SelectedTile-active" @click="signOut" link class="SelectedTile mt-auto">
        <v-list-item-content>
          <v-list-item-title class="tracking-widest font-medium uppercase text-3xl text-sv-white">
            <font-awesome-icon class="mr-6" icon="sign-out-alt" /> Sign Out
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-navigation-drawer>


  <!-- Only the button to toggle the drawer -->
  <v-app-bar app :clipped-left="!drawer" collapse :elevation="2" style="visibility: hidden">
    <div style="visibility: visible">
      <v-btn @click="drawer = !drawer" icon class="text-primary ml-2" style="background-color: white; opacity: 1"
        color="white" variant="outlined">
        <font-awesome-icon class="text-primary text-xl" icon="bars" />
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      company: null,
      userDetails: null,
      drawer: true,
      isActive: false,
      machines: [],
      selectedCompany: null,
      menuItems: [
        // { title: 'Profile', icon: 'user', route: '/profile' },
        { title: 'Dashboard', icon: 'bar-chart', route: '/dashboard' },
        // { title: 'Machines', icon: 'truck', route: '/machines' },
        { title: 'Detections', icon: 'exclamation-triangle', route: '/detections' }
      ]
    };
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: []
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies,
      currentUser: (state) => state.userFromQuery,
    }),
    profileBackgroundStyle() {
      return {
        backgroundColor: '#42cafd',
        color: '#221f20',
      };
    },
    companyIDs() {
      return this.companies.map((company) => { return { title: company.sName, value: company.idItem } });
    }
  },
  async mounted() {
    await this.fetchMachines();
    if (this.isAdmin()) {
      await this.fetchCompanies();
    }
    this.drawer = !this.isMobile();

    if (this.$store.state.companyFromQuery && this.$store.state.userFromQuery) {
      console.log("Data already present, retrieving from store");
      this.company = this.$store.state.companyFromQuery;
      this.userDetails = this.$store.state.userFromQuery;
    } else {
      await this.$store.dispatch("getUserFromQuery");
      await this.$store.dispatch("getCompanyFromQuery");
      this.company = this.$store.state.companyFromQuery;
      this.userDetails = this.$store.state.userFromQuery;
      console.log("Retrieved new company data: ");
      console.log(this.$store.state.companyFromQuery);
      console.log("Retrieved new user table data: ");
      console.log(this.$store.state.userFromQuery);
      console.log("Retrieved new user data: ");
      console.log(this.$store.state.user);
    }
  },
  methods: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    async fetchMachines() {
      if (this.$store.state.machines.length <= 0) {
        try {
          await this.$store.dispatch("setAllMachines");
        } catch (error) {
          console.error(error);
        }
      }
      this.machines = this.$store.state.machines.sort((a, b) => a.sFleetNumber.localeCompare(b.sFleetNumber));
    },
    async fetchCompanies() {
      if (this.$store.state.companies.length <= 0) {
        try {
          await this.$store.dispatch("fetchCompanies");
        } catch (error) {
          console.error(error);
        }
      }
    },
    isAdmin() {
      const user = this.$store.state.user;
      if (!user || !user.signInUserSession || !user.signInUserSession.idToken || !user.signInUserSession.idToken.payload['cognito:groups']) {
        return false;
      }
      this.$store.state.isAdmin = user.signInUserSession.idToken.payload['cognito:groups'].includes('PRM');
      return this.$store.state.isAdmin;
    },
    signOut() {
      this.$store.dispatch('logout');
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    onUpdatedCompany() {
      console.log("Selected company: ", this.selectedCompany);
      let updatedUserDetails = this.currentUser;
      updatedUserDetails.idCompanyPartition = this.selectedCompany;

      this.$store.dispatch('updateUser', updatedUserDetails);

      this.signOut();
    }
  },
  watch: {
    $route(to) {
      // Check if the current route matches '/machines' to activate highlighting
      this.isActive = to.path === '/machines';
    },
  },
  created() {
    // Set initial active state on load
    this.isActive = this.$route.path === '/machines';
  },
};
</script>
<style scoped>
.v-list-group-scroll {
  /* or any height you want */
  overflow-y: auto
}

a.active,
a:hover {
  background-color: #ffffff;
}

a.active div,
a.active svg,
a:hover div,
a:hover svg {
  color: rgb(1, 87, 119);
}


.v-navigation-drawer {
  background-color: #221f20;
  /* background-color: #212631; */
}

.v-list-item {
  border-radius: 4px;
}

.ListItemClass {
  color: #f5f5f5;
}

.SelectedTile:hover {
  border-radius: 4px;
  background: #42cafd;
}

.SelectedTile-active {
  border-radius: 4px;
  background: #42cafd;
  color: #ffffff;
  /* rgba(10, 204, 117, 0.19) */
}

.no-scrollbar-override {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scrollbar-override::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
</style>
