<template>
  <v-card class="w-full h-full">
    <!-- Header Section -->
    <v-card-title>
      <v-row class="mx-2 mt-1">
        <v-col>
          <div class="text-l text-sv-text"> {{ title }} </div>
          <div v-if="graph.graphDataDateRangeTitle" class="text-sm tracking-widest text-sv-text uppercase">
            {{ graph.graphDataDateRangeTitle }}
          </div>
        </v-col>
        <v-spacer></v-spacer>

        <v-col class="mt-4 text-right">
          <RequestDataModule :detections="detections" />
        </v-col>

        <v-select v-if="showSelectChart" v-model="selectedChartType" density="compact" color="#e4e4e4"
          variant="outlined" width="1" :items="chartTypes" label="Select Chart Type" @change="updateChart" />
      </v-row>
    </v-card-title>


    <v-card-text class="h-full w-full">
      <!-- Loading state -->
      <v-row v-if="isLoading" class="fill-height d-flex align-center justify-center mb-5">
        <v-col class="text-center">
          <v-progress-circular indeterminate color="primary" size="64" width="4" class="mb-4"></v-progress-circular>
          <v-text class="text-2xl ml-4 text-sv-tertiary">Loading...</v-text>
        </v-col>
      </v-row>
      <div class="text-xl text-sv-tertiary" v-if="!isLoading && processedChartData.length === 0">
        No available data to show in the {{
          graphDataDateRangeTitle
        }}
      </div>
      <!-- Dynamic component based on selected chart type -->
      <component v-else :is="chartComponents[selectedChartType]" class="flex-grow" ref="graph-module"
        id="graph-module" :options="chartOptions" :data="processedChartData" />
    </v-card-text>
    <v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import { Bar, Line, Bubble, Doughnut, Scatter } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { mapState } from "vuex";
import RequestDataModule from "@/modules/RequestDataModule.vue";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: 'GenericGraphModule',
  components: {
    Bar,
    Line,
    Bubble,
    Doughnut,
    Scatter,
    RequestDataModule
  },
  data() {
    return {
      selectedChartType: 'Bar', // Default chart type
      chartTypes: ['Bar', 'Line', 'Bubble', 'Doughnut', 'Scatter'], // Available chart types
      chartComponents: {
        Bar: 'Bar',
        Line: 'Line',
        Bubble: 'Bubble',
        Doughnut: 'Doughnut',
        Scatter: 'Scatter',
      },
      chartOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              // Get the dataset label and the datapoint value
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
              const value = tooltipItem.yLabel || tooltipItem.value;

              // Append " hours" to the value when displaying the tooltip
              return `${datasetLabel}: ${value} hours`;
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
              precision: 0,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: this.showLegend,
          },
        },
      },
    };
  },
  props: {
    updateChartData: {
      type: Function,
      required: true,
    },
    tooltipOverride: {
      type: Function,
      default: (tooltipItem, data) => {
        const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
        const value = tooltipItem.yLabel || tooltipItem.value;

        return `${datasetLabel}: ${value}`;
      },
      required: false,
    },
    processedChartData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    showSelectChart: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      graph: state => state.graph
    }),
  },
  methods: {
    updateChart() {
      // Trigger the chart data update function passed via props
      this.updateChartData();
    },
  },
  watch: {
    selectedChartType: 'updateChart', // Watch for chart type changes and update the chart
    detections: {
      handler: "updateChartData", // Watch for changes in detections and update chart data
      deep: true,
    },
  },
  mounted() {
    // Initial call to update the chart with processed data
    this.updateChart();
  },
};
</script>

<style scoped>
.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}
</style>
