<template>
  <GenericDataTable :headers="headers" :query="query" :index="'listMachines'" :exportDataType="'listMachines'"
    :expandedRowData="expandedRowData" :triggerRefresh="triggerRefresh" :title="'Select A Machine'">

    <template v-slot:extraButtons>
      <v-btn text="Create Machine" color="#797979" variant="outlined" size="small" @click="showCreateModal"
        icon="fa fa-plus" class="secondary mx-2">
      </v-btn>
    </template>

    <!-- Radio Button for Selecting Machine -->
    <template v-slot:item.action="{ item }">
      <v-radio v-model="selectedMachine" :value="item"></v-radio>
    </template>

    <!-- Modify Machine Button -->
    <template v-slot:item.modify="{ item }">
      <v-btn color="green" size="small" icon @click="showUpdateModal(item)">
        <font-awesome-icon icon="edit" />
      </v-btn>
    </template>

    <!-- Delete Button -->
    <template v-slot:item.delete="{ item }">
      <v-btn color="red" size="small" icon @click="showDeleteConfirmation(item)">
        <font-awesome-icon icon="trash" />
      </v-btn>
    </template>
  </GenericDataTable>


  <!-- Create Machine Modal -->
  <v-dialog v-model="createModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Create New Machine</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <v-select label="Select" :items="companyIDs" v-model="selectedCompany"></v-select>
        <v-text-field v-model="stMachine.sFleetNumber" label="Fleet Number"></v-text-field>
        <v-text-field v-model="stMachine.sModel" label="Model"></v-text-field>
        <v-text-field v-model="stMachine.sMake" label="Make"></v-text-field>
        <v-text-field v-model="stMachine.sSerialNumber" label="Machine Serial Number"></v-text-field>
        <v-text-field v-model="stVisionAI.stVPU.sSerialNumber" label="VPU Serial Number"></v-text-field>
        <v-text-field v-model="stVisionAI.stVPU.sCrc" label="CRC / Serial Number"></v-text-field>
        <v-select v-model="stVisionAI.stVPU.sType" :items="['2 Camera', '4 Camera']" label="VPU Type"></v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="confirmCreateMachine">Create</v-btn>
        <v-btn @click="closeCreateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Update Machine Modal -->
  <v-dialog v-model="updateModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Modify Machine</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <!-- ID (if necessary to display) -->
        <v-text-field v-model="selectedMachine.idItem" label="Machine ID" disabled />

        <!-- Fleet Number -->
        <v-text-field v-model="selectedMachine.sFleetNumber" label="Fleet Number" />

        <!-- Model -->
        <v-text-field v-model="selectedMachine.sModel" label="Model" />

        <!-- Make -->
        <v-text-field v-model="selectedMachine.sMake" label="Make" />

        <!-- Machine Serial Number -->
        <v-text-field v-model="selectedMachine.sSerialNumber" label="Machine Serial Number" />

        <v-select label="Select" :items="companyNameMap" v-model="selectedUpdatedCompany"></v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn color="green" @click="confirmUpdateMachine">Update</v-btn>
        <v-btn @click="closeUpdateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- Delete Confirmation Modal -->
  <v-dialog v-model="deleteModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Delete Machine</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        Are you sure you want to delete the machine "{{ selectedMachine.sName }}"? This action cannot be reverted.
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="confirmDeleteMachine">Delete</v-btn>
        <v-btn @click="closeDeleteModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import * as queries from "@/graphql/queries";
import GenericDataTable from "@/components/dataTables/GenericDataTable.vue";
import {
  v4 as uuidv4
} from 'uuid';


export default {
  name: 'AdminVisionAIDataTable',
  components: {
    GenericDataTable,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      stMachine: this.initializeMachine(),
      stVisionAI: this.initializeVisionAI(),
      selectedMachine: null,
      selectedUpdatedCompany: null,
      createModal: false,
      updateModal: false,
      deleteModal: false,
      triggerRefresh: false,
      selectedCompany: null,
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies,
      notification: (state) => state.notification,
    }),
    companyNameMap() {
      return this.companies.map((company) => { return { title: company.sName, value: company.idItem } });
    },
    companyIDs() {
      return this.companies.map((company) => { return { title: company.sName, value: company.idItem } });
    },
    headers() {
      return [
        { align: 'start', key: 'machineStVisionAIIdItem', title: 'Fleet Number', sortable: true },
        { key: 'sMake', title: 'Make', sortable: true },
        { key: 'sModel', title: 'Model', sortable: true },
        // { title: "Select", key: "action", sortable: false },
        { title: "Modify", key: "modify", sortable: false },
        { title: "Delete", key: "delete", sortable: false },
      ];
    }, query() {
      return queries.listMachines;
    },
    expandedRowData() {
      return {
        'sFleetNumber': 'Fleet Number',
        'sMake': 'Make',
        'sModel': 'Model',
        'machineStVisionAIIdItem': 'Vision AI Serial Number',
        'stPosition': 'Last Known Location',
        'sSerialNumber': 'Machine Serial Number'
      };
    }

  },
  methods: {
    ...mapActions(['setAllMachines', 'createMachine', 'deleteMachine', 'updateMachine', 'createVisionAI', 'deleteVisionAI', 'updateVisionAI',]),
    ...mapMutations(['setMachines', 'removeMachine', 'showNotification', 'hideNotification']),
    refresh() {
      this.triggerRefresh = !this.triggerRefresh;
    },
    showCreateModal() {
      this.createModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
      this.refresh();
    },
    initializeMachine() {
      return {
        idCompanyPartition: '',
        idItem: '',
        sFleetNumber: '',
        sModel: '',
        sMake: '',
        stPosition: { iTimestamp: '', fLatitude: '', fLongitude: '' },
        sSerialNumber: '',
        tCreatedAt: '',
        tUpdatedAt: '',
        machineStVisionAIIdCompanyPartition: '',
        machineStVisionAIIdItem: ''
      };
    },
    initializeVisionAI() {
      return {
        idCompanyPartition: '',
        idItem: '',
        stVPU: { sSerialNumber: '', sCrc: '', sType: '' },
        tCreatedAt: '',
        tUpdatedAt: '',
        visionAIStMachineIdCompanyPartition: '',
        visionAIStMachineIdItem: ''
      };
    },
    async confirmCreateMachine() {
      console.log("confirmCreateMachine called");
      console.log(this.selectedCompany);

      if (!this.selectedCompany) {
        console.log({ type: 'error', message: 'Please select a company' });
        return;
      }

      const companyPartition = this.selectedCompany;
      this.assignMachineAndVisionAIDetails(companyPartition);

      try {
        console.log("Creating Machine and VisionAI", this.stMachine, this.stVisionAI);
        await this.createMachine(this.stMachine);
        console.log("Machine created successfully!");
        await this.createVisionAI(this.stVisionAI);
        console.log("VisionAI created successfully!");

        this.closeCreateModal();

        console.log({ type: 'success', message: 'Machine and VisionAI created successfully!' });
        console.log("Machine and VisionAI created successfully!");
      } catch (error) {
        console.log({ type: 'error', message: `Error: ${error.message}` });
      }
    },
    showUpdateModal(machine) {
      this.selectedMachine = { ...machine };
      this.updateModal = true;
    },
    closeUpdateModal() {
      this.updateModal = false;
    },
    async confirmUpdateMachine() {
      try {
        if (!this.selectedUpdatedCompany) {
          console.log({ type: 'error', message: 'Please select a company' });
          return;
        }

        console.log("confirmUpdateMachine called");
        this.selectedMachine.idCompanyPartition = this.selectedUpdatedCompany;
        this.selectedMachine.machineStVisionAIIdCompanyPartition = this.selectedUpdatedCompany;

        console.log(this.selectedMachine);
        await this.updateMachine(this.selectedMachine);
        console.log({
          type: "success",
          message: "Machine updated successfully!",
        });
        this.closeUpdateModal();
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to update machine.",
        });
      }
    },

    showDeleteConfirmation(company) {
      this.selectedMachine = company;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.refresh();
      // this.selectedMachine = null;
    },
    assignMachineAndVisionAIDetails(companyPartition) {
      this.stVisionAI.idCompanyPartition = companyPartition;
      this.stVisionAI.idItem = this.stVisionAI.stVPU.sSerialNumber;
      this.stMachine.idCompanyPartition = companyPartition;
      this.stMachine.idItem = uuidv4();
      this.stMachine.stPosition.iTimestamp = Math.floor(Date.now() / 1000);
      this.stMachine.stPosition.fLatitude = 0.0;
      this.stMachine.stPosition.fLongitude = 0.0;
      this.stMachine.machineStVisionAIIdCompanyPartition = companyPartition;
      this.stMachine.machineStVisionAIIdItem = this.stVisionAI.stVPU.sSerialNumber;
      this.stVisionAI.visionAIStMachineIdCompanyPartition = companyPartition;
      this.stVisionAI.visionAIStMachineIdItem = this.stMachine.idItem;
    },
    async confirmDeleteMachine() {
      try {
        await this.deleteMachine(this.selectedMachine);
        this.removeMachine(this.selectedMachine.idItem);
        this.closeDeleteModal();
        console.log({
          type: "success",
          message: "Machine deleted successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to delete machine.",
        });
      }
    },
  },
  created() {
    // this.fetchMachines(); // Fetch companies when component is created
  },

};
</script>