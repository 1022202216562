<template>
  <router-view class="no-scrollbar-override"  />
</template>
<style scoped>

.no-scrollbar-override {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.no-scrollbar-override::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
</style>
<script>

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

export default {
  name: 'App',
  data() {
    return {
      nextToken: ""
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      }
      return false;
    }
  }
}
</script>
