<template>
        <v-row>
            <v-col v-for="(stat, index) in visibleStats" :key="index" :cols="12 / visibleStats.length">
                <StatItem :title="stat.title" :amount="stat.amount()" :icon="stat.icon" :tooltip="stat.tooltip" />
            </v-col>
        </v-row>
</template>

<script>
import InfoHover from "@/components/InfoHover.vue";
import StatItem from "@/components/StatItem.vue";
import { useDisplay } from 'vuetify'

export default {
    name: "StatsBanner",
    components: {
        InfoHover,
        StatItem
    },
    setup() {
        const { mdAndUp, smAndUp } = useDisplay();

        return {
            mdAndUp,
            smAndUp,
        }
    },
    data() {
        return {
            stats: [
                {
                    title: "Machines Registered",
                    amount: () => this.getAdministeredMachines(),
                    icon: "fa-truck",
                    tooltip: "Total Sentinel Vision AI systems administered by Sentinel Cloud.",
                },
                {
                    title: "Detections Total",
                    amount: () => this.getTotalDetectionCount(),
                    icon: "exclamation-triangle",
                    tooltip: "Total pedestrian detections from all machines in the timerange specified.",
                },
                {
                    title: "Danger Detections",
                    amount: () => this.getWarningCount(),
                    icon: "circle-exclamation",
                    tooltip: "Operator was alerted and could not stop the machine before the pedestrian entered the danger zone, triggering the external alarms.",
                },
                {
                    title: "Warning Detections",
                    amount: () => this.getPrewarningCount(),
                    icon: "exclamation-triangle",
                    tooltip: "Operator was alerted and stopped the machine before the pedestrian could enter the danger zone.",
                },
                {
                    title: "Detections Today",
                    amount: () => this.getTodaysDetectionCount(),
                    icon: "fa-person",
                    tooltip: "Total pedestrian detections from all machines today",
                },
            ],
        };
    },
    computed: {
        visibleStats() {
            if (this.mdAndUp) {
                // Medium and larger screens: show all 5 stats
                return this.stats;
            } else if (this.smAndUp) {
                // Small screens: show 3 stats
                return this.stats.slice(0, 3);
            } else {
                // Extra small screens: show 2 stats
                return this.stats.slice(0, 2);
            }
        },
    },
    methods: {
        isMobile() {
            return this.$store.state.isMobile;
        },
        getAdministeredMachines() {
            return this.$store.state.machines.length;
        },
        getTotalDetectionCount() {
            return this.$store.state.detections.length;
        },
        getTodaysDetectionCount() {
            const today = new Date();
            return this.$store.state.detections.filter((detection) => {
                const detectionDate = new Date(detection.iTimestamp);
                return (
                    detectionDate.getDate() === today.getDate() &&
                    detectionDate.getMonth() === today.getMonth() &&
                    detectionDate.getFullYear() === today.getFullYear()
                );
            }).length;
        },
        getWarningCount() {
            return this.$store.state.detections.filter((detection) => detection.sAlarmSeverity === "Danger").length;
        },
        getPrewarningCount() {
            return this.$store.state.detections.filter((detection) => detection.sAlarmSeverity === "Warning").length;
        }
    }
};
</script>
