import { createWebHistory, createRouter } from 'vue-router';
import store from '@/state/index';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import LoginPage from '@/pages/LoginPage.vue';
import DashboardPage from '@/pages/DashboardPage.vue';
import SitesPage from '@/pages/SitesPage.vue';
import MachinesPage from '@/pages/MachinesPage.vue';
import DetectionsPage from '@/pages/DetectionsPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import ReportsPage from '@/pages/ReportsPage.vue';
import MachineDetailPage from '@/pages/MachineDetailPage.vue';
import AdminPage from '@/pages/AdminPage.vue';
import DefaultLayout from '@/layouts/DefaultLayout';

let user = null;

async function getUser() {
    try {
        const data = await Auth.currentAuthenticatedUser();
        if (data && data.signInUserSession) {
            store.commit('user', data);
            return data;
        }
    } catch (error) {
        store.commit('user', null);
        return null;
    }
} 

// Listen for auth events
Hub.listen("auth", async (data) => {
    if (data.payload.event === 'signOut') {
        user = null;
        store.commit('user', null);
        router.push({ path: '/login' });
    } else if (data.payload.event === 'signIn') {
        user = await getUser();
        router.push({ path: '/dashboard' });
    }
});

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            { path: '/dashboard', name: 'Dashboard', component: DashboardPage, meta: { requiresAuth: true } },
            { path: '/sites', name: 'Sites', component: SitesPage, meta: { requiresAuth: true, disabled: true } },
            { path: '/machines', name: 'Machines', component: MachinesPage, meta: { requiresAuth: true } },
            { path: '/detections', name: 'Detections', component: DetectionsPage, meta: { requiresAuth: true } },
            { path: '/profile', name: 'Profile', component: ProfilePage, meta: { requiresAuth: true } },
            { path: '/reports', name: 'Report Builder', component: ReportsPage, meta: { requiresAuth: true, disabled: true } },
            { path: '/machine-detail', name: 'Machine Detail', component: MachineDetailPage, meta: { requiresAuth: true } },
            { path: '/machine-detail/:id', name: 'Machine Detail', component: MachineDetailPage, meta: { requiresAuth: true }, props: true },
            { path: '/admin', name: 'AdminPage', component: AdminPage, meta: { requiresAuth: true } },
        ]
    },
    { path: '/login', name: 'Login', component: LoginPage },
    { path: '/:catchAll(.*)', redirect: '/dashboard' }  // Catch-all route for non-existent routes
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

// Global navigation guard to handle authentication and route access
router.beforeEach(async (to, from, next) => {
    user = await getUser(); // Ensure user is fetched before checking authentication

    if (!user) {
        if (to.meta.requiresAuth) {
            next({ path: '/login' }); // Redirect to login if the route requires authentication
        } else {
            next(); // Allow navigation for routes that don't require authentication
        }
    } else {
        if (to.path === '/login') {
            next({ path: '/dashboard' }); // Redirect to dashboard if already logged in and trying to access login
        } else {
            next(); // Proceed to the requested route if authenticated
        }
    }
});

// Ensure the correct route is taken on initial load
router.isReady().then(async () => {
    user = await getUser();
    if (user && router.currentRoute.value.path === '/login') {
        router.push({ path: '/dashboard' }); // Redirect to dashboard if already logged in and on login page
    } else if (!user && router.currentRoute.value.meta.requiresAuth) {
        router.push({ path: '/login' }); // Redirect to login if on a protected route without being logged in
    }
});

export default router;
