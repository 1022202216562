<template>
  <v-card elevated class="overflow-y-scroll"
    > <v-list>
      <template v-if="contentType !== 'date-range-picker'">
        <v-container v-if="options.length > 0" class="pa-0" style="position: relative">
          <v-divider inset class="absolute top-n4 right-4"></v-divider>
          <v-list-item v-for="option in options" :key="option.value">
            <OptionEntry ref="optionEntry" @deselect-all="deselectAll" @trigger-dropdown="triggerDropdown"
              @set-selected-value="setSelectedValue" :options="option" />
          </v-list-item>
        </v-container>
        <v-list-item v-if="options.length === 0" key="no-returned-data">
          <OptionEntry ref="optionEntry" @trigger-dropdown="triggerDropdown" :options="null"
            :fallbackTitle="'No ' + contentType + '(s) found'" />
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import OptionEntry from './OptionEntry.vue';

export default {
  components: {
    OptionEntry,
  },
  name: 'OptionDropdown',
  emits: [
    'set-selected-value',
    'trigger-dropdown',
  ],
  props: {
    contentType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    clickFunction: {
      type: Function,
    },
  },
  methods: {
    deselectAll() {
      this.$refs.optionEntry.forEach((item) => {
        item.$data.isSelected = false;
      });
    },
    setSelectedValue(value) {
      this.$emit('set-selected-value', value);
    },
    triggerDropdown() {
      this.$emit('trigger-dropdown');
    },
  },
};
</script>

<style scoped>
.absolute {
  position: absolute;
}

.top-n4 {
  top: -4px;
}
</style>