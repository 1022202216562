<template>
    <GenericGraphModule title="Detections Per Machine" :updateChartData="updateChartData" :processedChartData="processedChartData" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { DateTime } from 'luxon';
import GenericGraphModule from "@/modules/charts/GenericGraphModule.vue";

export default {
  components: {
    Bar,
    GenericGraphModule
  },
  props: {
    detections: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      processedData: {}
    };
  },
  computed: {
    processedChartData() {
      const labels = [];
      const datasets = [];
      let firstlabel = true;


      // Iterate over each fleet's data
      Object.keys(this.processedData).forEach((idVisionAI) => {
        const fleetData = this.processedData[idVisionAI];
        const data = [];

        // Collect labels and values for each fleet
        fleetData.forEach((item) => {
          if (firstlabel) {
            labels.push(item.label);
          }
          data.push(item.value);
        });

        firstlabel = false;

        // Generate random color for the fleet number
        const { randomColor, randomColorTint } = this.getRandomColor();

        // Create a new dataset for this fleet number
        datasets.push({
          label: this.mapVisionaiToMachine(idVisionAI),
          data,
          backgroundColor: randomColor,
          hoverBackgroundColor: randomColorTint,
        });
      });

      return {
        labels,
        datasets,
      };
    },
  },
  watch: {
    detections: {
      handler: "updateChartData",
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    mapVisionaiToMachine(idVisionAI) {
      // Use filter to find machines that match the given idVisionAI
      let machines = this.$store.state.machines.filter((machine) => {
        return machine.machineStVisionAIIdItem === idVisionAI;
      });

      // Log the machines found for debugging
      console.log(machines);

      // Return the fleet number if machines are found, otherwise return null or a default value
      return machines.length > 0 ? machines[0].sFleetNumber : idVisionAI;
    },
    getRandomColor() {
      // Function to convert a hex color to RGB values
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r, g, b };
      };

      // Function to calculate luminance of a color
      const getLuminance = ({ r, g, b }) => {
        // Normalize RGB values to a 0-1 scale
        r = r / 255;
        g = g / 255;
        b = b / 255;

        // Calculate luminance based on relative luminance formula
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
      };

      // Generate a random color until the luminance is over 20%
      let randomColor, intColor, rgb, luminance;
      do {
        intColor = Math.random() * 16777215; // Generate random 24-bit color
        randomColor = Math.floor(intColor).toString(16).padStart(6, '0'); // Convert to hex and pad if necessary
        rgb = hexToRgb(randomColor); // Convert to RGB
        luminance = getLuminance(rgb); // Calculate luminance
      } while (luminance < 0.2); // Keep generating until brightness > 20%

      // Add a tint to the color
      const intColorTint = intColor + 1000;
      const randomColorTint = '#' + Math.floor(intColorTint).toString(16).padStart(6, '0');

      return { randomColor: `#${randomColor}`, randomColorTint };
    },

    prefillDaysInTimePeriod(startDate, endDate) {
      const fleetDataTemplate = [];
      startDate = DateTime.fromISO(startDate);
      endDate = DateTime.fromISO(endDate);
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
        const label = date.toFormat(dateFormat);

        fleetDataTemplate.push({
          value: 0,
          label: label,
        });
      }

      return fleetDataTemplate;
    },
    updateChartData() {
      // Reset the processedData object
      this.processedData = {};

      let startDate = DateTime.fromISO(this.$store.state.graph.start);
      let endDate = DateTime.fromISO(this.$store.state.graph.end);
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      // Prefill data for each fleet number and time period
      this.detections.forEach((detection) => {
        const detectionDate = DateTime.fromISO(detection.iTimestamp).toFormat(dateFormat);

        // Initialize the fleet data if not already present
        if (!this.processedData[detection.idVisionAI]) {
          this.processedData[detection.idVisionAI] = this.prefillDaysInTimePeriod(
            this.$store.state.graph.start,
            this.$store.state.graph.end
          );
        }

        // Find the index of the detection date in the prefilled data
        const existingLabelIndex = this.processedData[detection.idVisionAI].findIndex(
          (item) => item.label === detectionDate
        );

        // Increment the value for the detected date
        if (existingLabelIndex !== -1) {
          this.processedData[detection.idVisionAI][existingLabelIndex].value++;
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add any required styles here */
</style>
