<template>
  <GenericDataTable :headers="headers" :query="query" :index="'listCompanies'" :exportDataType="'listCompanies'"
    :expandedRowData="expandedRowData" :triggerRefresh="triggerRefresh" :title="'Select A Company'">

    <template v-slot:extraButtons>
      <v-btn text="Create Company" color="#797979" variant="outlined" size="small" @click="showCreateModal"
        icon="fa fa-plus" class="secondary mx-2">
      </v-btn>
    </template>

    <!-- Radio Button for Selecting Company -->
    <template v-slot:item.action="{ item }">
      <v-radio v-model="selectedCompany" :value="item"></v-radio>
    </template>

    <!-- Modify Company Button -->
    <template v-slot:item.modify="{ item }">
      <v-btn color="green" size="small" icon @click="showUpdateModal(item)">
        <font-awesome-icon icon="edit" />
      </v-btn>
    </template>

    <!-- Delete Button -->
    <template v-slot:item.delete="{ item }">
      <v-btn color="red" size="small" icon @click="showDeleteConfirmation(item)">
        <font-awesome-icon icon="trash" />
      </v-btn>
    </template>
  </GenericDataTable>


  <!-- Create Company Modal -->
  <v-dialog v-model="createModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Create New Company</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <!-- Company Name -->
        <v-text-field v-model="newCompany.sName" label="Company Name" />

        <!-- Company Email -->
        <v-text-field v-model="newCompany.sEmail" label="Email" />

        <!-- Receive System Fault Email (boolean) -->
        <v-radio-group v-model="newCompany.xReceiveSystemFaultEmail" label="Receive System Fault Email?">
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>

        <!-- Primary Admin -->
        <v-text-field v-model="newCompany.idPrimaryAdmin" label="Primary Admin ID" />

        <!-- Owned Vision AI Devices -->
        <v-text-field v-model="newCompany.idOwnedVisionAIDevices" label="Owned Vision AI Devices (comma-separated)" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="confirmCreateCompany">Create</v-btn>
        <v-btn @click="closeCreateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- Update Company Modal -->
  <v-dialog v-model="updateModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Modify Company</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <!-- ID (if necessary to display) -->
        <v-text-field v-model="selectedCompany.idItem" label="Company ID" disabled />

        <!-- Company Name -->
        <v-text-field v-model="selectedCompany.sName" label="Company Name" />

        <!-- Company Email -->
        <v-text-field v-model="selectedCompany.sEmail" label="Email" />

        <!-- Receive System Fault Email -->
        <v-switch v-model="selectedCompany.xReceiveSystemFaultEmail" label="Receive System Fault Email?" />

        <!-- Primary Admin -->
        <v-text-field v-model="selectedCompany.idPrimaryAdmin" label="Primary Admin ID" />

        <!-- Owned Vision AI Devices -->
        <v-text-field v-model="selectedCompany.idOwnedVisionAIDevices"
          label="Owned Vision AI Devices (comma-separated)" />
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" @click="confirmUpdateCompany">Update</v-btn>
        <v-btn @click="closeUpdateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- Delete Confirmation Modal -->
  <v-dialog v-model="deleteModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Delete Company</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        Are you sure you want to delete the company "{{ selectedCompany.sName }}"? This action cannot be reverted.
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="confirmDeleteCompany">Delete</v-btn>
        <v-btn @click="closeDeleteModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import * as queries from "@/graphql/queries";
import GenericDataTable from "@/components/dataTables/GenericDataTable.vue";


export default {
  name: 'AdminCompanyDataTable',
  components: {
    GenericDataTable,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      selectedCompany: null,
      createModal: false,
      updateModal: false,
      deleteModal: false,
      triggerRefresh: false,
      newCompanyName: "",
      newCompanyEmail: "",
      newCompany: {
        'idItem': '',
        'sName': '',
        'sEmail': '',
        'xReceiveSystemFaultEmail': '',
        'idPrimaryAdmin': '',
        'idOwnedVisionAIDevices': '',
      },
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies,
      notification: (state) => state.notification,
    }),
    headers() {
      return [
        { align: 'start', key: 'idItem', title: 'Company ID', sortable: true },
        { key: 'sName', title: 'Name', sortable: true },
        { key: 'sEmail', title: 'Email', sortable: true },
        // { title: "Select", key: "action", sortable: false },
        { title: "Modify", key: "modify", sortable: false },
        { title: "Delete", key: "delete", sortable: false },

      ];
    },
    query() {
      return queries.listCompanies;
    },
    expandedRowData() {
      return {
        'idItem': 'Company ID',
        'sName': 'Name',
        'sEmail': 'Email',
        'xReceiveSystemFaultEmail': 'Receive System Fault Email',
        'idPrimaryAdmin': 'ID Primary Admin',
        'idOwnedVisionAIDevices': 'Owned Vision AI Devices',
      };
    }
  },
  methods: {
    ...mapActions(['fetchCompanies', 'createCompany', 'deleteCompany', 'updateCompany']),
    ...mapMutations(['setCompanies', 'removeCompany', 'showNotification', 'hideNotification']),
    refresh() {
      this.triggerRefresh = !this.triggerRefresh;
    },
    showCreateModal() {
      this.createModal = true;
    },
    // query() {
    //   return queries.listCompanies;
    // },
    // expandedRowData() {
    //   return {
    //     'idItem': 'Item ID',
    //     'sName': 'Name',
    //     'sEmail': 'Email',
    //     'xReceiveSystemFaultEmail': 'Receive System Fault Email',
    //     'idPrimaryAdmin': 'Primary Admin',
    //     'idOwnedVisionAIDevices': 'Owned Vision AI Devices',
    //     'tCreatedAt': 'Created At',
    //     'tUpdatedAt': 'Updated At',
    //   };
    // },
    closeCreateModal() {
      this.createModal = false;
      this.refresh();
    },
    async confirmCreateCompany() {
      try {
        await this.createCompany(this.newCompany);
        this.triggerRefresh = !this.triggerRefresh;

        this.closeCreateModal();
        console.log({
          type: "success",
          message: "Company created successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to create company.",
        });
      }
    },

    showUpdateModal(company) {
      this.selectedCompany = { ...company };
      this.updateModal = true;
    },
    closeUpdateModal() {
      this.updateModal = false;
      // this.selectedCompany = null;
    },
    async confirmUpdateCompany() {
      try {
        console.log("confirmUpdateCompany called");
        console.log(this.selectedCompany);
        await this.updateCompany(this.selectedCompany);
        console.log("Company updated successfully!");

        this.closeUpdateModal();
        console.log({
          type: "success",
          message: "Company updated successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to update company.",
        });
      }
    },

    showDeleteConfirmation(company) {
      this.selectedCompany = company;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.refresh();
      // this.selectedCompany = null;
    },
    async confirmDeleteCompany() {
      try {
        await this.deleteCompany(this.selectedCompany.idItem);
        this.removeCompany(this.selectedCompany.idItem);
        this.closeDeleteModal();
        console.log({
          type: "success",
          message: "Company deleted successfully!",
        });
      } catch (error) {
        console.log({
          type: "error",
          message: "Failed to delete company.",
        });
      }
    },
  },
  created() {
    this.fetchCompanies(); // Fetch companies when component is created
  },
};
</script>