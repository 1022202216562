<template>
    <v-card class="w-full h-full">
        <!-- Header Section -->
        <v-card-title>
            <div class="flex flex-row justify-between">
                <div class="flex flex-col">
                    <div class="text-l text-sv-text"> {{ title }} </div>
                </div>
                <!-- Toggle Markers Button -->
                <v-btn v-if="$store.state.isMobile" @click="toggleMarkers" color="#797979" size="small"
                    :icon="showMarkers ? 'fa fa-eye-slash' : 'fa fa-map-marker-alt'" variant="outlined"
                    class="secondary mt-2"></v-btn>

                <v-btn v-else @click="toggleMarkers" variant="outlined" class="text-primary mt-2">
                    {{ showMarkers ? 'Hide Markers' : 'Show Markers' }}
                    <font-awesome-icon class="text-primary text-xs ml-2"
                        :icon="showMarkers ? 'fa fa-eye-slash' : 'fa fa-map-marker-alt'" />
                </v-btn>
            </div>
        </v-card-title>


        <v-card-text style="min-height:40vh; max-height:40vh;" class="h-full">
            <!-- Loading state -->
            <v-row v-if="$store.state.isLoading" class="fill-height d-flex align-center justify-center mb-15">
                <v-col class="text-center">
                    <v-progress-circular indeterminate color="primary" size="64" width="4"
                        class="mb-4"></v-progress-circular>
                    <v-text class="text-2xl ml-4 text-sv-tertiary">Loading...</v-text>
                </v-col>
            </v-row>
            <div id="map" class="flex flex-grow fill-height d-flex align-center justify-center mb-15" />
            <!-- style="width: 100%; height: 500px;"></div> -->
        </v-card-text>
    </v-card>
</template>

<style scoped>
.spinner-border {
    border-top-color: transparent;
    border-right-color: transparent;
}
</style>

<script>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
export default {
    name: "Heatmap",
    props: {
        title: {
            type: String,
            default: "",
            required: true,
        },
        heatmapData: {
            type: Array,
            required: true
        },
    },
    setup(props) {
        const map = ref(null);
        const markers = ref([]);
        const showMarkers = ref(true);
        var isInitalized = false;
        const store = useStore(); // Access the Vuex store

        console.log('heatmapData:', props.heatmapData);

        // Computed function to filter heatmap data
        const filteredHeatmapData = computed(() => {
            return props.heatmapData.filter(item => {
                return item.stPosition && item.stPosition.fLatitude != 0 && item.stPosition.fLongitude != 0;
            });
        });

        // Helper function to load Google Maps API
        const loadGoogleMapsApi = ({ key, libraries = [] }) => {
            return new Promise((resolve, reject) => {
                if (window.google && window.google.maps) {
                    resolve(window.google);
                    return;
                }

                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?v=3.35&key=${key}&libraries=${libraries.join(',')}`;
                script.onload = () => {
                    if (window.google && window.google.maps && window.google.maps.visualization) {
                        resolve(window.google);
                    } else {
                        reject(new Error('Visualization library not loaded'));
                    }
                };
                script.onerror = () => reject(new Error('Failed to load Google Maps API'));
                document.head.appendChild(script);
            });
        };

        // Helper function to calculate the center of the map
        const calculateCenter = (data) => {
            if (!data.length) return { lat: 0, lng: 0 };
            const lat = data.reduce((acc, item) => acc + parseFloat(item.stPosition.fLatitude), 0) / data.length;
            const lng = data.reduce((acc, item) => acc + parseFloat(item.stPosition.fLongitude), 0) / data.length;
            return { lat, lng };
        };

        // Function to calculate bounds and center map accordingly
        const calculateBoundsAndCenter = (data) => {
            if (!data.length) return { lat: 0, lng: 0 };

            const bounds = new google.maps.LatLngBounds();

            data.forEach((item) => {
                const position = new google.maps.LatLng(
                    parseFloat(item.stPosition.fLatitude),
                    parseFloat(item.stPosition.fLongitude)
                );
                bounds.extend(position);
            });

            return bounds;
        };
        // Function to add markers to the map
        const addMarkers = (mapInstance, data) => {
            let currentInfoWindow = null; // Keep track of the currently opened InfoWindow

            markers.value = data.map(point => {
                const marker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(point.stPosition.fLatitude),
                        lng: parseFloat(point.stPosition.fLongitude)
                    },
                    map: mapInstance,
                    title: point.sFleetNumber
                });

                // Add InfoWindow with metadata
                const infoWindow = new google.maps.InfoWindow({
                    content: `
            <div class="container">
                <div class="card-body">
                    <h1 class="text-lg text-sv-text"><a href="/machine-detail/${point.idVisionAI}"><strong>${point.sFleetNumber}</strong></a></h1>
                    
                    <p><strong>Timestamp:</strong> ${point.iTimestamp}</p>
                    
                    <h4 class="text-md mt-4"><strong>GPS Position</strong></h4>
                    <p class="card-text"><strong>Latitude:</strong> ${point.stPosition.fLatitude}</p>
                    <p class="card-text"><strong>Longitude:</strong> ${point.stPosition.fLongitude}</p>
                </div>
            </div>
          `
                });

                // Add event listener to open InfoWindow and close others
                marker.addListener('click', () => {
                    // Close the previously opened InfoWindow
                    if (currentInfoWindow) {
                        currentInfoWindow.close();
                    }

                    // Open the clicked marker's InfoWindow
                    infoWindow.open(mapInstance, marker);

                    // Set the current InfoWindow to this one
                    currentInfoWindow = infoWindow;
                });

                return marker;
            });
        };

        // Function to clear markers from the map
        const clearMarkers = () => {
            markers.value.forEach(marker => {
                marker.setMap(null);
            });
            markers.value = [];  // Clear the markers array
        };

        // Toggle Markers on and off reactively
        const toggleMarkers = () => {
            if (isInitalized) {
                if (showMarkers.value) {
                    clearMarkers();
                } else {
                    addMarkers(map.value, filteredHeatmapData.value);
                }
                showMarkers.value = !showMarkers.value;  // Update the button text and state
            }
            generateGraph();  // Re-generate the heatmap
        };

        const generateGraph = async () => {
            try {
                // Wait for Google Maps API to load
                if (!isInitalized) {
                    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
                    const google = await loadGoogleMapsApi({
                        key: apiKey,
                        libraries: ['visualization']
                    });
                    isInitalized = true;
                }
                // if (map.value) {
                // const center = calculateCenter(filteredHeatmapData.value);

                if (filteredHeatmapData.value && filteredHeatmapData.value.length === 0) {
                    map.value = new google.maps.Map(document.getElementById('map'), {
                        mapTypeId: 'satellite',
                        center: { lat: -27.603713, lng: 153.028954 },
                        zoom: 15
                    });
                } else {

                    map.value = new google.maps.Map(document.getElementById('map'), {
                        mapTypeId: 'satellite',
                    });
                    // Fit the map to the bounds of the heatmap data
                    const bounds = calculateBoundsAndCenter(filteredHeatmapData.value);
                    map.value.fitBounds(bounds);

                    const heatmap = new google.maps.visualization.HeatmapLayer({
                        data: filteredHeatmapData.value.map(
                            item => new google.maps.LatLng(parseFloat(item.stPosition.fLatitude), parseFloat(item.stPosition.fLongitude))
                        ),
                        radius: 20,
                        opacity: 1
                    });

                    heatmap.setMap(map.value);
                }

                // Initially add markers if showMarkers is true
                if (showMarkers.value) {
                    addMarkers(map.value, filteredHeatmapData.value);
                }
                // }
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        };



        watch(
            () => store.state.isLoading,
            (newVal, oldVal) => {
                // Only run if isLoading changed from true to false (falling edge trigger)
                if (oldVal && !newVal) {
                    console.log('Loading complete, waiting for map DOM element...');

                    // Wait for the DOM to update and map.value to be present
                    nextTick(async () => {
                        // Set a timeout to hide the notification after 200 ms
                        // In some cases, the heatmap will not load as the map is not available
                        if (map.value) {
                            clearMarkers();
                            addMarkers(map.value, filteredHeatmapData.value);
                            generateGraph();  // Re-generate the heatmap
                        } else {
                            console.error('Map element not found.');
                        }
                    });
                }
            },
            { immediate: true }  // This ensures the watcher runs immediately
        );

        watch(
            () => props.heatmapData,
            () => {
                // Only run if isLoading changed from true to false (falling edge trigger)
                if (!store.state.isLoading) {
                    console.log('Loading complete, waiting for map DOM element...');

                    // Wait for the DOM to update and map.value to be present
                    nextTick(async () => {
                        // Set a timeout to hide the notification after 200 ms
                        // In some cases, the heatmap will not load as the map is not available
                        if (map.value) {
                            clearMarkers();
                            addMarkers(map.value, filteredHeatmapData.value);
                            generateGraph();  // Re-generate the heatmap
                        } else {
                            console.error('Map element not found.');
                        }
                    });
                }
            },
            { immediate: true }  // This ensures the watcher runs immediately
        );

        // Setup function to initialize the map and heatmap
        onMounted(async () => {
            await generateGraph();
        });

        return { toggleMarkers, showMarkers };
    }
};
</script>