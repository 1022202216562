<template>
  <div
    :class="{ 'bg-sv-background-tint px-6': !isInExportModule }"
    class="flex flex-row w-full items-center justify-between py-6"
  >
    <div class="w-full flex flex-col" v-if="!isEditFilterState">
      <div v-if="filterEntries.length <= 0">No Filters Active</div>
      <div v-if="filterEntries.length > 0">
        <div
          v-for="entry in filterEntries[0].filters"
          :key="entry.type"
          class="flex flex-row items-center w-full"
        >
          <div
            class="bg-sv-primary py-2 px-4 tracking-widest flex flex-row items-center relative"
          >
            <div class="font-2xl text-sv-white uppercase font-medium">
              <div v-if="entry.filterType !== 'date-range'">
                {{ entry.filterType + ": " + entry.filterValue }}
              </div>
              <div v-if="entry.filterType === 'date-range'">
                <div v-if="entry.filterValue[1] !== null">
                  {{
                    entry.filterType +
                    ": " +
                    entry.filterValue[0].toLocaleString() +
                    " - " +
                    entry.filterValue[1].toLocaleString()
                  }}
                </div>
                <div v-if="entry.filterValue[1] === null">
                  {{
                    entry.filterType +
                    ": " +
                    entry.filterValue[0].toLocaleString()
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="ml-auto flex flex-row">
            <font-awesome-icon
              @click="editEntry(entry)"
              class="text-sv-white h-5 ml-10 -mt-1 cursor-pointer"
              icon="edit"
            />
            <font-awesome-icon
              @click="removeEntry(entry)"
              class="text-sv-white h-5 ml-10 -mt-1 cursor-pointer"
              icon="trash"
            />
          </div>
        </div>
      </div>
      <Button
        @click-event="enableEditFilterState"
        :hasIcon="false"
        color="primary"
        title="Add Filter"
      />
    </div>
    <div class="flex flex-col w-full" v-if="isEditFilterState">
      <div class="flex flex-row w-full">
        <div
          :class="{ 'flex-wrap': isInExportModule }"
          class="w-full py-2 px-4 flex-row flex-wrap sm:flex-nowrap flex bg-sv-white border-sv-input border-2"
        >
          <FilterOptionChip
            v-for="filterOption in filterOptions"
            :key="filterOption.value"
            :title="
              Object.keys(currentEditedFilter.filters).length > 0 &&
              currentEditedFilter.filters[getCurrentFilterIndex()]
                .filterType === filterOption.value
                ? currentEditedFilter.filters[
                    getCurrentFilterIndex()
                  ].filterValue.toString()
                : 'Select ' + filterOption.name + '(s)'
            "
            :type="filterOption.value"
            @filter-option-set="updateAppliedFilters"
          />
        </div>
      </div>
      <div
        class="flex flex-row w-full ml-auto justify-end mt-2"
        v-if="canApplyFilter && !isEditingExistingFilter"
      >
        <div v-if="!isInExportModule">
          <Button
            @click-event="sendActiveFilterToFilterModule"
            :hasIcon="false"
            color="primary"
            title="Apply Filter"
            position="right"
          />
        </div>
        <div v-if="isInExportModule">
          <div class="flex flex-row justify-end">
            <Button
              @click-event="cancelEdit"
              :hasIcon="true"
              iconName="times"
              color="secondary"
            />
            <Button
              class="ml-12"
              @click-event="setActiveFilterToExportModule"
              :hasIcon="true"
              iconName="check"
              color="primary"
            />
          </div>
        </div>
      </div>
      <div v-if="isEditingExistingFilter">
        <div v-if="!isInExportModule">
          <Button
            @click-event="setUpdatedActiveFilterToFilterModule"
            :hasIcon="false"
            color="primary"
            title="Apply Edited Filter"
            position="right"
          />
        </div>
        <div v-if="isInExportModule">
          <Button
            @click-event="cancelEdit"
            :hasIcon="true"
            iconName="times"
            color="secondary"
          />
          <Button
            @click-event="setUpdatedActiveFilterToExportModule"
            :hasIcon="true"
            iconName="check"
            color="primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import Button from "../components/Button.vue";
import FilterOptionChip from "../components/FilterOptionChip.vue";
export default {
  name: "FilterBuilderModule",
  components: {
    Button,
    FilterOptionChip,
  },
  emits: ["add-new-filter-to-export", "update-existing-filter-to-export"],
  mounted() {
    if (this.setInitialStateToEdit) {
      this.enableEditFilterState();
    }
    this.filterEntries =
      this.filterReference !== undefined
        ? this.filterReference.activeFilters
        : this.exportFilterEntries !== undefined
        ? this.exportFilterEntries
        : [];
  },
  data() {
    return {
      filterEntries: [],
      isEditFilterState: false,
      isEditingExistingFilter: false,
      currentEditedFilter: {},
      canApplyFilter: false,
    };
  },
  props: {
    filterReference: ref(),
    activeFilters: {
      type: Array,
    },
    filterOptions: {
      type: Array,
    },
    setInitialStateToEdit: {
      type: Boolean,
      default: false,
    },
    isInExportModule: {
      type: Boolean,
      default: false,
    },
    exportFilterEntries: {
      type: Array,
    },
  },
  methods: {
    enableEditFilterState() {
      this.isEditFilterState = true;
      this.createFilterToEdit();
    },
    getCurrentFilterIndex() {
      for (var i = 0; i < this.currentEditedFilter.filters.length; i++) {
        return i;
      }
      return 0;
    },
    createFilterToEdit() {
      this.currentEditedFilter = {
        filters: {},
      };
    },
    updateAppliedFilters(filterToAdd) {
      // if entry/key is not present in filters, we add it to the filters sub object
      var isFilterTypePresentInCurrentFilters = false;
      var indexToReplace = 0;
      for (
        var i = 0;
        i < Object.keys(this.currentEditedFilter.filters).length;
        i++
      ) {
        isFilterTypePresentInCurrentFilters =
          "filterType" in this.currentEditedFilter.filters[i];
        indexToReplace = i;
      }

      if (!isFilterTypePresentInCurrentFilters) {
        let filterCount = Object.keys(this.currentEditedFilter.filters).length;
        filterToAdd.index = filterCount;
        this.currentEditedFilter.filters[filterCount] = filterToAdd;
      }

      // if it IS present, we need to update its value
      if (isFilterTypePresentInCurrentFilters) {
        this.currentEditedFilter.filters[indexToReplace].filterValue =
          filterToAdd.filterValue;
      }

      // check to see if we have AT LEAST one filter set
      // if we do, we can enable the apply Filter button
      if (Object.keys(this.currentEditedFilter.filters).length > 0) {
        this.canApplyFilter = true;
      }
    },
    setUpdatedActiveFilterToFilterModule() {
      this.filterReference.updateActiveFilter(this.currentEditedFilter);
      this.currentEditedFilter = {};
      this.isEditFilterState = false;
      this.isEditingExistingFilter = false;
    },
    sendActiveFilterToFilterModule() {
      // we can finalize the current selected filter to edit
      // send to filter module to be set as an active filter
      this.filterReference.addFilterToActiveFilters(this.currentEditedFilter);
      // nullify the current selected editable filter
      this.currentEditedFilter = {};
      // set edit state to false
      this.isEditFilterState = false;
    },
    editEntry(filterEntry) {
      this.filterReference.editSelectedEntryFromActiveFilters(filterEntry);

      // Beyond MVP, we need to set that index as dynamic rather than the first element in the active filters object
      this.currentEditedFilter = this.filterEntries[0];
      this.isEditFilterState = true;
      this.isEditingExistingFilter = true;
    },
    removeEntry(filterEntry) {
      this.filterReference.removeFilterFromActiveFilters(filterEntry);
      this.filterEntries = this.filterReference.activeFilters;
    },
    cancelEdit() {
      //  what we want to do here is reset all filter adjustments to normal and set the edit state to false
    },
    setActiveFilterToExportModule() {
      // emit an event with data that passes the accepted NEW filter to the list of active filters on the export module
      this.$emit("add-new-filter-to-export", this.currentEditedFilter);
      this.currentEditedFilter = {};
      // set edit state to false
      this.isEditFilterState = false;
    },
    setUpdatedActiveFilterToExportModule() {
      // emit an event with the active filter edits and overwrite the original filter with the new one from th eexport module
      this.$emit("update-existing-filter-to-export", this.currentEditedFilter);
      this.currentEditedFilter = {};
      this.isEditFilterState = false;
      this.isEditingExistingFilter = false;
    },
  },
};
</script>
