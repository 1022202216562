<template>
  <v-card class="w-full h-full">

    <!-- Header Section -->
    <v-card-title>
      <div class="flex flex-row justify-between">
        <v-col class="d-flex flex-column">
          <div class="text-l text-sv-text"> {{ title }} </div>
          <div v-if="graph.graphDataDateRangeTitle" class="text-sm tracking-widest text-sv-text uppercase">
            {{ graph.graphDataDateRangeTitle }}
          </div>
        </v-col> <v-spacer> </v-spacer>
        <div class="flex flex-row mt-2">
          <RequestDataModule :detections="detections" class="mr-2 mt-2" />
        </div>
      </div>
    </v-card-title>

    <v-card-text style="max-height:40vh;" class="h-full">
      <Bar class="flex flex-grow" ref="graph-module" id="graph-module" :options="chartOptions"
        :data="processedChartData"> </Bar>
    </v-card-text>
  </v-card>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { DateTime } from "luxon";
import { mapState } from "vuex";
import RequestDataModule from "@/modules/RequestDataModule.vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = ["League Spartan", "sans-serif"];
ChartJS.defaults.color = "#000000";
ChartJS.defaults.borderColor = "#000000";

export default {
  name: "DetectionsBarChart",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    detections: {
      type: Array,
      default: [],
      required: true,
    },
  },
  components: {
    Bar,
    RequestDataModule
  },
  data() {
    return {
      processedData: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
              precision: 0,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      graph: state => state.graph
    }),
    processedChartData() {
      const labels = [];
      const data = [];

      this.processedData.forEach((item) => {
        labels.push(item.label);
        data.push(item.value);
      });

      return {
        labels,
        datasets: [
          {
            label: "Detections",
            data,
            backgroundColor: "#00aeef",
            hoverBackgroundColor: "#5fd4ff",
          },
        ],
      };
    },
  },
  watch: {
    detections: {
      handler: "updateChartData",
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    prefillDaysInTimePeriod(startDate, endDate) {
      const processedData = [];

      startDate = DateTime.fromISO(startDate);
      endDate = DateTime.fromISO(endDate);
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      console.log("startDate:", startDate);
      console.log("endDate:", endDate);

      for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
        const label = date.toFormat(dateFormat);

        processedData.push({
          value: 0,
          label: label,
        });
      }

      return processedData;
    },
    updateChartData() {
      // Prefill data for each day in the selected time period
      this.processedData = this.prefillDaysInTimePeriod(this.$store.state.graph.start, this.$store.state.graph.end);

      let startDate = DateTime.fromISO(this.$store.state.graph.start);
      let endDate = DateTime.fromISO(this.$store.state.graph.end);
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      // Extract plain data from the reactive detections array
      const plainDetections = this.detections.map((det) => ({
        iTimestamp: det.iTimestamp,
      }));

      // Update the prefilled data with actual detection values
      plainDetections.forEach((detection) => {
        const detectionDate = DateTime.fromISO(detection.iTimestamp).toFormat(dateFormat);

        const existingLabelIndex = this.processedData.findIndex(
          (item) => item.label === detectionDate
        );

        if (existingLabelIndex !== -1) {
          this.processedData[existingLabelIndex].value++;
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
