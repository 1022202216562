<template>
    <GenericDataTable :headers="headers" :query="query" :index="'listSystemStatuses'" :exportDataType="'Startup Logs'"
        :expandedRowData="expandedRowData" :title="title" :startTimestamp="graphState[0]" :idVisionAI="idVisionAI"
        :endTimestamp="graphState[1]" />
</template>

<script>
import GenericDataTable from '@/components/dataTables/GenericDataTable.vue';
import * as queries from "@/graphql/queries";
import { mapState } from "vuex";

export default {
    name: 'SystemStatusDataTable',
    components: {
        GenericDataTable
    },
    props: {
        title: {
            type: String,
            default: 'System Status Logs'
        },
        displayHeading: {
            type: Boolean,
            default: true
        },
        idVisionAI: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapState({
            graphState(state) {
                return [state.graph.start, state.graph.end];
            }
        }),
        headers() {
            if (this.idVisionAI !== '') {
                return [
                    { align: 'start', key: 'iTimestamp', title: 'Timestamp', sortable: true },
                    { key: 'stDeviceStatus', title: 'Status', sortable: true },
                ];
            }
            return [
                { align: 'start', key: 'iTimestamp', title: 'Timestamp', sortable: true },
                { key: 'sFleetNumber', title: 'Fleet Number', sortable: true },
                { key: 'stDeviceStatus', title: 'Status', sortable: true },
            ];
        },
        query() {
            return queries.listSystemStatuses;
        },
        expandedRowData() {
            return {
                'sFleetNumber': 'Fleet Number',
                'iTimestamp': 'Timestamp',
            };
        }
    }
};
</script>