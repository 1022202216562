<template>
  <div class="flex flex-col">
    <!-- <v-row >
      <v-col cols="12" md="6">
        <SystemFaults :title="'System Faults'" :dataToPassToGraph="$store.state.systemStatuses" />
      </v-col>
      <v-col cols="12" md="6">
        <Runtime :title="'Operational Hours'" :dataToPassToGraph="$store.state.startupShutdowns" />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="12">
        <MachineDataTable class="flex flex-shrink" :defaultItemsPerPage=15 />
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
import NavigationComponent from "@/components/NavigationComponent.vue";
import MachineDataTable from "@/components/dataTables/MachineDataTable.vue";
import Runtime from "@/modules/charts/Runtime.vue";
import SystemFaults from "@/modules/charts/SystemFaults.vue";

export default {
  name: "MachinesPage",
  components: {
    NavigationComponent,
    MachineDataTable,
    SystemFaults,
    Runtime
  },
  data() {
    return {
      machines: [],
      startTime: null,
      endTime: null,
    };
  },
};
</script>