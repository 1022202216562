<template>
    <div class="info-container">
        <div class="text-sv-text text-l sm:text-xl font-thin mt-2">
            {{ title }}
            <!-- Wrap the icon in a span to make it clickable -->
            <span class="info-icon ml-1" @click="toggleInfo" @mouseover="toggleInfo">
                <i class="bi bi-info-circle"></i>
            </span>
        </div>

        <!-- Info overlay (shown when hovered over) -->
        <div class="info-overlay" v-if="showInfo" @click="toggleInfo" @mouseleave="toggleInfo">
            <div class="info-content">
                <!-- Your information goes here -->
                <p class="m-1" ><strong>{{ title }}</strong></p>
                <p>{{ content }}</p>
                <!-- Add descriptions for other statistics similarly -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        }
    },
    methods: {
        toggleInfo() {
            this.showInfo = !this.showInfo; // Toggle the showInfo flag
        },
        handleClickOutside(event) {
            if (this.$refs.infoContainer && !this.$refs.infoContainer.contains(event.target)) {
                this.showInfo = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    data() {
        return {
            showInfo: false // Controls the visibility of the info overlay
        };
    }
};
</script>

<style scoped>
.info-container {
    position: relative;
    display: inline-block;
    /* Ensure inline display to correctly position the overlay */
}

.info-icon {
    color: blue;
    /* Set the default color */
    cursor: pointer;
    /* Change cursor to pointer when hovered */
}

.info-overlay {
    position: absolute;
    top: -120%;
    /* Adjust this value to position the overlay */
    left: 0;
    z-index: 999;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 200px;
    /* Adjust width as needed */
    transition: top 0.3s ease;
    /* Add smooth transition */
}

.info-content {
    color: #333;
}

/* Optional: Style the info icon */
.info-icon i {
    color: blue;
}
</style>