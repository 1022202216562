<template>
    <GenericGraphModule title="Detections Per Time of Day" :updateChartData="updateChartData" :processedChartData="processedChartData" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { DateTime } from 'luxon';
import GenericGraphModule from "@/modules/charts/GenericGraphModule.vue";

export default {
  components: {
    Bar,
    GenericGraphModule
  },
  props: {
    dataToPassToGraph: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      processedData: []
    };
  },
  computed: {
    processedChartData() {
      const labels = [];
      const data = [];

      this.processedData.forEach((item) => {
        labels.push(item.label);
        data.push(item.value);
      });

      return {
        labels,
        datasets: [
          {
            label: "Detections",
            data,
            backgroundColor: "#00aeef",
            hoverBackgroundColor: "#5fd4ff",
          },
        ],
      };
    },
  },
  watch: {
    dataToPassToGraph: {
      handler: "updateChartData",
      deep: true,
    },
  },
  mounted() {
    this.updateChartData();
  },
  methods: {
    prefillDaysInTimePeriod(startDate, endDate) {
      const processedData = [];

      for (let date = startDate; date <= endDate; date = date.plus({ hour: 1 })) {
        const label = date.toFormat("HH")+":00";
        processedData.push({
          value: 0,
          label: label,
        });
      }

      return processedData;
    },
    updateChartData() {
      // Prefill data for each day in the selected time period
      let startDate = DateTime.now().set({ days: 1, hour: 0 });
      let endDate = DateTime.now().set({ days: 2, hour: 0 });
      this.processedData = this.prefillDaysInTimePeriod(startDate, endDate);

      // Extract plain data from the reactive dataToPassToGraph array
      const plainDetections = this.dataToPassToGraph.map((det) => ({
        iTimestamp: det.iTimestamp,
      }));

      // Update the prefilled data with actual detection values
      plainDetections.forEach((detection) => {
        const detectionDate = DateTime.fromISO(detection.iTimestamp).toFormat("HH")+":00";;

        const existingLabelIndex = this.processedData.findIndex(
          (item) => item.label === detectionDate
        );

        if (existingLabelIndex !== -1) {
          this.processedData[existingLabelIndex].value++;
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add any required styles here */
</style>
