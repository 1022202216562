<template>
    <v-row>
      <v-col cols="12" md="7">
        <GraphModule :dataToPassToGraph="detections" />
      </v-col>
      <v-col cols="12" md="5">
        <Heatmap :heatmapData="detections" :title="'Heatmap'" :showMarkers="false"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <MachineDataTable :title="'Machines'" :defaultItemsPerPage=15 />
      </v-col>
      <v-col cols="12" md="6">
          <DetectionDataTable :defaultItemsPerPage=15 />
      </v-col>
    </v-row>
</template>


<script>
import MachineDataTable from "@/components/dataTables/MachineDataTable.vue";
import DetectionDataTable from "@/components/dataTables/DetectionDataTable.vue";
import GraphModule from "@/modules/GraphModule.vue";
import StatsBanner from "@/components/StatsBanner.vue";
import Heatmap from "@/components/maps/Heatmap.vue";

import { mapState } from "vuex";
export default {
name: "DashboardPage",
components: {
  MachineDataTable,
  DetectionDataTable,
  GraphModule,
  Heatmap
},
data() {
  return {
    machines: [],
    loading: true,
  };
},
computed: {
  // Use mapState to map 'detections' from Vuex store state to component's computed property
  ...mapState(["detections"]),
},
methods: {
  isMobile() {
    return this.$store.state.isMobile;
  }
}
};
</script>
