<template>
    <div class="flex flex-row">
        <NavigationComponent />
        <div class="bg-sv-background h-screen flex flex-row w-full px-8 py-5">
            <!-- module area -->
            <div>
            </div>
        </div>
    </div>
</template>
<script>
    import NavigationComponent from '@/components/NavigationComponent.vue';
    export default {
        name: 'SitesPage',
        components: {
            NavigationComponent
        }
    }
</script>