<template>
    <GenericDataTable :headers="headers" :query="query" :index="'listDetectionEvents'" :exportDataType="'Detections'"
        :expandedRowData="expandedRowData" :title="title" :startTimestamp="graphState[0]" :idVisionAI="idVisionAI"
        :endTimestamp="graphState[1]" />
</template>

<script>
import GenericDataTable from '@/components/dataTables/GenericDataTable.vue';
import * as queries from "@/graphql/queries";
import { mapState } from "vuex";

export default {
    name: 'DetectionDataTable',
    components: {
        GenericDataTable
    },
    props: {
        title: {
            type: String,
            default: 'Detections'
        },
        displayHeading: {
            type: Boolean,
            default: true
        },
        idVisionAI: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapState({
            graphState(state) {
                return [state.graph.start, state.graph.end];
            }
        }),
        headers() {
            if (this.idVisionAI !== '') {
                return [
                    { align: 'start', key: 'iTimestamp', title: 'Timestamp', sortable: true },
                    { key: 'iDuration', title: 'Duration', sortable: true },
                    { key: 'sAlarmGroup', title: 'Alarm Group', sortable: true },
                    { key: 'sAlarmSeverity', title: 'Severity', sortable: true },
                ];
            }
            return [
                { align: 'start', key: 'iTimestamp', title: 'Timestamp', sortable: true },
                { key: 'sFleetNumber', title: 'Fleet Number', sortable: true },
                { key: 'iDuration', title: 'Duration', sortable: true },
                { key: 'sAlarmGroup', title: 'Alarm Group', sortable: true },
                { key: 'sAlarmSeverity', title: 'Severity', sortable: true },
            ];
        },
        query() {
            return queries.listDetectionEvents;
        },
        expandedRowData() {
            // only return detections if user is admin
            if (this.isAdmin()) {
                return {
                    'sFleetNumber': 'Fleet Number',
                    'iTimestamp': 'Timestamp',
                    'iDuration': 'Duration',
                    'sAlarmGroup': 'Alarm Group',
                    'sAlarmSeverity': 'Severity',
                    'stPosition': 'GPS Position',
                    'idVisionAI': 'Vision AI Serial',
                };
            }
            return {
                'sFleetNumber': 'Fleet Number',
                'iTimestamp': 'Timestamp',
                'iDuration': 'Duration',
                'sAlarmGroup': 'Alarm Group',
                'sAlarmSeverity': 'Severity',
                'stPosition': 'GPS Position',
            };
        }
    },
    methods: {
        isAdmin() {
            return this.$store.state.isAdmin;
        },
    }
};
</script>