<template>
  <v-card class="h-full w-full">
    <!-- Header Section -->
    <v-card-title>
      <div class="flex flex-row justify-between">
        <div class="flex flex-col">
          <div class="text-l text-sv-text"> {{ graphDataTypeTitle }} </div>
          <div class="text-sm
         tracking-widest text-sv-text uppercase">
            {{ graphDataDateRangeTitle }}
          </div>
        </div>
        <v-spacer> </v-spacer>
        <div v-show="!isMobile()" class="flex flex-row mt-2">

          <v-btn text="Refresh" color="#797979" variant="outlined" size="small" @click="requestData"
            icon="fa fa-refresh" class="secondary mx-2">
          </v-btn>

          <v-btn :disabled="loading" text="Refresh" color="#797979" variant="outlined" size="small"
            @click="triggerSelectOptionDropdown" icon="fa fa-calendar-week" class="secondary">
          </v-btn>
          <!-- Dropdown Menu in Dialog -->
          <v-dialog v-model="isDropdownOpen" max-width="400px" @click:outside="isDropdownOpen = false">
            <OptionDropdown :contentType="'graph-date-type'" :options="graphDataDateRangeOptions"
              @set-selected-value="setDateValue" />
          </v-dialog>

          <!-- Date Picker in Dialog -->
          <v-dialog v-model="isDatePickerOpen" max-width="400px" @click:outside="isDatePickerOpen = false">
            <DatePicker :value="date" @update:modelValue="handleDate" range inline />
          </v-dialog>
        </div>
      </div>
    </v-card-title>

    <v-card-text style="max-height:40vh;" class="h-full">
      <!-- Loading state -->
      <v-row v-if="isLoading" class="fill-height d-flex align-center justify-center mb-5">
        <v-col class="text-center">
          <v-progress-circular indeterminate color="primary" size="64" width="4" class="mb-4"></v-progress-circular>
          <v-text class="text-2xl ml-4 text-sv-tertiary">Loading...</v-text>
        </v-col>
      </v-row>
      <div class="text-xl text-sv-tertiary" v-if="!isLoading && processedData.length === 0">
        No available data to show in the {{
          graphDataDateRangeTitle
        }}
      </div>
      <Bar v-else class="flex flex-grow" ref="graph-module" id="graph-module" :options="chartOptions"
        :data="processedChartData"> </Bar>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.spinner-border {
  border-top-color: transparent;
  border-right-color: transparent;
}
</style>


<script>
/* eslint-disable */
import RequestDataModule from "@/modules/RequestDataModule.vue";
import Button from "../components/Button.vue";
import OptionDropdown from "../components/OptionDropdown.vue";
import ExportModule from "../modules/ExportModule.vue";
import { Bar } from "vue-chartjs";
import { mapState } from "vuex";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import moment from "moment";
import { DateTime } from 'luxon';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = ["League Spartan", "sans-serif"];
ChartJS.defaults.color = "#000000";
ChartJS.defaults.borderColor = "#000000";
export default {
  name: "ChartModule",
  components: {
    Button,
    Bar,
    OptionDropdown,
    ExportModule,
    RequestDataModule,
  },
  computed: {
    // Use mapState to map 'detections' from Vuex store state to component's computed property
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    processedChartData() {
      return {
        labels: this.processedData.map((data) => data.label),
        datasets: [
          {
            label: "Detections",
            backgroundColor: "#00aeef",
            hoverBackgroundColor: "#5fd4ff",
            data: this.processedData.map((data) => data.value),
          },
        ],
      };
    },
  },
  async created() {
    await this.requestData();
  },
  data() {
    const handleDate = async (modelData) => {
      this.isDropdownOpen = false;
      this.isDatePickerOpen = false;

      const [startTimestamp, endTimestamp] = this.calculateCustomTimerange(modelData);
      this.graphDataDateRangeTitle = startTimestamp.toFormat('dd/MM/yyyy') + ' - ' + endTimestamp.toFormat('dd/MM/yyyy');
      this.graphDataDateRangeType = "custom-date";

      this.$store.state.graph.graphDataDateRangeTitle = this.graphDataDateRangeTitle;

      await this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);
    };
    return {
      date: new Date(),
      handleDate,
      refreshKey: 0,
      isDropdownOpen: false,
      isDatePickerOpen: false,
      graphDataTypeTitle: "Detections",
      graphDataDateRangeTitle: "Last 7 Days",
      graphDataDateRangeType: "last-week",
      graphDataDateRangeCustom: [],
      processedData: [],
      rawDetectionData: [],
      graphDataDateRangeOptions: [
        {
          name: "Last 24 Hours",
          value: "yesterday",
        },
        {
          name: "Last 7 Days",
          value: "last-week",
        },
        {
          name: "Last 30 Days",
          value: "last-30-days",
        },
        {
          name: "Last 3 Months",
          value: "last-3-months",
        },
        {
          name: "Last 6 Months",
          value: "last-6-months",
        },
        {
          name: "Last 12 Months",
          value: "last-year",
        },
        {
          name: "Custom",
          value: "custom-date",
        },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              stepSize: 1,
              beginAtZero: true,
              precision: 0,
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {
    window.addEventListener('resize', this.refreshChart);
    this.requestData(true);

    // Watch both `graph.start` and `graph.end` together
    // data was updated elsewhere
    this.$store.watch(
      (state) => [state.graph.start, state.graph.end],  // Watching both properties
      async ([newStart, newEnd]) => {                         // Destructure new values
        let startTimestamp = newStart;                 // Update startTimestamp
        let endTimestamp = newEnd;                     // Update endTimestamp
        this.graphDataDateRangeTitle = this.$store.state.graph.graphDataDateRangeTitle;
        this.graphDataDateRangeType = this.$store.state.graph.graphDataDateRangeType;
        await this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);

      },
      { deep: true }  // Optional: ensures nested properties are tracked
    );

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.refreshChart);
  },
  methods: {
    refreshChart() {
      this.$store.state.isLoading = true;
      setTimeout(`console.log("Refresh Graph")`, 100);
      this.$store.state.isLoading = false;
    },
    async requestData(force = false) {
      this.$store.state.isLoading = true;

      if (this.$store.state.detections.length === 0) {
        this.$store.state.graph.graphDataDateRangeTitle = this.graphDataDateRangeTitle;
        this.$store.state.graph.graphDataDateRangeType = this.graphDataDateRangeType;

        const [startTimestamp, endTimestamp] = this.calculateTimerange(this.graphDataDateRangeType);
        await this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);
      } else {
        this.graphDataDateRangeTitle = this.$store.state.graph.graphDataDateRangeTitle;
        this.graphDataDateRangeType = this.$store.state.graph.graphDataDateRangeType;
        this.refreshGraph(this.$store.state.detections);
      }
      if (force) {
        console.log("Force refresh");
        console.log("Date range", this.graphDataDateRangeType);
        const [startTimestamp, endTimestamp] = this.calculateTimerange(this.graphDataDateRangeType);
        await this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);

      }
      this.refreshKey++;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    triggerSelectOptionDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async setDateValue(value) {
      this.graphDataDateRangeTitle = value.name;
      this.graphDataDateRangeType = value.value;
      this.$store.state.graph.graphDataDateRangeTitle = this.graphDataDateRangeTitle;
      this.$store.state.graph.graphDataDateRangeType = this.graphDataDateRangeType;

      if (value.value !== "custom-date") {
        this.isDropdownOpen = !this.isDropdownOpen;
      } else {
        this.isDatePickerOpen = !this.isDatePickerOpen;
        // do not request data if the date range is custom
        return;
      }

      const [startTimestamp, endTimestamp] = this.calculateTimerange(this.graphDataDateRangeType);
      await this.fetchDetectionsBetweenTimePeriod(startTimestamp, endTimestamp);
      this.refreshKey++;
    },

    prefillDaysInTimePeriod(startDate, endDate) {
      const processedData = [];

      startDate = DateTime.fromISO(startDate);
      endDate = DateTime.fromISO(endDate);

      // Determine if the period is >= 1 year
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
        const label = date.toFormat(dateFormat);

        processedData.push({
          value: 0,
          label: label,
        });
      }

      return processedData;
    },

    refreshGraph(importedDetections) {
      // Reset the graph
      this.processedData = this.prefillDaysInTimePeriod(this.$store.state.graph.start, this.$store.state.graph.end);

      // Determine if the period is >= 1 year for consistent formatting
      const startDate = DateTime.fromISO(this.$store.state.graph.start);
      const endDate = DateTime.fromISO(this.$store.state.graph.end);
      const useFullYearFormat = endDate.diff(startDate, 'years').years >= 1;
      const dateFormat = useFullYearFormat ? "dd/MM/yyyy" : "dd/MM";

      // Fill out values
      for (var i = 0; i < importedDetections.length; i++) {
        // Parse the timestamp string to datetime
        var timestamp = DateTime.fromISO(importedDetections[i].iTimestamp).toFormat(dateFormat);

        var existingLabelIndex = this.processedData.findIndex(item => item.label === timestamp);
        if (existingLabelIndex !== -1) {
          this.processedData[existingLabelIndex].value++;
        } else {
          this.processedData.push({
            value: 1,
            label: timestamp,
          });
        }
      }
      this.$store.state.isLoading = false;
    },
    checkSetDateRange(startTimestamp, endTimestamp) {
      // Convert startTimestamp to DateTime if it's not already
      if (!(startTimestamp instanceof DateTime)) {
        this.startTimestamp = DateTime.fromISO(startTimestamp);
      }

      // Convert endTimestamp to DateTime if it's not already
      if (!(endTimestamp instanceof DateTime)) {
        this.endTimestamp = DateTime.fromISO(endTimestamp);
      }

      // Check if the new start and end timestamps are different from the previous ones
      const currentStart = this.$store.state.graph.start;
      const currentEnd = this.$store.state.graph.end;

      if (
        startTimestamp.toISO() !== currentStart ||
        endTimestamp.toISO() !== currentEnd
      ) {
        // Update the store state with new values
        this.$store.state.graph.start = startTimestamp.toISO();
        this.$store.state.graph.end = endTimestamp.toISO();
      }
    },
    async fetchDetectionsBetweenTimePeriod(startTime, endTime) {
      this.$store.state.isLoading = true;
      // commit the graph data to store

      console.log("Graph old start Time", this.$store.state.graph.start);
      console.log("Graph old end Time", this.$store.state.graph.end);

      this.checkSetDateRange(startTime, endTime);

      // prefill the graph data
      this.processedData = this.prefillDaysInTimePeriod(startTime, endTime);

      try {
        console.log("start", DateTime.now());
        await this.$store.dispatch("setGraphDetectionsBetweenTimePeriod", {
          start: startTime,
          end: endTime
        });

        this.refreshGraph(this.$store.state.detections);
      } catch (error) {
        console.error(error);
      }
    },
    calculateCustomTimerange(dateRangeValue) {
      let startTimestamp = DateTime.now();
      let endTimestamp = DateTime.now();

      if (dateRangeValue[1] !== null) {
        startTimestamp = DateTime.fromJSDate(dateRangeValue[0]);
        endTimestamp = DateTime.fromJSDate(dateRangeValue[1]);
      }

      return [startTimestamp, endTimestamp]
    },
    calculateTimerange(graphDataDateRangeType) {
      let timeNow = DateTime.now();
      let startDate = timeNow;

      switch (graphDataDateRangeType) {
        case "yesterday":
          startDate = timeNow.minus({ days: 1 });
          break;

        case "last-week":
          startDate = timeNow.minus({ weeks: 1 });
          break;

        case "last-30-days":
          startDate = timeNow.minus({ days: 30 });
          break;

        case "last-3-months":
          startDate = timeNow.minus({ months: 3 }).startOf('month');
          break;

        case "last-6-months":
          startDate = timeNow.minus({ months: 6 }).startOf('month');
          break;

        case "last-year":
          startDate = timeNow.minus({ years: 1 });
          break;
        case "custom-date":
          // we are loading a custom date range from cache
          if (this.$store.state.graph.start && this.$store.state.graph.end) {
            console.log("Loading custom date range from cache");
            console.log("Start", this.$store.state.graph.start);
            console.log("End", this.$store.state.graph.end);
            startDate = DateTime.fromISO(this.$store.state.graph.start);
            timeNow = DateTime.fromISO(this.$store.state.graph.end);
          }
          break;
        default:
          console.error("Invalid date range type");
          break;
      }

      return [startDate, timeNow];
    }
  }
};
</script>
