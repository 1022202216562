<template>
    <v-container fluid>
      <!-- Company Details -->
      <v-card class="mb-5" outlined>
        <v-card-title>
          <span class="text-xl">Company Details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="6">
              <v-card-text>
                <div class="text-h6">{{ company.sName }}</div>
                <div class="text-subtitle-1 font-weight-light">Company Name</div>
              </v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
              <v-card-text>
                <div class="text-h6">{{ company.sEmail }}</div>
                <div class="text-subtitle-1 font-weight-light">Primary company contact email</div>
              </v-card-text>
          </v-col>
        </v-row>
      </v-card>
  
      <!-- User Details -->
      <v-card class="mb-5" outlined>
        <v-card-title>
          <span class="text-xl">User Details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="6">
              <v-card-text>
                <div class="text-h6">{{ user.sFirstName }}</div>
                <div class="text-subtitle-1 font-weight-light">First Name</div>
              </v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
              <v-card-text>
                <div class="text-h6">{{ user.sLastName }}</div>
                <div class="text-subtitle-1 font-weight-light">Last Name</div>
              </v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
              <v-card-text>
                <div class="text-h6">{{ user.sEmail }}</div>
                <div class="text-subtitle-1 font-weight-light">Email Address</div>
              </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </template>
  <script>
  import NavigationComponent from "@/components/NavigationComponent.vue";
  export default {
    name: "ProfilePage",
    components: {
      NavigationComponent,
    },
    data() {
      return {
        user: {},
        company: {},
      };
    },
    props: ["id"],
    async created() {
      if (this.$store.state.userFromQuery) {
        this.user = this.$store.state.userFromQuery;
      } else {
        await this.$store.dispatch("getUserFromQuery");
        this.user = this.$store.state.userFromQuery;
      }
  
      if (this.$store.state.companyFromQuery) {
        this.company = this.$store.state.companyFromQuery;
      } else {
        await this.$store.dispatch("getCompanyFromQuery");
        this.company = this.$store.state.companyFromQuery;
      }
    },
  };
  </script>
  