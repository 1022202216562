<template>
  <button
    ref="button"
    @click="$emit('click-event')"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="font-sans tracking-widest font-medium uppercase m-1 min-w-40 p-2 flex flex-row items-center justify-center"
    :class="{
      'ml-3': rightPair === true,
      'mr-3': leftPair === true,
      'ml-0': noMarginLeft === true,
      'ml-auto': position === 'right',
      'bg-sv-primary text-sv-white': color === 'primary',
      'bg-sv-white text-sv-secondary border-sv-secondary border-2':
        color === 'secondary',
      'py-3 px-4': title !== '',
      'w-12 h-12': title === '',
      'bg-sv-primary-hover': hover && color === 'primary',
      'border-sv-secondary-hover text-sv-secondary-hover bg-sv-secondary-fill-hover':
        hover && color === 'secondary',
      'bg-sv-primary border-none': !hover && color === 'primary',
      'bg-sv-disabled': isDisabled && color === 'primary',
      'border-sv-disabled text-sv-disabled':
        isDisabled && color === 'secondary',
      'w-auto py-3 px-2': hasIcon && title !== '',
    }"
    :disabled="isDisabled"
  >
    <div
      v-show="!disableTitleInMobile || (disableTitleInMobile && !isMobile())"
      v-if="title !== '' && !isReverseOrder"
    >
      {{ title }}
    </div>
    <font-awesome-icon
      class="text-lg"
      v-if="hasDropdown"
      icon="caret-down"
    ></font-awesome-icon>
    <font-awesome-icon
      class="text-xl align-middle"
      v-if="hasIcon"
      :icon="iconName"
      :class="
        !isReverseOrder && title !== null && disableTitleInMobile && !isMobile()
          ? 'ml-2'
          : ''
      "
    ></font-awesome-icon>
    <div
      v-show="!disableTitleInMobile || (disableTitleInMobile && !isMobile())"
      v-if="title !== '' && isReverseOrder"
      :class="
        !disableTitleInMobile || (disableTitleInMobile && isMobile())
          ? 'ml-2'
          : ''
      "
    >
      {{ title }}
    </div>
  </button>
</template>
<script>
export default {
  name: "sv-button",
  inheritAttrs: false,
  data() {
    return {
      hover: false,
      focused: false,
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    variant: {
      type: String,
      default: "standard",
    },
    title: {
      type: String,
      default: "",
    },
    hasDropdown: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    clickFunction: {
      type: Function,
    },
    isReverseOrder: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "",
    },
    disableTitleInMobile: {
      type: Boolean,
      default: false,
    },
    noMarginLeft: {
      type: Boolean,
      default: false,
    },
    leftPair: {
      type: Boolean,
      default: false,
    },
    rightPair: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
