<template>
  <GenericDataTable :headers="headers" :query="query" :index="'listUsers'" :exportDataType="'listUsers'"
    :expandedRowData="expandedRowData" :triggerRefresh="triggerRefresh" :title="'Manage Users'">


    <template v-slot:extraButtons>
      <v-btn text="Add New Customer" color="#797979" variant="outlined" size="small" @click="showCreateModal"
        icon="fa fa-plus" class="secondary mx-2">
      </v-btn>
    </template>

    <!-- Radio Button for Selecting User -->
    <template v-slot:item.action="{ item }">
      <v-radio v-model="selectedUser" :value="item"></v-radio>
    </template>

    <!-- Modify User Button -->
    <template v-slot:item.modify="{ item }">
      <v-btn color="green" size="small" icon @click="showUpdateModal(item)">
        <font-awesome-icon icon="edit" />
      </v-btn>
    </template>

    <!-- Delete User Button -->
    <template v-slot:item.delete="{ item }">
      <v-btn color="red" size="small" icon @click="showDeleteConfirmation(item)">
        <font-awesome-icon icon="trash" />
      </v-btn>
    </template>
  </GenericDataTable>

  <!-- Create User Modal -->
  <v-dialog v-model="createModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Create New User</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      <v-card-text>
        <v-select label="Select" :items="companyIDs" v-model="newUser.sCompanyPartitionID"></v-select>
        <v-text-field v-model="newUser.sFirstName" label="First Name" />
        <v-text-field v-model="newUser.sLastName" label="Last Name" />
        <v-text-field v-model="newUser.sEmail" label="Email" />
        <v-select v-model="selectedGroup" :items="groupOptions" label="Select Group" variant="outlined"
          density="compact" hide-details />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" @click="confirmCreateUser">Create</v-btn>
        <v-btn @click="closeCreateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Update User Modal -->
  <v-dialog v-model="updateModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Modify User</v-card-title>

      <v-alert class="mx-4" v-if="notification.isVisible" :type="notification.type" dismissible>
        {{ notification.message }}
      </v-alert>

      
      <v-card-text>
        <v-text-field v-model="selectedUser.sFirstName" disabled label="First Name" />
        <v-text-field v-model="selectedUser.sLastName" disabled label="Last Name" />
        <v-text-field v-model="selectedUser.sEmail" disabled label="Email" />

        <v-row class="mx-1 my-1">
          <!-- <v-select v-model="selectedGroup" :items="groupOptions" label="Select Group" variant="outlined"
            density="compact" hide-details /> -->
          <v-select v-model="this.selectedGroup" :items="groupOptions" label="Select Group" variant="outlined"
            density="compact" hide-details />

          <v-btn class="mx-2" color="primary"
            @click="addUserToGroup({ userUUID: selectedUser.idItem, groupName: selectedGroup })" size="small"
            icon="fa fa-plus"></v-btn>
          <v-btn color="red" @click="removeUserFromGroup({ userUUID: selectedUser.idItem, groupName: selectedGroup })"
            size="small" icon="fa fa-delete-left"></v-btn>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="green" @click="confirmUpdateUser">Update</v-btn>
        <v-btn @click="closeUpdateModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Confirmation Modal -->
  <v-dialog v-model="deleteModal" persistent max-width="400px">
    <v-card>
      <v-card-title>Delete User</v-card-title>
      <v-card-text>
        Are you sure you want to delete user "{{ selectedUser.sFirstName }} {{ selectedUser.sLastName }}"? This action
        cannot be reverted.
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="confirmDeleteUser">Delete</v-btn>
        <v-btn @click="closeDeleteModal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import * as queries from "@/graphql/queries";
import GenericDataTable from "@/components/dataTables/GenericDataTable.vue";


export default {
  components: { GenericDataTable },
  data() {
    return {
      users: [],
      selectedUser: null,
      selectedGroup: '',
      createModal: false,
      updateModal: false,
      deleteModal: false,

      groupOptions: [{ title: 'PRM', value: 'PRM' }, { title: 'ADMIN', value: 'ADMIN' }, { title: 'USER', value: 'USER' }],
      newUser: { sFirstName: '', sLastName: '', sEmail: '', group: '', sCompanyPartitionID: '' },
      triggerRefresh: false,
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.companies,
      notification: (state) => state.notification,
    }),
    headers() {
      return [
        { key: 'sFirstName', title: 'First Name', sortable: true },
        { key: 'sLastName', title: 'Last Name', sortable: true },
        { key: 'sEmail', title: 'Email', sortable: true },
        { title: "Modify", key: "modify", sortable: false },
        { title: "Delete", key: "delete", sortable: false }
      ];
    },
    query() {
      return queries.listUsers;
    },
    expandedRowData() {
      return { sFirstName: 'First Name', sLastName: 'Last Name', sEmail: 'Email' };
    },
    companyIDs() {
      return this.companies.map((company) => { return { title: company.sName, value: company.idItem } });
    },
  },
  methods: {
    ...mapActions(['createUser', 'deleteUser', 'fetchUsers', 'addUserToGroup', 'removeUserFromGroup']),
    ...mapMutations(['showNotification', 'hideNotification']),
    refresh() {
      this.triggerRefresh = !this.triggerRefresh;
    },
    async confirmCreateUser() {
      await this.createUser(this.newUser);
      this.refresh();
      this.closeCreateModal();
    },
    async confirmUpdateUser() {
      this.closeUpdateModal();
    },
    async confirmDeleteUser() {
      await this.deleteUser(this.selectedUser.idItem);
      this.closeDeleteModal();
    },

    showCreateModal(user) {
      this.createModal = true;
    },
    showUpdateModal(user) {
      this.selectedUser = { ...user };
      this.updateModal = true;
    },
    showDeleteConfirmation(user) {
      this.selectedUser = { ...user };
      this.deleteModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
      this.resetNewUserForm();
      this.refresh();
    },
    closeUpdateModal() {
      this.updateModal = false;
    },
    closeDeleteModal() {
      this.deleteModal = false;
      this.refresh();
    },
    resetNewUserForm() {
      this.newUser = { sFirstName: '', sLastName: '', sEmail: '', group: '' };
    },
  },
};
</script>

<style scoped>
/* Add necessary styling here */
</style>
