<template>
    <v-tooltip location="bottom">
        <div style="width: 400px">
            
        {{ tooltip }}
                </div>
                <template v-slot:activator="{ props }">
            <v-card v-bind="props" class="px-4 py-4 bg-sv-card w-full">
                <div class="flex flex-row justify-between">
                    <h2 class="text-2xl text-sv-text">{{ amount }}</h2>
                    <font-awesome-icon class="text-sv-navigation text-2xl" :icon=icon />
                </div>
                <span class="text-base text-sv-secondary tracking-widest text-sv-text uppercase">{{ title }}</span>
            </v-card>
        </template>
    </v-tooltip>
</template>

<script>
export default {
    name: "StatItem",
    props: {
        title: {
            type: String,
            default: "",
        },
        amount: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            default: "exclamation-triangle",
        },
        tooltip: {
            type: String,
            default: "A quick summary of Sentinel Vision AI data",
        },
    },
};
</script>

<style scoped>
/* Ensure consistent size and spacing */
.v-card {
    /* Minimum width and height to keep cards uniform */
    min-width: 200px;
    /* Adjust as needed */
    height: 120px;
    /* Adjust as needed */
}

/* Center text and icons */
h2,
.text-base {
    margin: 0;
}

/* Ensure items in flex container are centered */
.flex {
    display: flex;
}

.shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.textSecondary {
    color: #6c757d;
}
</style>
