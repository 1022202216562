<template>
    <v-btn v-if="!isIcon" @click="showExportModal = true" :disabled="disabled" variant="outlined" class="text-primary">
        Export
        <font-awesome-icon class="text-primary text-xs ml-2" icon="file-export" />
    </v-btn>
    <v-btn v-else @click="showExportModal = true" color="#797979" :disabled="disabled" class="text-sm" size="small"
        variant="outlined" icon="fa fa-file-export">
    </v-btn>

    <!-- Export Modal -->
    <v-dialog v-model="showExportModal" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <v-row class="mx-2 my-2">
                    <p>
                        <v-icon class="mr-2">fa fa-file-export</v-icon>
                        Export Data
                    </p>
                    <v-spacer></v-spacer>

                    <v-btn text="Refresh" color="#797979" variant="outlined" size="small"
                        @click="showExportModal = false" icon="fa fa-times" class="secondary">
                    </v-btn>
                </v-row>
            </v-card-title>

            <v-card-text>
                <div v-if="isLoading" class="flex justify-center items-center ">
                    <div class="spinner-border animate-spin inline-block w-16 h-16 border-4 rounded-full text-sv-tertiary"
                        role="status">
                    </div>
                    <p class="text-sv-tertiary text-2xl ml-2">Loading...</p>
                </div>
                <div v-else>
                    <!-- Optional Filters -->
                    <div>
                        <v-col>
                            <!-- <div class="text-sv-tertiary mb-10 my-4">
                                Exporting {{ dataToExport !== undefined ? dataToExport.length : 0 }} {{
                                    convertDatatypeToFilename(dataType) }}
                            </div> -->

                            <div class="text-sv-text">Optional Filters</div>
                            <v-row class="mt-4">
                                <v-text-field dense filled clearable v-model="newFilterKeyword" color="secondary"
                                    variant="outlined" label="Enter filter keyword" hide-details />

                                <v-btn :disabled="isLoading" text="Refresh" color="#797979" variant="outlined"
                                    @click="addNewFilterToActiveFilters(newFilterKeyword)" icon="fa fa-plus"
                                    class="secondary my-2 mx-2">
                                </v-btn>

                                <v-btn :disabled="isLoading" text="Refresh" color="#797979" variant="outlined"
                                    @click="clearFilters" icon="fa fa-delete-left" class="secondary my-2">
                                </v-btn>
                            </v-row>
                        </v-col>

                        <v-col class="mb-4">
                            <!-- Show Applied Filters -->
                            <div v-if="activeFilters.length > 0">
                                <div>
                                    <v-chip class="mx-2 my-2" v-for="(filter, index) in activeFilters" :key="index"
                                        close @click:close="removeFilter(index)">
                                        {{ filter }}
                                    </v-chip>
                                </div>
                            </div>

                            <div v-if="activeFilters.length === 0" class="text-sv-tertiary">
                                No filters applied
                            </div>

                        </v-col>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <!-- <v-btn class="ml-2" color="primary" variant="outlined">
                    <download-csv prepend-icon="fa fa-file-csv" :data="dataToExport" :name="fileName + '.csv'">
                        Export CSV
                    <font-awesome-icon class="text-primary text-xs ml-2" icon="file-csv" />
                </download-csv>
                </v-btn> -->
                <v-btn prepend-icon="fa fa-file-excel" class="ml-2" @click="loadDataThenExport" color="primary"
                    variant="outlined">
                    Export Excel
                    <!-- <font-awesome-icon class="text-primary text-xs ml-2" icon="file-excel" /> -->
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped>
.spinner-border {
    border-top-color: transparent;
    border-right-color: transparent;
}
</style>
<script>
import Button from "../components/Button.vue";
import FilterBuilderModule from "./FilterBuilderModule.vue";
import { utils, writeFile } from 'xlsx';
import { mapState } from "vuex";
import { DateTime } from 'luxon';

export default {
    name: "ExportModule",
    components: {
        Button,
        FilterBuilderModule,
    },
    data() {
        return {
            showExportModal: false,
            activeFilters: [],
            newFilterKeyword: "",
            isInEditFilterState: true,
            startRange: "",
            endRange: "",
            loadRequested: false,
            hasLoadedAllData: false,
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        importedData: {
            type: Array,
        },
        dataType: {
            type: String,
            default: "",
        },
        exportData: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            startTimestring(state) {
                return DateTime.fromISO(state.graph.start).toFormat('dd/MM/yyyy')
            },
            endTimestring(state) {
                return DateTime.fromISO(state.graph.end).toFormat('dd/MM/yyyy')
            }
        }),
        fileName() {
            if (this.dataType === "") {
                return `Sentinel Vision AI Export ${this.startTimestring}-${this.endTimestring}`;
            }
            if (this.dataType === "listMachines") {
                return `Sentinel Vision AI ${this.convertDatatypeToFilename(this.dataType)}`;
            }
            return `Sentinel Vision AI ${this.convertDatatypeToFilename(this.dataType)}-${this.startTimestring}-${this.endTimestring}`;
        },
        dataToExport() {
            const keysToIgnore = ['tCreatedAt', 'idCompanyPartition', 'tUpdatedAt', 'idItem', '__typename',
                'machineStVisionAIIdCompanyPartition', 'visionAIStMachineIdCompanyPartition', 'stVisionAI',
                'visionAIStMachineIdItem', 'xFunctionRunning', 'iLastEmailSent', 'iLastEmailSentTimestamp',
                'iTimestampReadable', 'machineStVisionAIIdItem',];
            let filteredData = this.importedData;

            if (this.activeFilters.length > 0) {
                filteredData = filteredData.filter((entry) => {
                    return this.activeFilters.some((filter) =>
                        Object.values(entry).some(value => value?.toString().toLowerCase().includes(filter.toLowerCase()))
                    );
                });
            }

            return filteredData.map(entry => {
                const csvEntry = {};

                for (const key in entry) {
                    if (keysToIgnore.includes(key)) continue;


                    if (key === 'iTimestamp' && entry[key]) {
                        // Convert ISO timestamp to Excel-readable date format
                        const timestamp = new Date(entry[key]);
                        csvEntry['Timestamp'] = timestamp.toLocaleString('en-GB');
                    } else if (key === 'stPosition' && entry[key]) {
                        csvEntry['GPS Latitude'] = entry[key].fLatitude;
                        csvEntry['GPS Longitude'] = entry[key].fLongitude;
                        csvEntry['GPS Google Maps Link'] = 'https://www.google.com/maps/place/' + entry[key].fLatitude + ',' + entry[key].fLongitude;
                    } else if (key === 'stPrestartItem' || key === 'stDeviceStatus' && entry[key]) {
                        for (const prestartKey in entry[key]) {
                            let monitoredDevice = entry[key][prestartKey];
                            if (monitoredDevice.iStatusCode !== 0) {
                                let deviceName = monitoredDevice.sSource;
                                csvEntry[deviceName] = monitoredDevice.sStatusCode;
                            }
                        }
                    } else if (key === 'iSystemStartup' || key === 'iSystemShutdown' && entry[key]) {
                        // Skip zero values
                        if (entry[key] !== 0) {

                            const timestamp = DateTime.fromSeconds(entry[key]);
                            csvEntry[this.convertVariableToTableHeading(key)] = timestamp.toLocaleString('en-GB');
                        } else {

                            continue;
                        }

                    } else {
                        let displayName = this.convertVariableToTableHeading(key);
                        csvEntry[displayName] = entry[key];
                    }
                }
                return csvEntry;
            });
        },
    },
    methods: {
        onLoadAllData(event) {
            this.$emit('clicked', 'loadAllDataFromServer');
        },
        loadDataThenExport() {
            this.onLoadAllData();
            this.loadRequested = true;
        },
        addNewFilterToActiveFilters(keyword) {
            if (keyword && !this.activeFilters.includes(keyword)) {
                this.activeFilters.push(keyword);
                this.newFilterKeyword = "";  // Clear input after adding
            }
        },
        removeFilter(index) {
            this.activeFilters.splice(index, 1);
        },
        clearFilters() {
            this.activeFilters = [];
        },
        convertDatatypeToFilename(sTableHeading) {
            let keyToFilename = {
                'listMachines': 'Machines',
                'listDetectionEvents': 'Detections',
                'listStartupShutdowns': 'Machine Runtime Logs',
                'listPrestarts': 'Prestart Logs',
                'listSystemStatuses': 'System Status Logs',
            }

            if (keyToFilename[sTableHeading]) {
                return keyToFilename[sTableHeading];
            }
            return sTableHeading;
        },
        convertVariableToTableHeading(sTableHeading) {
            let keyToTitleMap = {
                // General
                'iTimestamp': 'Timestamp',
                'sTimeZone': 'Time Zone',
                'sFleetNumber': 'Fleet Number',
                // StartupShutdown
                'sSource': 'Device',
                'iStatusCode': 'Status Code',
                'sStatusCode': 'Device Status',
                // StartupShutdown
                'iTotalRuntime': 'Runtime',
                'iSystemStartup': 'Startup Timestamp',
                'iSystemShutdown': 'Shutdown Timestamp',
                'iStartupTimeTaken': 'Startup Time Taken',
                'sSystemState': 'Event Type',
                // Prestart
                'iDuration': 'Duration',
                'stPrestartItem': 'Device',
                'xPrestartPassed': 'Prestart Passed',
                // SystemStatus
                'stDeviceStatus': 'Device Status',
                // Detection
                'iCameraIdentity': 'Camera ID',
                'sCameraRoiType': 'Camera ROI Type',
                'sDisplayZoneName': 'Display Zone',
                'sAlarmSeverity': 'Alarm Severity',
                'iConfidence': 'Confidence',
                'stBoundingBox': 'Bounding Box',
                'sType': 'Detection Type',
                'idVisionAI': 'Vision AI Serial Number',
                // Position
                'fLatitude': 'Latitude',
                'fLongitude': 'Longitude',
                // DetectionEvent
                'stDetections': 'Detections',
                'stPosition': 'GPS Position',
                'sAlarmGroup': 'Alarm Group',
                'sAlarmSeverity': 'Alarm Severity',
                // Machine
                'sModel': 'Model',
                'sMake': 'Make',
                'sSerialNumber': 'Serial Number',
                // 'machineStVisionAIIdItem': 'Vision AI Serial Number',
                // VPU
                'sCrc': 'Make',
                'sType': 'Make', //TODO: Differentiate between VPU Type and Detection Type
                // VisionAI
                'stMachine': 'Machine',
                'stVPU': 'Vision AI',
                // User
                'sEmail': 'Make',
                'sFirstName': 'Make',
                'sLastName': 'Make',
                // Company
                'sName': 'Company name',
                'sEmail': 'Primary Contact Email',
                'xReceiveSystemFaultEmail': 'Receive System Fault Email',
            }

            if (keyToTitleMap[sTableHeading]) {
                return keyToTitleMap[sTableHeading];
            }
            return sTableHeading.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
        },
        exportToExcel() {
            const worksheet = utils.json_to_sheet(this.dataToExport);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, 'Export');
            writeFile(workbook, this.fileName + '.xlsx');
        },
    },
    watch: {
        exportData(newVal, oldVal) {
            if (oldVal && !newVal) {
                console.log("State changed from loading to not loading");
                if (this.loadRequested) {
                    console.log("Exporting data to Excel");
                    this.exportToExcel();
                    this.hasLoadedAllData = true;
                    this.loadRequested = false;
                }
            }
        },
    },
};
</script>
