<template>
    <v-footer>
        <v-container>
            <v-row>
                <!-- First Column - Image -->
                <v-col cols="12" md="3" class="text-center">
                    <img src="@/assets/prm_logo_circle.png" alt="Sentinel PRM Engineering" width="165" height="156" />
                </v-col>

                <!-- Second Column - Contact Details -->
                <v-col cols="12" md="3">
                    <p>
                        <v-icon left class="text-primary mr-2 mt-2">fa fa-laptop</v-icon>
                        <a href="https://prmengineering.com.au" target="_blank" rel="noopener">
                            <strong>www.prmeng.com.au</strong>
                        </a>
                    </p>
                    <p>
                        <v-icon left class="text-primary mr-2 mt-2">fa fa-phone</v-icon>
                        <a href="tel:07 3711 2779">
                            <strong>+61 (0)7 3711 2779</strong>
                        </a>
                    </p>
                    <p>
                        <v-icon left class="text-primary mr-2 mt-2">fa fa-envelope</v-icon>
                        <a href="mailto:engineering@prmeng.com.au">
                            <strong>engineering@prmeng.com.au</strong>
                        </a>
                    </p>
                    <p>
                        <font-awesome-icon class="resize-icon text-primary text-xl mr-2 mt-2" :icon="faLinkedin" />
                        <a href="https://www.linkedin.com/company/prm-solutions-australasia" target="_blank"
                            rel="noopener">
                            <strong>PRM Solutions</strong>
                        </a>
                    </p>
                </v-col>

                <!-- Third Column - Address -->
                <v-col cols="12" md="3">
                    <p>

                    <p><v-icon left class="text-primary mr-2 mt-2">fa fa-map-marker-alt</v-icon> Unit 4 / 30 Gardens
                        Drive<br /></p>
                    <p class="ml-9">Willawong, QLD 4110</p>
                    </p>
                    <p>
                        <font-awesome-icon class="resize-icon text-primary text-xl mr-2 mt-2" :icon="faFacebook" />
                        <a href="https://facebook.com/prm.solutions.australasia" target="_blank" rel="noopener">
                            <strong>Facebook</strong>
                        </a>
                    </p>
                </v-col>

                <!-- Fourth Column - Terms and Conditions -->
                <v-col cols="12" md="3">
                    <p><strong>PRM ENG Terms and Conditions</strong></p>
                    <p>
                        <a href="https://prmengineering.com.au/wp-content/uploads/2020/01/PRM-ENG-Terms-and-Conditions-of-sale-for-Goods.pdf"
                            target="_blank" rel="noopener">
                            PRM ENG – Terms and Conditions of Sale of Goods
                        </a>
                    </p>
                </v-col>
            </v-row>

            <!-- Copyright Section -->
            <v-row justify="center" class="mt-4">
                <v-col cols="12" class="text-center">
                    Copyright (C) {{ new Date().getFullYear() }} PRM Engineering Services. All rights reserved.
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<style scoped>
.resize-icon {
    font-size: 21pt;
}
</style>

<script>
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default {
    components: {
        faFacebook,
        faLinkedin
    },
    data: () => ({
        faFacebook,
        faLinkedin,
        currentYear: new Date().getFullYear(),
    }),
}
</script>