/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSystemInfo = /* GraphQL */ `
  mutation CreateSystemInfo(
    $input: CreateSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    createSystemInfo(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iSystemStartup
      iSystemShutdown
      iTotalRuntime
      iStartupTimeTaken
      sSystemState
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updateSystemInfo = /* GraphQL */ `
  mutation UpdateSystemInfo(
    $input: UpdateSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    updateSystemInfo(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iSystemStartup
      iSystemShutdown
      iTotalRuntime
      iStartupTimeTaken
      sSystemState
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deleteSystemInfo = /* GraphQL */ `
  mutation DeleteSystemInfo(
    $input: DeleteSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    deleteSystemInfo(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iSystemStartup
      iSystemShutdown
      iTotalRuntime
      iStartupTimeTaken
      sSystemState
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const createPrestart = /* GraphQL */ `
  mutation CreatePrestart(
    $input: CreatePrestartInput!
    $condition: ModelPrestartConditionInput
  ) {
    createPrestart(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      iDuration
      idVisionAI
      sFleetNumber
      stPrestartItem {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      xPrestartPassed
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updatePrestart = /* GraphQL */ `
  mutation UpdatePrestart(
    $input: UpdatePrestartInput!
    $condition: ModelPrestartConditionInput
  ) {
    updatePrestart(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      iDuration
      idVisionAI
      sFleetNumber
      stPrestartItem {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      xPrestartPassed
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deletePrestart = /* GraphQL */ `
  mutation DeletePrestart(
    $input: DeletePrestartInput!
    $condition: ModelPrestartConditionInput
  ) {
    deletePrestart(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      iDuration
      idVisionAI
      sFleetNumber
      stPrestartItem {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      xPrestartPassed
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const createSystemStatus = /* GraphQL */ `
  mutation CreateSystemStatus(
    $input: CreateSystemStatusInput!
    $condition: ModelSystemStatusConditionInput
  ) {
    createSystemStatus(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      stDeviceStatus {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updateSystemStatus = /* GraphQL */ `
  mutation UpdateSystemStatus(
    $input: UpdateSystemStatusInput!
    $condition: ModelSystemStatusConditionInput
  ) {
    updateSystemStatus(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      stDeviceStatus {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deleteSystemStatus = /* GraphQL */ `
  mutation DeleteSystemStatus(
    $input: DeleteSystemStatusInput!
    $condition: ModelSystemStatusConditionInput
  ) {
    deleteSystemStatus(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      stDeviceStatus {
        sSource
        iStatusCode
        sStatusCode
        __typename
      }
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const createDetectionEvent = /* GraphQL */ `
  mutation CreateDetectionEvent(
    $input: CreateDetectionEventInput!
    $condition: ModelDetectionEventConditionInput
  ) {
    createDetectionEvent(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iDuration
      stDetections {
        iTimestamp
        iCameraIdentity
        sCameraRoiType
        sDisplayZoneName
        sAlarmSeverity
        iConfidence
        sType
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sAlarmGroup
      sAlarmSeverity
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updateDetectionEvent = /* GraphQL */ `
  mutation UpdateDetectionEvent(
    $input: UpdateDetectionEventInput!
    $condition: ModelDetectionEventConditionInput
  ) {
    updateDetectionEvent(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iDuration
      stDetections {
        iTimestamp
        iCameraIdentity
        sCameraRoiType
        sDisplayZoneName
        sAlarmSeverity
        iConfidence
        sType
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sAlarmGroup
      sAlarmSeverity
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deleteDetectionEvent = /* GraphQL */ `
  mutation DeleteDetectionEvent(
    $input: DeleteDetectionEventInput!
    $condition: ModelDetectionEventConditionInput
  ) {
    deleteDetectionEvent(input: $input, condition: $condition) {
      idCompanyPartition
      iTimestamp
      sTimeZone
      idVisionAI
      sFleetNumber
      iDuration
      stDetections {
        iTimestamp
        iCameraIdentity
        sCameraRoiType
        sDisplayZoneName
        sAlarmSeverity
        iConfidence
        sType
        __typename
      }
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sAlarmGroup
      sAlarmSeverity
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const createMachine = /* GraphQL */ `
  mutation CreateMachine(
    $input: CreateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    createMachine(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      sFleetNumber
      sModel
      sMake
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sSerialNumber
      stVisionAI {
        idCompanyPartition
        idItem
        tCreatedAt
        tUpdatedAt
        visionAIStMachineIdCompanyPartition
        visionAIStMachineIdItem
        __typename
      }
      tCreatedAt
      tUpdatedAt
      machineStVisionAIIdCompanyPartition
      machineStVisionAIIdItem
      __typename
    }
  }
`;
export const updateMachine = /* GraphQL */ `
  mutation UpdateMachine(
    $input: UpdateMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    updateMachine(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      sFleetNumber
      sModel
      sMake
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sSerialNumber
      stVisionAI {
        idCompanyPartition
        idItem
        tCreatedAt
        tUpdatedAt
        visionAIStMachineIdCompanyPartition
        visionAIStMachineIdItem
        __typename
      }
      tCreatedAt
      tUpdatedAt
      machineStVisionAIIdCompanyPartition
      machineStVisionAIIdItem
      __typename
    }
  }
`;
export const deleteMachine = /* GraphQL */ `
  mutation DeleteMachine(
    $input: DeleteMachineInput!
    $condition: ModelMachineConditionInput
  ) {
    deleteMachine(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      sFleetNumber
      sModel
      sMake
      stPosition {
        iTimestamp
        fLatitude
        fLongitude
        __typename
      }
      sSerialNumber
      stVisionAI {
        idCompanyPartition
        idItem
        tCreatedAt
        tUpdatedAt
        visionAIStMachineIdCompanyPartition
        visionAIStMachineIdItem
        __typename
      }
      tCreatedAt
      tUpdatedAt
      machineStVisionAIIdCompanyPartition
      machineStVisionAIIdItem
      __typename
    }
  }
`;
export const createVisionAI = /* GraphQL */ `
  mutation CreateVisionAI(
    $input: CreateVisionAIInput!
    $condition: ModelVisionAIConditionInput
  ) {
    createVisionAI(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      stMachine {
        idCompanyPartition
        idItem
        sFleetNumber
        sModel
        sMake
        sSerialNumber
        tCreatedAt
        tUpdatedAt
        machineStVisionAIIdCompanyPartition
        machineStVisionAIIdItem
        __typename
      }
      stVPU {
        sSerialNumber
        sCrc
        sType
        __typename
      }
      tCreatedAt
      tUpdatedAt
      visionAIStMachineIdCompanyPartition
      visionAIStMachineIdItem
      __typename
    }
  }
`;
export const updateVisionAI = /* GraphQL */ `
  mutation UpdateVisionAI(
    $input: UpdateVisionAIInput!
    $condition: ModelVisionAIConditionInput
  ) {
    updateVisionAI(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      stMachine {
        idCompanyPartition
        idItem
        sFleetNumber
        sModel
        sMake
        sSerialNumber
        tCreatedAt
        tUpdatedAt
        machineStVisionAIIdCompanyPartition
        machineStVisionAIIdItem
        __typename
      }
      stVPU {
        sSerialNumber
        sCrc
        sType
        __typename
      }
      tCreatedAt
      tUpdatedAt
      visionAIStMachineIdCompanyPartition
      visionAIStMachineIdItem
      __typename
    }
  }
`;
export const deleteVisionAI = /* GraphQL */ `
  mutation DeleteVisionAI(
    $input: DeleteVisionAIInput!
    $condition: ModelVisionAIConditionInput
  ) {
    deleteVisionAI(input: $input, condition: $condition) {
      idCompanyPartition
      idItem
      stMachine {
        idCompanyPartition
        idItem
        sFleetNumber
        sModel
        sMake
        sSerialNumber
        tCreatedAt
        tUpdatedAt
        machineStVisionAIIdCompanyPartition
        machineStVisionAIIdItem
        __typename
      }
      stVPU {
        sSerialNumber
        sCrc
        sType
        __typename
      }
      tCreatedAt
      tUpdatedAt
      visionAIStMachineIdCompanyPartition
      visionAIStMachineIdItem
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      idItem
      idCompanyPartition
      sEmail
      sFirstName
      sLastName
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      idItem
      idCompanyPartition
      sEmail
      sFirstName
      sLastName
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      idItem
      idCompanyPartition
      sEmail
      sFirstName
      sLastName
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      idItem
      sName
      sEmail
      xReceiveSystemFaultEmail
      idPrimaryAdmin
      idOwnedVisionAIDevices
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      idItem
      sName
      sEmail
      xReceiveSystemFaultEmail
      idPrimaryAdmin
      idOwnedVisionAIDevices
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      idItem
      sName
      sEmail
      xReceiveSystemFaultEmail
      idPrimaryAdmin
      idOwnedVisionAIDevices
      tCreatedAt
      tUpdatedAt
      __typename
    }
  }
`;
